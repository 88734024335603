.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background:#fff;
  /*background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6311864691995184-1640084429048.jpg') no-repeat scroll 50% 0;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*background-color: #f03033;*/
  /*background-image: -webkit-radial-gradient(center, ellipse cover, #f03033 0%, #690000 100%);*/
}
.loaderImg{
  background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6311864691995184-1640084429048.jpg') no-repeat scroll 50% 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.truck-image {
  /*width: 150px;*/
  width: 200px;
  position: absolute;
  animation-name: loading-animation;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: loading-animation;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
.truckSenta{width:400px;animation-duration: 8s;
  -webkit-animation-duration: 8s;}
@keyframes loading-animation {
  0% {
    left: -5%;
  }
  25%{
    left: 20%;
  }
  35%{
    left: 40%;
  }
  50%{
    left: 60%;
  }
  70%{
    left: 70%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes loading-animation {
  0% {
    left: -5%;
  }
  25%{
    left: 20%;
  }
  35%{
    left: 40%;
  }
  50%{
    left: 60%;
  }
  70%{
    left: 70%;
  }
  100% {
    left: 100%;
  }
}
.loader-container:before {
  content: "";
  height: 100%;
  width: 100%;

  background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-snow-img/images/snow.png), url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-snow-img/images/snow2.png), url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-snow-img/images/snow3.png);
  background-repeat: repeat;

  position: absolute;
  left: 0;
  top: 0;

  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;

  will-change: background-position;

  -webkit-animation: an-snow 20s linear infinite;
  -moz-animation: an-snow 20s linear infinite;
  -o-animation: an-snow 20s linear infinite;
  animation: an-snow 20s linear infinite;
}

@-webkit-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@-moz-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@-o-keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}
@keyframes an-snow {
  100% {
    background-position: 500px 1000px, 300px 300px, -400px 400px;
  }
}


@media (min-width: 1000px) {
  .truck-image {
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
  }
}
