body {
    margin: 0;
    padding: 0;
    color: #000;
    background-color: #fff;
    background-size: cover;
    font-family: "Cretype caros", sans-serif;
}
.homes-img {
    padding: 8px 0 10px;
    min-height: 100vh;
}
.video-img {
    width: 100%;
    background-size: cover;
    padding-bottom: 5px;
}
h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0
}
.dashboard-wrapper{padding: 60px 0;background: #fff}
header {
    background: #fff;
    box-shadow: 0 2px 6px 0 #c7c5c5;
}
.navbar { transition: all 0.3s ease-in-out;
    background: #fff;
    box-shadow: 0 -5px 15px 0;
    padding: 1px 15px!important;
    /*padding: 10px 15px 0!important;*/
}
.navbar-nav .nav-link {
    padding-left: 2rem !important;
    font-size: 15px;
    cursor:pointer;
    position: relative;
    color: #171717;
    text-transform: uppercase;
}
.navbar-nav .nav-link i,.navbar-nav .nav-link svg {
    font-size: 1.2rem;
}
.dropdown-item {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    color: #000 !important
}
.dropdown-menu {min-width: 15rem;
    border-radius: .25rem;
}
.nav-link:hover {
    color: #f15925 !important
}
.search-btn {
    transition: all .2s;
    border-radius: 50px;
    background: #000000!important;
    border-color: #000000!important;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 50px;
    box-shadow: 0 4px 0 #404040, 0 5px 5px 1px rgba(0, 0, 0, .4)!important;
}
.search-btn:active {
    transform: scaleX(.9) translateY(4px)
}
.man-fields{
    position: absolute;
    bottom:0
}
.srv-validation-message {
    font-size: 12px;
    color: red;
    font-weight: 600;
    padding: 0 5px 0
}
.man-fields .srv-validation-message{
    font-size: 12px;
}
.post-slide {
    padding: 0 5px 0 0
}
.post-img {
    margin-right: 10px
}
.post-img>img {
    width: 100px;
    height: 100px;
    border-radius: 5px
}
.post-img i,.post-img svg {
    font-size: 30px;
    color: #173ebc;
    /*transform: rotate(-42deg)*/
}
.post-slide .post-title {
    position: relative
}
.post-slide .post-title>a {
    font-size: 17px;
    color: #173ebc;
    font-weight: 700;
    text-transform: capitalize
}
.post-slide .post-title>a:hover {
    text-decoration: none
}
.post-slide .post-title>a>i,.post-slide .post-title>a>svg {
    color: #173ebc;
    font-size: 18px;
    margin: 0 6px
}
.post-slide .post-description {
    color: #555;
    font-size: 14px;
    line-height: 22px
}
.post-slide:hover .post-img>img {
    opacity: .5;
    transition: .3s ease
}
.post-slide:hover .post-title>a {
    color: #f15925;
    transition: .3s ease
}
.owl-controls .owl-buttons {
    position: relative
}
.owl-controls .owl-prev {
    position: absolute;
    left: -40px;
    bottom: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    color: #173ebc;
    transition: background .5s ease
}
.owl-controls .owl-next {
    position: absolute;
    right: -35px;
    bottom: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    color: #173ebc;
    transition: background .5s ease
}
.owl-controls .owl-next:after, .owl-controls .owl-prev:after {
    /*font-family: "Font Awesome 5 Free";*/
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f104";
    font-size: 20px
}
.owl-controls .owl-next:after {
    content: "\f105"
}
.owl-controls .owl-next:hover, .owl-controls .owl-prev:hover {
    background: #173ebc;
    color: #fff
}
@media screen and (max-width: 480px) {
    .post-slide {
        padding-left: 0;
        padding-right: 0
    }
}
.text {
    color: #000;
    font-weight: 500;
    font-size: 16px
}
.text strike {
    font-size: 13px;
    font-weight: 400;
    color: #777
}
.post-title {
    font-size: 14px
}
.small-text {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    color: #173ebc;
}

.rate-card {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
    padding: 5px 10px;
    background: #fff;
    border-radius: 5px
}

.ship-box {flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding: 6px 10px;
    cursor: pointer
}
.ship-box:hover {border: 3px solid #000 !important;
    background: #fff !important;
    padding: 8px 10px;
    font-size: 12px;
    color: #ff0600;
    border-radius: 5px;
    box-shadow: 0 5px 15px 3px rgb(0 0 0 / 48%);
    -webkit-transform: scale(1.1,1.1);
    transform: scale(1.1,1.1);
}
.ship-box.active {border: 3px solid #000 !important;
    background: #fff !important;
    padding: 8px 10px;
    font-size: 12px;
    color: #ff0600;
    border-radius: 5px;
    box-shadow: 0 5px 15px 3px rgb(0 0 0 / 48%);
    -webkit-transform: scale(1.1,1.1);
    transform: scale(1.1,1.1);
}
.ship-box p {
    padding: 5px 0 0;
    font-weight: 500;
    margin: 0;
    font-size: 13px;
}
.origin-destination {
    font-size: 14px;
    color: #404040!important;
    font-weight: 500
}
.origin-text {
    width: 145px;
}
.origin-text label {
    font-size: 13px;
    color: #404040;
}
.book-btn {
    border-color: transparent;
    background: #000!important;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 5px;
}
.book-btn:hover {
    background: linear-gradient(96deg, #173ebc, #173ebc);
    color: #fff;
    transition: .4s
}
.back-black {
    background: #173ebc;
}
.other-details h4 {
    font-weight: 700;
    font-size: 13px;
    margin-right: 40px;
    color: #000;
}
.other-details h4 span {
    font-weight: 500;
}
.card-typess label {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}
.card-price {
    font-size: 18px;
    font-weight: 700;
    color: #ff0600;
    margin-right: 25px
}
.color-blue {
    color: #000;
    font-weight: 500
}

.economy input {
    width: 100%;
    outline: 0;
    border-radius: 3px;
    border: 1px solid #c8c8c8;
    font-size: 13px;
    padding: 0 14px;
    height: 35px;
    background: #ffffff;
}

.forms {
    padding-bottom: 5px;
}
.input-searchs input {
    border-radius: 35px;
    padding: 0 15px;
    font-size: 12px;
    background: #fcfcfc;
    height: 35px;
    border-color: transparent;
    outline: 0;
    font-weight: 600
}
.border-bottoms {
    border-bottom: 1px solid #868686 !important
}
.border-top {
    border-top: 1px solid #868686 !important
}
.border-tops {
    border-top: 1px solid #868686 !important
}
.navbar-light .navbar-toggler {
    border-color: transparent !important;
    line-height: 2
}
.css-151xaom-placeholder {
    font-size: 11px !important
}
.react-datepicker__navigation--previous {
    border-right-color: #ff0600 !important
}
.react-datepicker__navigation--next {
    border-left-color: #ff0600 !important
}
.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected {
    background: #ff0600 !important;
    border-radius: .1rem !important
}
.react-datepicker__day--today {
    background: #ff0600 !important
}
.cross-btn {
    position: absolute;
    cursor: pointer !important;
    right: 25px;
    top: 35px
}
.displayCross {
    display: none;
    color: #fff;
    background: #000!important;
    border-radius: 50px;
    font-size: 13px;
    padding: 3px 15px 5px;
    margin: 8px 0 0;
    font-weight: 700;
}
.cross-btn i,.cross-btn svg {
    width: 25px;
    height: 25px;
    background: #000;
    color: #fff;
    line-height: 25px;
    font-size: 15px;
    text-align: center
}
.cross-btn:hover .displayCross {
    display: block
}
.shipment-his {
    padding: 80px 0 0
}
@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::after, .flex-sm-nowrap .step .step-icon-wrap::before {
        display: none
    }
}
@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::after, .flex-md-nowrap .step .step-icon-wrap::before {
        display: none
    }
}
@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::after, .flex-lg-nowrap .step .step-icon-wrap::before {
        display: none
    }

}
@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::after, .flex-xl-nowrap .step .step-icon-wrap::before {
        display: none
    }
}
.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important
}
.origin-destination1 {
    font-size: 17px;
}
.order-btns {
    color: #173ebc !important;
    font-weight: 700;
    font-size: 14px
}
.border-cir {
    border-radius: 50px
}
.hiii a:not(.collapsed) .rotate-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}
.padding-15 {
    padding: 0 15px
}
.hiii a {
    font-size: 13px;
    color: #000000;
    font-weight: 500
}
.mar_bot {
    margin-bottom: 0
}
.pink-back {background-color: #f6f6f6;
    font-size: 13px;
    color: #000000;
    padding: 0 20px 10px;
    font-weight: 400;
    border-radius: 3px;
}
.pink-back h6 {
    font-weight: 700;
    font-size: 15px;
}
#exampleModal .modal-dialog {
    max-width: 750px
}
.padding-no {
    padding: 0 !important
}
.padding-eight {
    padding: 8px !important
}
.close {opacity: 1;
    color: #ff0600 !important;
    font-size: 25px;
}
.color-blue {
    color: #000;
}
.padding-0 {
    padding: 0 !important
}
.auth-card {
    min-height: 470px
}
.auth-card .image-side {
    display: none;
    /*display: flex;
    */
    flex-direction: column;
    align-items: center;
    justify-content: flex-end
}
.auth-card .image-side:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0
}
.auth-card .image-side p {
    text-align: center;
    font-family: Sriracha, cursive;
    font-size: 44px;
    background: -webkit-linear-gradient(#173ebc, #002496);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #00091721;
    line-height: 54px;
    font-weight: 700
}
.sizechange {
    max-width: 800px
}
.sizechange.pop-login {
    max-width: 450px
}
.sizechange .close {
    top: 0;
    position: absolute;
    z-index: 9;
    right: 11px;
    height: 30px;
    color: #173ebc;
    font-weight: 700
}
small {
    color: #616161!important;
    font-weight: 600;
    font-size: 13px
}
.terms-modal {
    max-width: 750px
}
.figure_case {
    display: flex;
    width: 26%;
    align-items: center
}
.figure_case figure{display: flex;align-items: center}
figure img {
    width: 50px;
    /*height: 4rem;
    */
    border-radius: 15%;
    /*border: 1.5px solid #173ebc;
    */
    margin-right: 9px;
    padding: 2px;
}
figure figcaption h4 {
    color: #000;
    font-size: 15px;
    margin-bottom: 6px;
    font-weight: 700
}
figure figcaption h6 {
    font-size: 14px;
    font-weight: 400
}
figure figcaption h2 {
    word-break: break-word;
    color: #404040;
    font-size: 13px;
    font-weight: 500
}
.order-track {
    padding: 1.1rem 1rem 0;
    display: flex;
    flex-direction: column
}
.order-track-step {
    display: flex;
    height: 4rem
}
.order-track-step:last-child {
    overflow: hidden;
    height: 4rem
}
.order-track-step:last-child .order-track-status span:last-of-type {
    display: none
}
.order-track-status {
    margin-right: 1.5rem;
    position: relative
}
.order-track-status-dot {
    display: block;
    width: 25px;
    height: 25px;
    line-height: 21px;
    background: #fff;
    text-align: center;
    color: #fff;
    font-size: 15px;
    border: 2px solid #bbb
}
.order-track-status-dot.complete {
    background: #009800;
    text-align: center;
    color: #fff;
    font-size: 15px;
    border-color: transparent
}
.order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 4rem;
    background: #ddd
}
.order-track-status-line.complete {
    background: #009800
}
.order-track-text-stat {
    font-weight: 700;
    margin: 0
}
.order-track {
    transform-origin: top center
}
.all-detaill {
    background: #f7f7f7;
    padding: 15px 25px 0;
    border: 1px solid #ddd;
    width: 70%;
    margin: 5px 0
}
.all-detaill ul {
    list-style: none;
    padding: 15px 0 0
}
.all-detaill span {
    font-weight: normal;
    float: right
}
.all-detaill ul li {
    font-weight: 600;
    font-size: 14px
}
.AAA-query-manager-head-4 input {
    border-radius: 35px;
    padding: 0 15px;
    font-size: 13px;
    background: #f8f8f8;
    height: 42px;
    border: 1px solid #8c8c8c;
    outline: 0;
    font-weight: 600;
    color: #000;
    width: 100%;
}
.AAA-query-manager-head-4 input::placeholder {
    color: #000 !important
}
.view-all{margin-top:10px;margin-bottom: 10px;}
.view-all h4 {
    font-weight: 700;
    color: #000;
    /*color: #fff;
    */
    text-transform: uppercase;
    font-size: 19px
}
.shipment-status1 {
    padding: 8px 15px !important;
    position: relative;
    box-shadow: rgba(0, 0, 0, .36) 0 0 6px 0;
    border-radius: 10px
}
.shipment-status1:hover {
    box-shadow: 0 0 6px 0 #173ebc85
}
.chargeable-model {
    max-width: 520px
}
.auth-card2 {
    background-size: cover;
    background: url(https://d2ra2wln3jgzfj.cloudfront.net/assets/img/air2.jpg) no-repeat center;
}
.auth-card1:before, .auth-card2:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /*background: #0f123fa6;
    */
    left: 0
}
.auth-card2 .form-side {
    padding: 30px 60px
}
.auth-card1 .form-side {
    position: relative;
    padding: 50px 30px 20px;
}
.cont-forms label {
    font-size: 15px;
    color: #000;
    font-weight: 600
}
.cont-titles span{
    color: #000;
    font-size: 26px;
}
.cont-forms input {
    border-radius: 50px;
    height: 40px;
    outline: 0
}
.contact-btnss{
    width: 100%;
    padding: 13px 0;
    border-radius: 5px;
    font-size: 18px;
    background: #ff0600!important;
    text-transform: uppercase;
}
.contactsize {
    max-width: 490px
}
.chargeable-model .close,.coupan_pop .close,.origin_destination_popup .close,.new_shipper_popup .close,.container_popup_mode .close,.activity_type_tarrif .close,.popup_tarrif .close,.contactsize .close, .ship-airbill .close, .three-type .close, .tracking-model .close, .truck_Popup .close,.popup_dangerous .close,.popup_activity_type .close,.popup_activity_type_door_door .close, .dangerous_pop .close,.mawb_popup .close,.airCargo_modal .close, .airline_revert_popup .close,.sendOtp_popup .close {
    top: 5px;
    position: absolute;
    z-index: 9;
    right: 3px;
    font-weight: 700;
    background: #ff0600;
    opacity: 1;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    font-size: 25px;
    color: #fff!important;
}
.order-btns {
    color: #173ebc !important;
    font-weight: 700;
    font-size: 14px
}
.history-text2 {
    margin: 10px 15px 0;
    text-align: center;
    padding-bottom: 8px
}
.history-text2 img {
    padding-bottom: 8px
}
.mytables td, .mytables th {
    font-size: 14px;
    padding: 6px 10px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #dee2e6;
}
.mytables th {
    text-transform: uppercase;
}
.three-type {
    max-width: 1080px
}
.three-type .modal-content {
    border-radius: 7px;
    /*overflow: hidden*/
}
.shipment-status1 .book-btn {
    margin-left: 5px !important
}
.navbar-text {
    position: fixed;
    transform: translateX(-52%);
    left: 312px;
    top: 50px;
    z-index: 100
}
.fixed-top {
    top: -1px;
    z-index: 99 !important
}
.navbar-text h3 {
    font-style: italic;
    font-size: 13px;
    font-weight: 600;
    color: #173ebc;
}
.navbar-brand img{
    /*width:340px;*/
    width:300px;
}
.change-mob1 {font-size: 13px;
    font-weight: 700;
    color: #ffffff!important;
    padding: 5px 15px 4px 10px !important;
    background: #000000!important;
    text-transform: capitalize;
    border-color: #000000!important;
    border-radius: 7px;
    box-shadow: 0 4px 0 #343434c2, 0 5px 5px 1px rgb(0 0 0 / 40%) !important;
    -webkit-box-shadow: 0 4px 0 #343434c2, 0 5px 5px 1px rgb(0 0 0 / 40%) !important;
}

.change-mob1 {
    transition: all .2s
}
.change-mob1:active {
    transform: scaleX(.9) translateY(4px)
}
.icon-text i ,.icon-text svg{
    font-weight: 700;
    font-size: 16px;
    margin-right: 5px
}
.pricess-all th, .pricess-all.origin-text td {
    border-color: transparent !important
}
.pricess-all td, .pricess-all th {
    padding: 10px !important;
    border-top: 1px solid transparent !important
}
.pricess-all th{
    font-weight: 700
}
.instagram {
    background: linear-gradient(96deg, #173ebc, #173ebc)
}
.three-type .origin-text {
    width: 130px
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: red
}
.red {
    color: red !important
}
.close-btnns {
    background: #000000;
    color: #fff;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50px;
    position: absolute;
    right: -13px;
    top: -13px
}
.close-btnns i,.close-btnns svg {
    font-size: 15px;
    line-height: 25px
}
.query-prev a {
    font-weight: 600;
    color: #173ebc;
}
.commo-text {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
}
.navbar .language-button {
    color: #173ebc;
    font-size: 12px
}
.navbar .language-button i,.navbar .language-button svg {
    font-size: 14px
}
.main-menu1 {
    width: 9%;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .4);
    height: 100vh;
    background: #fff;
    z-index: 3;
    position: fixed;
    transition: transform .3s;
    left: 0;
    overflow: auto;
    padding-bottom: 140px
}
.main-menu1 ul li {padding:4px 2px;
    cursor: pointer;
    position: relative;
}
.sidebar .main-menu1 ul li a {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #f3f3f3;
    color: #303030;
    transition: color .3s;
}
.sidebar .main-menu1 ul li i,.sidebar .main-menu1 ul li svg {
    font-size: 32px;
    line-height: 42px;
    color: #404040;
    font-weight: 800;
}
.sidebar .main-menu1 ul li a span {
    color: #000000;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
}
.sidebar .main-menu1 .scroll {
    padding-right: unset;
    margin-right: unset;
    height: 100%;
    transition: left.7s ease;
}
.contactsss .close {
    z-index: 1;
    right: 7px;
    font-size: 32px;
    position: absolute;
}
.chnageBack {
    background: #173ebc;
    color: #f1f1f1;
}
.edit button {
    padding: 6px 10px;
    background: #fff;
    font-size: 16px;
    outline: 0;
    border-radius: 20px;
    border: 1px solid #cacaca;
    min-width: 90px;
    color: #173ebc;
    font-weight: 600;
}
.right-title {
    font-size: 25px;
    font-weight: 600;
}
.right-profile-card label {
    font-weight: 700;
    font-size: 13px;
    color: #000 !important;
}
.right-profile-card input {
    border-radius: 50px;
    background: #f7f7f7;
    font-weight: 600;
    font-size: 13px;
    color: #000;
    height: 40px;
}
.right-profile-card textarea {
    background: #f7f7f7;
    font-weight: 600;
    font-size: 13px;
    color: #000;
}
.right-profile-card .update-btn {
    border-radius: 50px;
    background: linear-gradient(96deg, #53b2fe, #065af3);
    border-color: transparent;
    color: #fff;
    font-weight: 700;
    padding: 10px 40px;
    text-transform: uppercase;
}
.right-profile-card .submit-btn {
    background: linear-gradient(96deg, #173ebc, #173ebc);
}
.left-profile-card .user-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto auto 20px;
}
.left-profile-card h3 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 700;
}
.cover-image {
    display: inline-block;
    position: relative;
}
.upload-pick {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 50px;
    background: #173ebc;
}
.edits-img i,.edits-img svg {
    font-size: 16px;
}
.upload-pick input {
    display: none;
}
.profile_page {
    padding: 30px 0 30px;
}
.shipper-detailss li a {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.shipper-detailss .nav-tabs .nav-item{margin-right:8px;}
.shipper-detailss .nav-tabs .nav-link{background: #000;padding: 0.4rem 1rem;
    border-radius: 4px;color: #ffffff !important;
    font-weight: 600;}
.shipper-detailss .nav-tabs .nav-link.active {
    background: #ff0600;
}
.shipper-detailss .nav-tabs {
    border-bottom: 1px solid transparent;
}
.shipper-historys {
    flex-wrap: wrap;
    list-style: none;
    width:95%;
    display: flex;
    padding: 0 0 0 10px;
    text-align: center;
    margin: 0;
}
.shipper-historys li {
    word-break: break-all;
    width: 16%;
    display: inline-block;
    margin-right: 7px;
}
.shipper-historys li h3 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.shipper-historys li p {
    text-transform: capitalize;
    font-size: 15px;
    margin: 9px 0 0;
    font-weight: 700;
    color: #404040;
}
.threecard {
    margin-bottom: 15px;
    box-shadow: -2px 10px 22px -16px rgb(0 0 0 / 62%);
}
.tracking_card_shipment .threecard{border-radius: 10px;margin-bottom: 20px;overflow: hidden}
.threecard-head {
    background: #fff;
    border-top: 1px solid #b1afaf;
    padding: 5px;
    border-right: 1px solid #b1afaf;
    border-left: 1px solid #b1afaf;
    border-radius: 5px 5px 0 0;
}
.other-cardss {
    text-align: center;
}
.other-cardss label {
    font-size: 15px;
    margin-bottom: 3px;
    font-weight: 700;
}
.detail-seconds {
    background: #ffffff;
    padding: 15px 5px 10px;
    border: 1px solid #b1afaf;
}
.threecard-head h3 {
    font-weight: 700;
    font-size: 14px;
    padding: 0 0 0 10px;
}
.bottomCard {
    border-bottom: 1px solid #b1afaf;
    padding: 5px 5px;
    border-right: 1px solid #b1afaf;
    border-left: 1px solid #b1afaf;
    border-radius: 0 0 5px 5px;
    background-color:#f7f7f7!important
}
.other-cardss img {
    display: none;
    width: 32px;
    margin-bottom: 3px;
}
.model_head {
    border-bottom: 0;
    padding: 0;
}
.contect-soon {
    background: #fff;
    border: solid 2px transparent;
}
.modal-dialog.model_contact {
    margin: 2.75rem auto;
}
.soon_text {position: relative;
    min-height: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.soon_text a {
    font-size: 26px;
    color: #8c0000;
    margin: 0 0 18px 0;
    font-weight: 600;
    display: block;
}
.book-text {
    width: 100%;
}
.book-imgs{display: none;
    width: 120px;
    border-radius: 50%;
    line-height: 120px;
    background: #fffde4;
    margin: -35px auto 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .4);
    height: 120px;
}
.soon_text img {
    width: 70px;
}
.soon_text h5 {
    font-size: 14px;
    color: #333;
    margin: 20px 0 10px 0;
    font-weight: 600;
}
.cont-titles h5{font-size: 14px;
    color: #333;
    font-weight: 600;}
.contact-later1 {
    padding: 0 !important;
}
.soon_text h2 {
    text-align: center;
    font-size: 33px;
    font-style: italic;
    color: #ff0600;
    font-weight: 700;
    padding: 5px 0 12px 0;
}
.soon_text h6 {
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: 700;
    padding: 0 40px;
    line-height: 22px;
}
.soon_text h6 span {
    display: block;
    font-weight: 700;
    color: #ff0600;
    font-size: 31px;
    margin-top: 12px;
}
.bottom_btn {
    background: #fff;
    padding: 0 30px 5px 30px;
    display: table;
    width: 100%;
}
.request_btn {
    border: 0;
    background: #ff0600;
    color: #fff;
    padding: 15px 12px!important;
    border-radius: 2px;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0;
    display: block;
    width: 100%;
    font-weight: 700;
    line-height: 15px;
}
.gst-btn, .customerKycBackground .airModes {background: #000!important;
    border-color: #000000!important;
    color: #fff;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    outline: 0!important;
    border-top: 2px solid #000000!important;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}
.gst-top {
    border-color: #ff0600!important;
    background-color: #ff0600 !important;
    color: #fff !important;
}
.gst-top:hover, .gst-btn:hover {
    background-color: #ff0600!important;
    color: #fff;
    border-top: 2px solid #ff0600!important;
}
.blue-gst1 input, .blue-gst1 select {
    border-radius: 4px;
    border: 1px solid #ababab;
    height:40px !important;
    padding: 0 10px;
}
.select-gst {
    padding: .375rem .75rem !important;
    border: 1px solid #ababab !important;
    height: 35px !important;
}
.blue-gst1 .custom-file-label {
    padding: 8px 10px;
    height: 40px !important;
    line-height: inherit;
    border-radius: 4px;
}
.blue-gst1 label {
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;
}
.blue-gst1 .custom-file-label::after {
    color: #fff;
    background-color: #ff0600;
    border-left: 1px solid #ff0600;
    height: 39px;
    padding: 0 10px;
    line-height: 39px;
    border-radius: 0 4px 4px 0;
}
.blue-gst1 .custom-file {
    width: 100%;
}
.btn-add, .upload-inputs .btn-secondary {
    min-height: 35px;
    background: #ff0600;
    border: 1px solid #ff0600;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    padding: .375rem .75rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
}
.next-btns {
    background: #ff0600;
    color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.ornage1 {
    background: #000000 !important;
    border: 1px solid transparent;
}
.branch-form-4 {
    margin-bottom: 15px;
}
.branch-formstart .input-branch {
    height: 38px;
    border: 1px solid #ababab;
}
.branch-formstart label {
    color: #202020;
    font-weight: 700;
    font-size: 15px;
    text-transform: capitalize;
}
.other-branch {
    padding: 10px 0;
}
.other-branch .add, .other-branch .delete {
    background-color: #000000;
    color: #fff;
    border-radius: 50px;
    padding: 12px 25px 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}
.list-query {
    text-align: left;
    margin-bottom: 15px;
    width: 25%;
}
.list-query h5 {
    font-size: 14px;
}
.ship-airbill {
    max-width: 900px;
}
.dis-background {
    cursor: no-drop;
    background: #d6cfcf !important;
    border: 1px solid #bfbbbb;
}
.circle-track {
    padding: 15px;
}
.circle-icon {
    background: linear-gradient(96deg, #173ebc, #173ebc);
    width: 80px;
    border-radius: 50px;
    height: 80px;
    margin: auto;
    text-align: center;
    line-height: 80px;
    font-size: 25px;
    color: #000;
}
.track1 {
    text-align: center;
    color: #000;
}
.track1 h3 {
    text-transform: uppercase;
    font-size: 15px;
    color: #000;
    font-weight: 700;
}
.track1 p {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
}
.booking-information {
    background: #fff;
    padding: 15px 0;
}
.booking-information h6 {
    margin-bottom: 3px;
}
.booking-information h2 {
    color: #173ebc;
    margin-bottom: 8px;
}
.booking-information h4 {
    font-weight: 700;
    font-size: 13px;
}
.booking-information p {
    margin: 0;
}
.booking-information td {
    padding: .5rem;
    font-weight: 600;
    vertical-align: middle;
    border-color: transparent !important;
}
.booking-information th {
    padding: .5rem;
    border-color: transparent !important;
    font-weight: 700;
}
.booking-information p, .booking-information td, .booking-information th {
    font-size: 13px;
    text-align: center;
}
.awbs-number {
    width: 48%;
}
.img-tracks {
    padding-right: 50px;
}
.booking-information h3 {
    font-size: 15px;
}
.circle-icon img {
    width: 55px;
}
.invoice-btn {
    padding: 8px 16px;
    margin-right: 2px;
    margin-bottom: 20px;
    text-transform: capitalize;
    margin-top: 0;
    font-size: 12px;
    border-radius: 50px !important;
    background: #ff0600;
    box-shadow: 0 4px 0 #ea6f00, 0 5px 5px 1px rgba(0, 0, 0, .4);
    border-color: #ff0600;
}
.react-multi-carousel-list {
    position: initial !important;
}
.super-offer .react-multiple-carousel__arrow--left {
    left: -2px !important;
}
.super-offer .react-multiple-carousel__arrow--right {
    right: -2px !important;
}
.market-section .react-multiple-carousel__arrow--left {
    left: 42px;
}
.market-section .react-multiple-carousel__arrow--right {
    right: 30px;
}
.market-section .react-multiple-carousel__arrow, .super-offer .react-multiple-carousel__arrow {
    min-width: 30px !important;
    min-height: 30px !important;
    width: 30px;
    height: 30px;
    z-index: 1 !important;
    background: #fff !important;
    border: 1px solid #ddd !important;
    border-radius: 50px !important;
    line-height: 28px !important;
}
.market-section .react-multiple-carousel__arrow, .super-offer .react-multiple-carousel__arrow {
    min-width: 35px !important;
    min-height: 35px !important;
    width: 35px !important;
    height: 35px !important;
}
.market-section .react-multiple-carousel__arrow--left::before, .market-section .react-multiple-carousel__arrow--right::before {
    font-size: 24px !important;
}
.react-multiple-carousel__arrow--left::before {
    color: #173ebc !important;
    font-family: "FontAwesome" !important;
    font-weight: 900 !important;
    content: "\f104" !important;
    font-size: 20px !important;
}
.react-multiple-carousel__arrow--right::before {
    color: #173ebc !important;
    font-family: "FontAwesome" !important;
    font-weight: 900 !important;
    content: "\f105" !important;
    font-size: 20px !important;
}
.animate-shake {
    animation: .15s ease-in 2s infinite alternate animate-shake;
    animation-fill-mode: initial;
    top: 1px;
    position: relative;
}
@keyframes animate-shake {
    0% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }
    100% {
        -webkit-transform-origin: center center;
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }
}
.notification-size {
    box-shadow: rgb(114 114 114 / 40%) 0 1px 7px 0;
    min-width: 300px;
    height: 400px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 0;
    border-bottom: 1px solid transparent;
}
.notify-title {
    border-bottom: 1px solid #ddd;
    margin-bottom: 13px;
    padding-bottom: 5px;
}
.navbar-nav .nav-link {
    margin-left: 0.5rem;
    padding-left: 0 !important;
}
.notification-bell {
    width: 40px;
    height: 40px;
    background: #f8f8f8 !important;
    border-radius: 50px;
    text-align: center;
    padding: 0;
    font-size: 17px;
    line-height: 46px!important;
}
.notification-bell i,.notification-bell svg {
    font-size: 23px;
    color: #ff0600;
}

.currency_icon i,.currency_icon svg {
    font-size: 23px;
    color: #ff0600;
}
.notification-bell.btn .badge {
    position: absolute !important;
    top: -10px !important;
    right: -10px !important;
    padding: 3px 3px;
    background: #ff0600;
    color: #fff;
    font-size: 12px;
}
.contact-number .call_number img {
    width: 38px;
}
.left-right {
    animation: swing ease-in-out 1s infinite alternate;
}
@keyframes swing {
    0% {
        transform: rotate(3deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}
.right-5 {
    margin-right: 5px;
}
.news-section {
    position: relative;
}
.news-shadow {
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
    margin: 0 5px 20px 5px;
    -moz-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
    border-radius: 6px;
    overflow: hidden;
    background: #fff;
}
.news-section .post-imgs {
    margin-right: 0 !important;
}
.news-shadow .day {
    color: #173ebc;
    font-weight: 600;
}
.news-section .post-imgs>img {
    width: 100%;
    border-radius: 0;
    height: auto;
}
.post-text {
    padding: 0 10px 10px;
}
.news-section .post-title {
    position: relative;
}
.news-section .post-title>a {
    font-size: 16px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}
.news-section .post-title>a:hover {
    text-decoration: none;
}
.news-section .post-description {
    color: #000;
    font-size: 13px;
    line-height: 20px;
}
.news-section .post-slide:hover .post-title>a {
    color: #173ebc;
    transition: .3s ease;
}
.news-section .react-multiple-carousel__arrow--left {
    left: inherit;
    right: 170px;
    top: 55px;
}
.news-section .react-multiple-carousel__arrow--right {
    right: 125px;
    top: 55px;
}
.news-section .react-multiple-carousel__arrow {
    background: #f1f1f1 !important;
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 0;
    z-index: 1 !important;
    min-width: 35px;
    min-height: 35px;
    opacity: 1;
    cursor: pointer;
    border: 1px solid #ddd;
}






.ads-section h2, .faq-section h2, .vedio-media h2 {
    font-size: 35px;
}
.vedio-media .react-multiple-carousel__arrow--left::before, .vedio-media .react-multiple-carousel__arrow--right::before {
    color: #fff !important;
}
.ads-section {
    background: #f4f4f4;
    padding: 50px 0 70px;
}
.vedio-media {
    background: #fff;
    padding: 50px 0;
}
.vedio-media .react-multiple-carousel__arrow {
    z-index: 1 !important;
}
.ads-section .post-slide {
    padding: 0 15px 0 0;
}
.news-page h2 {
    padding-bottom: 8px;
    position: relative;
}
.news-page h2:after {
    border: 1px solid #173ebc;
    display: inline-block;
    width: 50px;
    position: absolute;
    left: 0;
    bottom: -1px;
}
.news-page h2 span {
    color: #173ebc;
}
.ads-section .react-multiple-carousel__arrow {
    z-index: 1 !important;
    background: #fff;
    border: 1px solid #173ebc;
}
.ads-shadow {
    border-radius: 4px;
    border: solid 1px #eaeaea;
    background: #fff;
}
.ads-img {
    width: 30%;
}
.ads-img img {
    width: 100%;
    height: 110px;
    object-fit: cover;
}
.ads-text {
    width: 70%;
    height: 110px;
    padding: 10px 15px;
}
.ads-text p {
    margin-bottom: 0;
}
.ads-text p a {
    color: #000;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
}
.ads-text h2 {
    font-size: 1rem;
    line-height: 1.25rem;
    min-height: 1.25rem;
    max-height: 3.75rem;
    margin-bottom: 0;
    color: #000;
}
.ads-text h2 a {
    color: #173ebc;
    font-weight: 700;
    font-size: 18px;
}
.vedio-media .news-shadow img {
    height: 280px
}
.news-section .news-shadow img {
    height: 160px
}
.faq-section {
    background: #f1f1f1;
    padding: 50px 0 60px 0
}
.faq-section .collapsible-link::before {
    content: '';
    width: 14px;
    height: 2px;
    background: #173ebc;
    position: absolute;
    top: calc(50% - 1px);
    right: 1rem;
    display: block;
    transition: all .3s
}
.faq-section .collapsible-link::after {
    content: '';
    width: 2px;
    height: 14px;
    background: #173ebc;
    position: absolute;
    top: calc(50% - 7px);
    right: calc(1rem + 6px);
    display: block;
    transition: all .3s
}
.faq-section .collapsible-link[aria-expanded=true]::after {
    transform: rotate(90deg) translateX(-1px)
}
.faq-section .collapsible-link[aria-expanded=true]::before {
    transform: rotate(180deg)
}
.faq-question {
    border-bottom: 1px solid #ddd;
    padding: 10px 0
}
.faq-question h6 a {
    color: #000
}
.faq-answer {
    padding: 15px;
    background: #fff
}
.faq-answer p {
    font-weight: 500;
    font-size: 14px;
    color: #5a5a5a
}
.faq-question h6 a:hover {
    color: #173ebc
}
.faq-question:hover {
    border-bottom: 1px solid #173ebc
}
.faq-question:hover h6 a:hover {
    color: #173ebc
}
.feed-img {
    margin-right: 10px
}
.feed-img img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover
}
.feeds-section {
    background: #fff;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 450px;
    padding: 15px 15px;
    border-radius: 5px
}
.feed-all {
    padding: 15px 8px;
    border: 1px solid #f7f7f7;
    margin-bottom: 8px;
    border-radius: 10px;
    background: #f1f1f1;
    overflow: hidden
}
.feed-text h6 {
    font-size: 13px
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2)
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 54%)
}
.ships-padding {
    margin: 35px 0 0
}
.blue-gst1 {
    margin: 25px 0 20px;
}
.media-btn {
    margin-right: 10px;
    background: #f4f4f4;
    padding: 7px 30px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600
}
.active-media {
    background: #173ebc;
    color: #fff
}
.model_offer {
    max-width: 600px
}
.offer_head .close {
    z-index: 1;
    right: 7px;
    font-size: 20px;
    position: absolute;
    background: #ff0600;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 1px solid #fff;
    line-height: 20px
}
.offer_head .close span {text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 30px;
}
.coupon_Code {
    display: inline-block;
    position: relative;
    border: 1px #000000 dashed;
    padding: 0 15px;
    vertical-align: top;
    margin-bottom: 10px;
}
.frieght-center td, .frieght-center th {
    text-align: center
}
.about_us .section-title h2 {
    font-size: 30px;
    color: #fff !important;
    font-weight: 700
}
.section-title1 h2 {
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 25px
}
.about_us .section-title p {
    color: #fff !important;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;

}
.dates {
    font-weight: 600;
    font-size: 17px
}
.dates i,.dates svg {
    color: #5d5d5d;
    margin-right: 5px
}
.pinterest {
    background: linear-gradient(29.61deg, #f38334 0, #da2e7d 50.39%, #6b54c6 100%) !important
}
.about_us .section-title h2 {
    font-size: 30px;
    color: #fff !important;
    font-weight: 700
}
.blue-wrappers {
    position: relative;
    padding: 40px 80px
}
.about_us:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgb(0 0 0 / 38%) 0, rgb(0 0 0 / 85%) 100%) !important;
    height: 100%;
    width: 100%
}
.section-title h2, .section-title p {
    position: relative
}
.about-video {
    padding-bottom: 20px !important
}
.iframe-video {
    margin-top: -50px !important
}
#video {
    text-align: center;
    margin: auto;
    z-index: 9
}
.cart-model {
    background: #f1f1f1;
    width: 360px;
    border-radius: 0;
    border: none;
    bottom: 0;
    left: auto;
    outline: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    right: 0;
    top: 0;
    margin: 0;
    transition: right .4s ease;
    position: absolute
}
.promo-model {
    padding: 10px 15px !important;
    overflow-y: auto
}
.offer-head {
    padding: 15px 10px !important;
    border-radius: 0;
    background: #233bb3;
    color: #fff
}
.cart-model .modal-content {
    background: 0 0;
    height: 100%;
    border-radius: 0;
    border: none
}
.promo-ads {
    margin-bottom: 10px
}
.offer-btns a {
    cursor: pointer;
    border-radius: 50px;
    color: #008cff !important;
    text-decoration: underline !important;
    font-weight: 600;
    font-size: 15px
}
.other-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 41px!important;
    background: #fff
}
.market-section {
    background-color: #fff;
    position: relative;
    background-image: url(https://d2ra2wln3jgzfj.cloudfront.net/assets/img/sun-set.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}
.market-section .title-newss {
    font-size: 27px;
    margin-bottom: 10px;
    padding: 50px 0 0;
    font-weight: 600;
    color: #f8f8f8
}
.text-box h2 {
    font-size: 23px;
    color: #173ebc;
    margin-bottom: 20px;
    font-weight: 600
}
.text-box p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400
}
.other-news {
    padding: 0;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    list-style: none;
    height: 520px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.other-news li {
    padding-left: 10px
}
.other-news li .text {
    cursor: pointer
}
.text-news {
    font-weight: 500;
    color: #fff;
    margin: 5px 0 20px
}
.other-news h3 {
    font-size: 17px;
    font-weight: 700;
    color: #fff;
    line-height: 25px
}
.news-big {
    padding: 50px 30px 50px 50px
}
.news-big h4 {
    font-weight: 700;
    font-size: 20px;
    color: #f8f8f8
}
.news-btns {
    border-radius: 50px;
    transition: all .2s;
    text-transform: capitalize;
    padding: 8px 16px;
    background: linear-gradient(96deg, #173ebc, #173ebc);
    margin-top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    box-shadow: 0 4px 0 #3436a4, 0 5px 5px 1px rgba(0, 0, 0, .4) !important
}
.news-btns:active {
    transform: scaleX(.9) translateY(4px)
}
.new_images img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    padding-bottom: 15px
}
.text-box {
    padding: 0 20px
}
.news-big .item-box1 {
    border: 1px solid #b7b7b7;
    overflow: hidden;
    background: #fff;
    padding-bottom: 20px;
    border-radius: 9px
}
.date-news {
    text-align: center;
    margin-top: 8px;
    background: #173ebc;
    color: #fff;
    padding: 7px 17px;
    line-height: 1.3;
    margin-right: 8px;
    border-top: 2px solid #173ebc;
}
.news-page, .offerss-page {
    padding: 40px 0
}
.news-page {
    padding: 20px 0 10px
}
.head-menuss.navbar-nav .nav-link, .navbar-nav .nav-link {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #202020!important;
}
.pin {
    text-align: center
}
.shadow {
    box-shadow: -40px 40px 160px 0 rgba(0, 0, 0, .08), -8px 8px 15px 0 rgba(120, 120, 120, .04), 3px 3px 30px 0 rgba(0, 0, 0, .04) !important
}
.mission-vission ,.vission-mission{
    background: #FAFAFA;
    padding: 80px 0;
    min-height: 550px
}
.value-banner {
    background: #FAFAFA;
    padding: 120px 0 80px;
    min-height: 550px
}
.text-vision h3 {
    font-weight: 700;
    font-size: 30px;
    color: #ff0600;
    padding-bottom: 20px
}
.text-vision p{
    font-size: 17px;
    color: #000;
    font-weight: 500;
    line-height: 30px;
}
.news-thumb img {
    width: 100%;
    height: 100px
}
.news-post .news-thumb {
    width: 35%;
    float: left
}
.news-post .news-descright {
    float: right;
    padding-left: 10px;
    width: 65%
}
.news-header p {
    margin: 0
}
.day {
    color: #173ebc;
    font-weight: 600
}
article.news-post {
    margin-bottom: 20px;
    cursor: pointer
}
.big-post img {
    max-width: 100%;
    height: 450px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23)
}
.bignews-thumb {
    text-align: center
}
.big-post h2 {
    margin: 15px 0 10px
}
.big-post h2 a {
    font-size: 20px !important
}
.newss_box-lg {
    display: inline-block;
    position: relative;
    color: #fff;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
    z-index: 2
}
.new-border:after {
    position: absolute;
    display: block;
    content: "";
    bottom: 0;
    left: 15px;
    right: 15px;
    z-index: 2;
    height: 2px
}
.borders-news:before {
    border-top: 8px solid #173ebc;
    border-right: 8px solid transparent
}
.newss-posts {
    padding: 40px 0
}
.newss_box-lg:before {
    z-index: 1;
    content: "";
    top: 0;
    right: -8px;
    position: absolute;
    width: 0;
    height: 0
}
.borders-news:after {
    background-color: #173ebc
}
.newss-section {
    padding: 0 40px
}
.newss_box-lg:after {
    content: "";
    height: 100%;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}
.air-freight img {
    box-shadow: 1.25rem 1.375rem 4.75rem 0 rgba(0, 0, 0, .22) !important;
    border-radius: 10px
}
.second-air {
    margin-top: -40px
}
.product-page {
    background: #fff;
    padding: 40px 0
}
.offerss-page {
    min-height: 90vh;
}
.product-title h2 {
    color: #000;
    font-weight: 700;
    font-size: 26px
}
.product-page p {
    font-size: 16px;
    line-height: 21px;
    color: #000;
    text-align: justify
}
.product_content {
    padding: 25px 30px
}
.product_content2 {
    padding: 30px 30px
}
.ocean_content {
    padding: 60px 40px
}
.documentation, .domstics, .other-service, .warehouse {
    padding: 20px 0 40px
}
span.navbar-toggler-icon:before {
    content: "\f0c9" !important
}
.navbar-light .navbar-toggler-icon {
    background-image: none;
    color: #ff0600 !important;
    /*font-family: "Font Awesome 5 Free" !important;*/
    font-family: "FontAwesome" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    content: "\f105" !important;
    position: relative
}
.our_offer .modal-content {
    width: 650px
}
.offers-img img {
    width: 240px
}
.offer-image {
    padding: 60px 30px !important;
    border-top: 5px solid #ff9a00;
    order: 0
}
.offer-image h2 {
    font-family: Sriracha, cursive;
    font-size: 36px;
    background: -webkit-linear-gradient(#173ebc, #002496);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #00091721;
    line-height: 42px;
    text-align: center;
    font-weight: 700
}
.coupon-label {
    width: 100%
}
.coupon-label span {
    font-weight: 700;
    margin-bottom: 15px
}
.coupon-label input {
    border-radius: 50px;
    border: 2px solid #2b63cc;
    margin: 40px 0 10px;
    height: 40px;
    background: #2b63cc0f
}
.our_offer .close {
    position: absolute;
    right: 0;
    z-index: 1;
    background: #243bb2;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 0
}
.our_offer .close span {
    color: #fff
}
.cupon-codes {
    width: 20px;
    height: 20px;
    background: #fff;
    color: #542f8b;
    border-radius: 50px;
    line-height: 21px;
    margin-left: 6px;
    font-weight: 700
}
#accordion .panel {
    border: none;
    border-radius: 3px;
    box-shadow: none;
    margin-bottom: 15px
}
#accordion .panel-heading {
    padding: 0;
    border: none;
    border-radius: 3px
}
#accordion .panel-title a {
    display: block;
    padding: 12px 15px 12px 12px;
    background: linear-gradient(to bottom, #fefefe, #cdcdcd);
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    text-shadow: 1px 1px 1px #fff;
    position: relative
}
#accordion .panel-body {
    padding: 10px 15px;
    font-size: 15px;
    color: #222;
    line-height: 27px;
    border: none
}
#accordion .panel-body p {
    margin-bottom: 0
}
.month_wise {
    width: 20%;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer
}
.month_wise i,.month_wise svg {
    color: #173ebc;
    font-weight: 700;
    font-size: 25px
}
.month_wise p {
    font-weight: 600;
    color: #000
}
.terms-list {
    padding: 0 0 0 25px;
    list-style-type: disc;
}
.terms-list li{font-size: 14px;
    font-weight: 400;
}
.term-test {
    padding-bottom: 0 !important;
    margin-bottom: 8px;
    font-weight: 600;
    display: inline-block;
    font-size: 19px;
    color: #000000;
}
.offer_inside{padding:0 10px 20px;}
.vision_text {
    padding: 110px 80px
}
.value_text {
    padding: 150px 80px
}
.coupon-under {
    list-style-type: circle
}
input[type=radio] {
    width: auto;
    outline: 0;
    border-radius: 0;
    border: 1px solid transparent;
    font-size: 13px;
    padding: 0;
    height: auto;
    background: #f3e8e5
}
.charge-input {
    background: #fff;
    cursor: not-allowed
}
.form-control:disabled {
    cursor: not-allowed!important;
}
.menu-other {
    display: none
}
.tracking-model {
    max-width: 800px
}
.arrow-track {
    height: fit-content
}
.image-change {
    background: #f1f1f1;
    padding: 85px 0 0;
    min-height: 100vh;
}
.blue-gst1 .upload-kyc .custom-file-label {
    padding: 0 0.75rem;
    height: 46px !important;
    line-height: 46px;
}
.blue-gst1 .upload-kyc .custom-file-label::after {
    padding: 0 0.75rem;
    height: 45px !important;
    line-height: 45px;
}
.upload-kyc .upload-inputs .btn-secondary {
    padding: 0 0.75rem;
    height: 45px !important;
    line-height: 45px;
}
.blue-gst1 .upload-kyc .custom-file {
    margin-bottom: 15px;
}
.ticker-box .post-img>img {
    width: 40px;
    height: auto;
    border-radius: 5px;
}
.minus-plus {
    list-style: none;
    margin: 0;
    padding: 0
}
.minus-plus li {
    display: inline-block;
    margin-right: 10px;
}
.minus-plus .fa-plus {
    width: 20px;
    height: 20px;
    background: #000;
    line-height: 22px;
    text-align: center;
    padding: 0 3px;
    color: #fff;
    border-radius: 50px;
}
.minus-plus .fa-minus {
    width: 20px;
    height: 20px;
    background: #000;
    line-height: 22px;
    padding: 0 3px;
    text-align: center;
    color: #fff;
    border-radius: 50px;
}
/* console shipment start */
.AAA-editbooking-console-shipment-3 {
    padding-top: 0rem;
}
.AAA-editbooking-console-shipment-3 .form-check {
    padding-left: 0rem;
}
.AAA-editbooking-console-shipment-4 {
    text-align: left;
}
.AAA-editbooking-console-shipment-6 {
    margin-top: 10px;
}
.AAA-editbooking-house-details-3 {
    margin-top: 24px;
}
.AAA-editbooking-house-details-3 input {
    float: left;
    width: 17px;
    height: 17px;
}
.AAA-add-query-dimension-5 label {
    color: #333333;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: .2px;
    font-weight: 500;
    margin-bottom: 8px;
}
.AAA-add-query-dimension-5 input {
    border: 1px solid #ababab;
    border-radius: 4px;
    height: 40px;
    line-height: 28px;
    width: 100%;
}
.csv-reader-input input {
    border: 1px solid transparent!important;
    outline: 0;
}
.AAA-add-query-dimension-7 hr {
    border-top: 1px solid #efefef;
    margin-top: 0;
    margin-bottom: 15px;
}
.AAA-add-query-dimension-7 label {
    color: #000;
    font-size: 0.8rem;
    text-transform: capitalize;
    letter-spacing: .2px;
    font-weight: 600;
    margin-bottom: 3px;
}
.AAA-add-query-dimension-7 input, .AAA-add-query-dimension-7 select {
    border-radius: 50px;
    height: 38px!important;
    font-size: 13px;
    border: 1px solid #ababab;
    line-height: 38px;
    padding: 0 15px;
}
.AAA-add-query-dimension-7 ul {
    padding: 0;
    margin: 0;
}
.AAA-add-query-dimension-7 ul li {
    float: left;
    list-style: none;
    margin-right: 7px;
    width: 19%;
}
.css-1hwfws3, .css-bg1rzq-control {
    height: 38px;
    line-height: 38px;
}
.AAA-add-query-dimension-7 ul li:last-child {
    margin-right: 0;
}
.AAA-add-query-dimension-7 ul li .invalid-feedback {
    width: 100%;
    margin-top: -5px;
    font-size: 12px;
    color: #ff3d36;
    font-weight: 400;
}
.AAA-add-query-dimension-7 ul li .btn-success:focus {
    outline: none;
    box-shadow: none !important;
}
.AAA-add-query-dimension-7 ul li .btn-success:hover {
    opacity: .8;
}
.AAA-add-query-dimension-7 ul li .btn-success span {
    padding-right: 4px;
}
.AAA-add-query-dimension-8 label {
    color: #000000;
    font-size: 15px;
    text-transform: capitalize;
    letter-spacing: .2px;
    font-weight: 700;
    margin-bottom: 8px;
}
.AAA-add-query-dimension-8 ul {
    padding: 0;
    margin: 0;
}
.AAA-add-query-dimension-8 ul li {
    float: left;
    list-style: none;
    margin-right: 7px;
    width: 16.15%;
}
.AAA-add-query-dimension-8 ul li:last-child {
    margin-right: 0;
}
.AAA-add-query-dimension-8 ul li .invalid-feedback {
    width: 100%;
    margin-top: -5px;
    font-size: 12px;
    color: #ff3d36;
    font-weight: 400;
}
.AAA-add-query-dimension-8 ul li .btn-danger {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    padding: 9px 10px 6px;
}
.AAA-add-query-dimension-8 ul li .btn-danger:focus {
    outline: none;
    box-shadow: none;
}
.AAA-add-query-dimension-8 ul li .btn-danger:hover {
    opacity: .8;
}
.AAA-add-query-dimension-8 ul li .btn-danger span {
    padding-right: 3px;
}
.AAA-add-query-dimension-8 ul li .form-group .form-control {
    box-shadow: none;
}
.AAA-editbooking-house-details-5 {
    margin-top: 10px;
}
.AAA-editbooking-house-details-5 label {
    color: #000;
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-weight: 600;
    margin-bottom: 0 !important;
}
.AAA-editbooking-house-details-6 label {
    color: #000;
    font-size: 16px !important;
    text-transform: capitalize !important;
    letter-spacing: .2px !important;
    font-weight: 500;
    margin-bottom: 10px !important;
}
/* console shipment end */
.ships-model {
    max-width: 800px;
}
.border-rad input, .border-rad select {
    border-radius: 4px;
    height: 40px;
    border: 1px solid #ababab;
}
.border-red .mycustom1, .houseDetails input,.houseDetails select {
    border-radius: 4px!important;
    height: 40px!important;
    border: 1px solid #000!important;
}
.text .post-title {
    font-size: 14px;
}
.mbl-img p {
    margin: 0;
    line-height: 14px;
}
.mbl-img {
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url('../../assets/img/target001.png');
    width: 817px;
    height: 1150px;
    background-position: 82% 0%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .36);
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
}
.change-links {
    padding-bottom: 15px;
    text-align: center;
}
.change-box {width: 80px;
    background: #fff;
    height: 85px;
    padding: 5px 5px;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    margin-right: 16px;
    box-shadow: -1px 5px 7px 0 rgb(0 0 0 / 52%);
}
.change-box.active {
    width: 80px;
    height: 85px;
    transform: none;
    border-bottom: 3px solid #000000 !important;
    background: #fff !important;
    padding: 5px 5px;
    font-size: 12px;
    color: #000000;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
}
.change-box.active:before {
    content: '';
    position: absolute;
    border-bottom: solid 10px #000000;
    border-left: solid 10px #ed0a0e00;
    border-right: solid 10px #ed0a0e00;
    bottom: -12px;
    left: 42%;
    transform: rotate(180deg);
}
.commos-widths {
    width: 100%;
}
.change-box.active p {
    color: #000000;
}
.icon-boxx {
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    background: #000000;
    line-height: 50px;
    margin: auto;
}
.sec-change {
    background: #000000;
}
.calculate-inputs ul li {
    width: 13%!important;
}
.calculate-inputs select,.calculate-inputs input{
    border-radius: 4px;
}
.calculate-inputs label {
    font-weight: 600!important;
    font-size: 13px!important;
}
.calculate-inputs .btn-success {
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    height: 38px;
    padding: 0 20px;
}
.hbl-img1 {
    background-image: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.015590545111315857-1647941007033.png');
    box-shadow: none!important;
}
.hbl_size{width: 850px;
    margin:auto;
    padding:20px 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .36);
}
/*/
///////////////////////////////spot rate ticker//////////////////////
/*/
.ticker-box {
    position: relative;
}
.quickbutton {
    z-index: 1;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -32px;
    top: -19px;
    width: 66px;
    height: 10px;
    cursor: pointer;
}
.quickbutton:before {
    position: absolute;
    content: "";
    border-bottom: 20px solid transparent;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    width: 64px;
    left: 0;
}
.quickbutton:after {
    position: absolute;
    content: "";
    border-bottom: 17px solid #D9D9D9;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    z-index: 0;
    width: 64px;
    top: 2px;
    left: 0;
}
.quickbutton span {
    display: inline-block;
    position: relative;
    z-index: 2;
    font-size: 15px;
    color: #000;
    top: 2px;
    left: 0;
}
.ticker-data {
    background: #FAFAFA;
    border-top: 1px solid #CDD1DB;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 0 0 5px 5px;
}
.ticker-data .more-spot {
    border-bottom: 1px dotted #D9D9D9;
    padding: 6px 0;
}
.ticker-data .post-title {
    margin-bottom: 0;
}
.ticker-data .post-title>a {
    font-size: 13px;
}
.ticker-data .text1 {
    font-size: 12px;
    color: #f12a26;
}
.more-spot .text1 .strike {
    font-size: 11px;
}
.border-spots {
    width: 2px;
    height: auto;
    border: 1px solid #c6c6c6;
}
.strike {
    font-size: 13px;
    font-weight: 400;
    color: #777;
}
.slick-next, .slick-prev {
    width: 30px!important;
    height: 30px!important;
    background: #000!important;
    text-align: center!important;
    color: #fff;
    border-radius: 50px!important;
    border: 1px solid #f78b774d!important;
}
.slick-next {
    position: absolute!important;
    left: auto!important;
    right: -19px!important;
    z-index: 1;
}
.slick-prev {
    position: absolute!important;
    left: -19px!important;
    z-index: 1!important;
}
.slick-prev:before{
    color: #fff !important;
    opacity: 1!important;
    font-family: "FontAwesome" !important;
    font-weight: 900 !important;
    content: "\f104" !important;
    font-size: 20px !important;
}
.slick-next:before{
    color: #fff !important;
    opacity: 1!important;
    font-family: "FontAwesome" !important;
    font-weight: 900 !important;
    content: "\f105" !important;
    font-size: 20px !important;
}
.pd-5 {
    padding: 0 5px;
}
.offer-box {
    background: #fff;
    padding: 0 20px 0 5px;
    border-radius: 0;
}
.offer-box .post-img>img {
    width: 40px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
}
.offer-box .text1, .ticker-data .text1 {
    color: #000;
    font-weight: 600;
    font-size: 13px;
}
.offer-box .text .post-title, .ticker-data .post-title {
    font-size: 14px;
    margin-bottom: 8px;
    position: relative;
}
.offer-box .post-title>a, .ticker-data .post-title>a {
    font-size: 14px;
    color: #202020;
    font-weight: 700;
    text-transform: capitalize;
}
.ticker-data .post-title>a {
    font-size: 13px;
}
.ticker-data .text1 {
    font-size: 12px;
    color: #f12a26;
}
.ticker-data .post-title {
    margin-bottom: 0;
}
.ticker-data .delete, .ticker-data .edit {
    padding: 3px 10px;
    font-size: 10px;
}
.edit, .delete {
    border: none;
    background: #000000!important;
    color: #fff;
    width: auto;
    display: block;
    padding: 7px 20px 5px;
    line-height: 20px;
    text-align: center;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 600!important;
    font-size: 11px;
    cursor: pointer;
    margin-right: 5px;
}
.delete {
    background: #000000!important;
}
.delete:hover, .last-ship:hover {
    background: #ff0600!important;
}
.ticker-btns {
    width: 95%;
    padding: 0 23px;
    margin: 10px 0;
}
.un-style .custom-file-label::after {
    background: #173ebc;
    color: #fff;
}
.about-para span {
    font-weight: 800;
    color: #ff0600;
}
/*/
////////////////////////////booking text//////////////////////
/*/
.forms .padding-mawb{box-shadow: 0 4px 15px 0 rgb(0 0 0 / 24%);}
.width-4 {
    margin-right: 6px;
    width: 24%;
}
.ipt11 {
    height: 40px;
    border-radius: 4px;
}
.width-4 .css-bg1rzq-control {
    height: 40px;
}
.origin-forms .origin-label {
    font-weight: bold;
}
.btns-master {
    background: #ff0600;
    color: #fff;
    font-weight: 700;
    border-radius: 50px;
    font-size: 13px;
    padding: 7px 16px;
}
.widths-16 {
    width: 16%;
    margin-right: 6px;
}
.widths-15 {
    width: 15%;
    margin-right: 8px;
}
.width-3 {
    width: 32%;
    margin-right: 10px;
}
.text-dark {
    color: #000000!important;
}
.mbl-pdf {
    width: 100%;
    border-radius: 5px;
    margin: auto;
    background: #ebebeb;
}
.mbl-back {
    width: 817px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .36);
    position: relative;
    margin: 0 auto;
    padding: 10px 10px 165px;
    background: #fff;
    border-radius: 4px;
    height: auto;
    color: #000;
}
.mbl-left {
    width: 50%;
    float: left;
    padding: 0 5px 0 0;
}
.first-part h4, .second-part h4 {
    margin: 4px 0 0;
    font-size: 9px;
    font-weight: 700;
}
.first-part p, .second-part p {
    margin-bottom: 0;
    font-size: 9px;
    /*font-weight: 500;*/
    line-height: 16px;
}
.mbl-back ul {
    margin: 0;
    padding: 0 0 0 10px;
}
.mbl-back ul li {
    font-size: 9px;
}
.shippers-popup .width-4 {
    width: 100%;
    margin: 0;
}
.shippers-popup .css-1pcexqc-container.col-lg-6, .lg-value .css-1pcexqc-container.col-lg-6 {
    max-width: 100%;
    flex: none;
    padding: 0;
}
.mail-quot {
    background: #eef6ff9c;
    padding: 10px 20px 0;
}
.oringin-master h3 {
    font-size: 17px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
}
.mail-quot label {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
}
.button-groups .rounded-circles {
    border-radius: 50px;
    padding: 10px 26px;
    font-size: 12px;
}
.other-branch .delete {
    display: initial;
}
/*/
////////////////////////landing page////////////////////////
/*/
#wrapper {
    background: #0000001a;
    background: url('../img/backlanding.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    /*padding-top: 20px;
    */
}
.myfirst-box {
    background-position: center;
    background-size: cover;
}
.mysecond-box {
    background-position: center;
}
.services h1 {
    color: #a6a6a6;
}
.services p {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 40px;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 22px;
    color: #737373;
}
.services h1 span:before {
    content: '/';
    padding-right: 10px;
    font-weight: 100;
}
.services h1 span:before {
    color: #e600ff;
}
.services h1 span:after {
    content: '/';
    padding-left: 10px;
    font-weight: 100;
}
.services h1 span:after {
    color: #e600ff;
}
.box-a {
    color: #ddd;
    width: 24%;
    align-items: center;
    cursor: pointer;
    filter: drop-shadow(5px 5px 4px rgba(255, 255, 255, 0.8));
    padding: 20px 20px 20px 20px;
    float: right;
    position: relative;
    margin-bottom: 20px;
    margin-top: 40px;
    border-radius: 15px;
}
.box-a:hover {
    transform: translateX(3px) translateY(3px);
    filter: drop-shadow(2px 2px 0 rgba(50, 50, 50, 0.3));
}
.box-a.rounded, .box-b.rounded {
    -moz-border-radius: 5px 0 5px 5px;
    border-radius: 5px 0 5px 5px;
}
.box-a.rounded:before, .box-b.rounded:before {
    border-width: 8px;
    border-color: #323232 #323232 transparent transparent;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 0 0 0 5px;
}
.box-a i,.box-a svg {
    font-size: 60px;
    background: #fff;
    padding: 25px;
    border-radius: 150px;
    margin-right: 20px;
}
.box-a h3 {
    position: relative;
    display: inline-block;
    padding: 0;
    text-align: left;
    padding-bottom: 10px;
    margin: 0;
    color: #fff;
}
.box-a p {
    color: #fff;
    float: left;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px;
}
.box-a:hover i,.box-a:hover svg{
    background: #e600ff;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
}
.land-image {
    margin-right: 20px;
}
.land-image img, .landing-right img {
    width: 60px;
}
.btn-10 {
    color: #fff;
    border: none;
    animation: jittery 4s infinite;
    font-weight: 700;
    font-size: 18px;
    transition: all 0.3s ease;
    overflow: hidden;
}
.take-tour {
    font-size: 14px;
    font-weight: 700;
    color: #fcfcfc;
    padding: 10px 35px!important;
    text-transform: capitalize;
    background: linear-gradient(96deg, #f24412, #f4a618);
    border-radius: 7px;
    -webkit-box-shadow: 0 4px 0 #af3a2a, 0 5px 5px 1px rgba(0, 0, 0, .4)!important;
    box-shadow: 0 4px 0 #f24412, 0 5px 5px 1px rgba(0, 0, 0, .4)!important;
    transition: all .2s;
}
.take-tour:active {
    transform: scaleX(.9) translateY(4px);
}
.tour-model {
    max-width: 1080px;
}
.tour-model .close {
    background: #eb0008;
    padding: 0;
    width: 25px;
    height: 25px;
    top: 5px;
    right: 5px;
    line-height: 25px;
    border-radius: 50px;
}
.verticle-middle {
    vertical-align: middle!important;
}
.orange {
    color: #ef0101;
}
.clears-notify {
    background: #ff0600;
    color: #fff;
    padding: 5px 12px;
    border-radius: 50px;
    font-size: 13px;
}
.loader-container {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.display-nonss {
    display: none!important;
}
.slick-slide img {
    width: 40px;
    height: 40px;
    margin-right: 4px;
    display: block;
}
/*mawb air customer*/
.mawb-backimg {
    background-repeat: no-repeat;
    background-position: 82% 70%;
    background-image: url(../img/mawb-air.png);
    width: 760px;
    height: 1098px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .36);
    position: relative;
    margin: 0 auto;
    padding-left: 28px;
    padding-right: 28px;
    line-height: 12px;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
}
/*/
//////////////////////////awb pdf popup////////////////////////
/*/
.get-pdf {
    max-width: 700px;
}
.get-pdf .close {
    top: 0;
    position: absolute;
    z-index: 9;
    right: 11px;
    opacity: 1;
    height: 30px;
    font-size: 33px;
    color: #ffffff;
    font-weight: 700;
}
.get-pdf label {
    font-size: 12px;
}
.get-pdf textarea, .get-pdf select {
    border-radius: 6px;
}
.get-pdf .auth-card1 .form-side {
    background: #f0f0f0;
    position: relative;
    padding: 30px 40px;
}
.get-pdf input[type="checkbox"] {
    height: auto;
    margin-right: 10px;
}
.checkboxess-multi {
    width: 50%;
}
.margin-forms {
    margin: 40px 0 0;
}
.margin-forms .mail-quot {
    background: #eef6ff;
    padding: 10px 20px 0;
    border-radius: .25rem;
}
.margin-forms .origin-forms {
    margin: 20px 0 10px;
}
.margin-forms .inputs1, .margin-forms .ipt11 {
    border-radius: 50px;
    height: 40px!important;
    font-size: 14px;
    padding: 0 20px;
}
.margin-forms .widths-20 {
    width: 19%;
    margin-right: 8px;
}
.margin-forms .css-bg1rzq-control {
    height: 40px;
    border-radius: 50px;
}
.margin-forms .btns-master {
    background: #000000!important;
    border: 1px solid #000000;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    padding: 9px 25px;
    border-radius: 50px;
}
.visi-nones {
    visibility: hidden;
}
.margin-forms .dim-list li {
    width: 15%;
}
.margin-forms .dim-list {
    padding: 0;
    margin: 0;
}
.margin-forms .widths-16 {
    width: 15%;
    margin-right: 7px;
}
.margin-forms .ipt1 {
    font-size: 13px;
    height: 40px;
    padding: 0 15px;
    border-radius: 45px;
}
.rounded-circles {
    border-radius: 50px;
}
.margin-forms .btnpadd {
    padding: 10px 20px;
    background: #173ebc!important;
    border: 1px solid #fb6c40;
    height: 45px;
    text-transform: uppercase;
    width: 100%;
    font-weight: 600;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
    outline: none!important;
    border-radius: 50px;
}
.margin-forms .width-6 {
    width: 48%;
    margin-right: 10px;
}
.width-6 {
    width: 48%;
    margin-right: 10px;
}
.house-pop div {
    width: 20%;
    margin-bottom: 10px;
}
.house-pop div h5 {
    color: #000 !important;
    font-size: 15px;
    font-weight: 700;
}
.btn-houses {
    padding: 10px 15px;
    background: #efefef;
}
.housepop-width {
    max-width: 830px;
}
.housepop-width .pop-text {
    padding: 15px 20px 0;
}
.housepop-width .close {
    background: red;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    color: #fff!important;
    padding: 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    top: 3px;
    position: absolute;
    right: 5px;
}
/*/
///////////////////////////////fixed bottom/////////////////////////
/*/
.fixed-bottoms {
    position: fixed;
    bottom: 51px;
    left: 0;
    right: 0;
    background: #fff;
}
.fixed-bottoms1 {
    position: fixed;
    z-index: 11;
    bottom:51px;
    width: 91%;
    right: 0;
    background: #fff;
}
#spot-rates {
    padding: 10px 0 1px;
}
.tick_background {
    background: #fafafa;
    border-top: 1px solid #cdd1db;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
    border-radius: 0 0 5px 5px;
}
.tick_background .more-spot {
    justify-content: center;
    border-bottom: 1px dotted #d9d9d9;
    padding: 6px 0;
}
.tick_background .text1 {
    font-size: 12px;
    color: #f12a26;
}
.tick_background .post-title {
    color: #000;
    font-weight: 700;
}
.tick-mainheading {
    color: #000000;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    padding: 7px 23px;
    /*margin-bottom: 5px;
    */
    border-bottom: 1px solid #f3f3f3;
    text-transform: uppercase;
}
.tick-mainheading span {
    color: #000000;
}
.statics-top {
    margin-top: 25px;
}
.icon {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}
.box {
    margin-bottom: 5px;
    border-radius: .375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    border: 0;
}
.boxes-widths {
    width: 16%;
    margin-right: 5px;
    margin-bottom: 10px;
    border-bottom: 2px solid #cccbcb;
}
.boxes-widths .card-body {
    padding: 10px 5px;
}
.box h5 {
    font-weight: 600;
    font-size: .8125rem;
}
.box-val {
    font-size: 20px;
    font-weight: 700!important;
}
.statics-combine {
    padding: 0 45px;
}
.heading-borderss {
    border-bottom: 2px solid;
    padding-bottom: 6px;
}
.footer-paddingbot {
    padding-bottom: 0;
    position: relative;
}
.staticss-page {
    background: #ebebeb;
}
.staticss-page .boxes-widths .card-body {
    padding: 16px 10px;
}
.charts {
    padding: 0 10px;
}
.padding-leftright {
    padding-left: 0;
    padding-right: 0;
}
.boxes-widths3 {
    margin-right: 14px;
    width: 32%;
}
.tracking-detailss .other-cardss label {
    font-weight: 700;
    font-size: 14px;
}
.search_input {
    border: 0;
    outline: 0;
    background: #fff;
    color: #000;
    padding: 0 10px;
    width: 300px;
    border-radius: 50px;
    line-height: 40px;
    position: relative;
}
.search_icon {
    height: 40px;
    width: 40px;
    position: absolute;
    background: #173ebc;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 50px;
    right: 0;
}
.search_input::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}
.search_input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
}
.search_input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}
.first-track {
    position: relative;
    /*width: 15%;
    */
}
.first-track p {
    font-size: 13px;
}
.track-boxs i,.track-boxs svg {
    font-size: 20px;
    color: #173ebc;
    padding: 10px 5px;
}
.track-boxs img {
    width: 113px;
    object-fit: contain;
}
.trackings-boxes {
    border-bottom: 1px dashed #929292;
}
.trackings-boxes h5 {
    font-size: 15px;
}
.statics-btns button {float: none!important;
    background-color: #000000!important;
    border: 1px solid #000000!important;
    border-radius: 50px!important;
}
.statics-forms .ipt1 {
    border-radius: 50px!important;
}
.charts .card {
    border-radius: 5px;
}
.apexcharts-canvas {
    min-height: 250px!important;
}
.apexcharts-canvas {
    position: relative!important;
}
.dimension-upload .custom-file-label, .dimension-upload .custom-file-label:after {
    border-radius: 50px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    padding: 0 20px;
}
.dimension-upload .custom-file-label:after {
    background-color: #000;
    border-left: 1px solid #000;
    color: #fff;
}
.dimension-upload .upload-btn {
    background: linear-gradient( 87deg, #ff3000, #ff9b03)!important;
    border: 1px solid #fb6c40;
    height: 45px;
    text-transform: uppercase;
    width: 100%;
    font-weight: 600;
    color: #fff!important;
    font-size: 13px;
    cursor: pointer;
    outline: none!important;
    border-radius: 50px;
}
.scrollbar-container {
    overflow-y: scroll!important;
    -webkit-overflow-scrolling: touch;
}
.model_head .close {
    z-index: 1;
    right: 2px;
    top: -2px;
    font-size: 25px;
    position: absolute;
    background: #ff0600;
    padding: 0;
    width: 30px;
    height: 30px;
    opacity: 1;
    color: #fff!important;
    line-height: 32px;
    border-radius: 50px;
}
.model_contact button {border-radius: 50px;
    background: #ff0600;
    border-color: #ff0600;
    cursor:pointer;
    padding: 10px 25px;
}
.AAA-uploader-sender-only-data-1 {
    padding-left: 10px;
    list-style: none;
}
.AAA-uploader-sender-only-data-1 li {
    font-weight: 500;
}
.blue-gst1 .card .card-body {
    padding: 15px 20px;
}
.filter-cross {
    background: #f1f1f1;
    width: 250px;
    margin-bottom: 10px;
    border-radius: 50px;
    font-weight: 700;
    padding: 10px 5px 10px 20px;
    font-size: 15px;
}
.cross-btns {
    background: red;
    width: 20px;
    height: 20px;
    cursor: pointer;
    line-height: 20px;
    color: #fff;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    font-weight: 700;
    float: right;
}
/*/
/////////////////////////////Edocket Popup//////////////////////////////
/*/
.edocket-width {
    max-width: 600px;
}
.docss {
    margin-right: 20px;
    margin-bottom: 15px;
    border: 1px solid #f3f3f3;
    border-bottom: 1px solid transparent;
    border-radius: 4px;
}
.download-preview, .files-preview {
    font-weight: 600;
    text-align: center;
}
.files-preview {
    background: #ffffff;
    width: 110px;
    border-radius: 4px 4px 0 0;
    min-height: 90px;
    padding: 10px 5px 4px;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    word-wrap:break-word;

}
.download-preview {
    background: #000000!important;
    color: #fff;
    cursor: pointer;
    width: 100%;
    border-color: transparent;
    padding: 2px 0;
}
.AAA-edocket-popup-data-3 input {
    border-radius: 50px!important;
    height: 40px!important;
}
.AAA-edocket-popup-data-3 .custom-file-label, .AAA-edocket-popup-data-3 .custom-file-label:after {
    border-radius: 50px!important;
}
.AAA-edocket-popup-data-3 .custom-file-label:after {
    height: 40px;
    line-height: 40px;
    background-color: #000;
    padding: 0 20px;
    border-left: 1px solid #000;
    color: #fff;
}
.ship-statics .boxes-widths {
    min-height: 110px;
}
.query-data h6 {
    font-weight: 600;
    margin-top: 6px;
    font-size: 14px;
}
.query-data h6 span {
    color: #ff0600;
}
.ship-statics .card-title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 7px;
}
.ship-statics .boxes-widths .card-body {
    padding: 10px 10px;
}
.table-sticky>thead>tr>td, .table-sticky>thead>tr>th {
    background: #fff;
    color: #fff;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}
.table-sticky>thead>tr>th {
    z-index: 1;
}
.ipt1 {
    height: 40px;
}
.dimen-width {
    max-width: 990px;
}
.big-reference {
    width: 30%!important;
    text-align:left;
}
label {
    font-size: 14px;
}
.all-detaill h6 {
    font-weight: 600;
    color: #202020;
}
/*/
/////////////////////////invoice design////////////////////
/*/
.orange-back {text-transform: uppercase;
    color: #000000;
    margin-bottom: 5px;
    font-size: 19px;
    background: transparent;
    text-align: left;
    font-weight: 600;
    border-bottom: 2px solid #000000;
}
.performa-table table {
    width: 100%;
    margin-bottom: 6px;
}
.performa-table table tr td{
    font-size: 14px;
}
.performa-table table tr th {
    font-size: 14px;
}
.shipment-back {
    padding: 8px 0 0;
}
.btn-secondary:hover {
    background-color: #000000!important;
    border-color: transparent!important;
}
.home-bbtns {
    padding-bottom: 30px;
}
.origin-forms .text-white, .edit-awb-data-19 .text-white {
    color: #202020!important;
}
.edit-awb-data-15 h4 {
    font-weight: 700;
}
.blue-gst1 input[type=checkbox] {
    height: auto!important;
}
/*/
/////////////////////footer rob/////////////////////////
/*/
footer {
    font-size: 1rem;
    color: #fff;
    background: #fff;
}
footer .footer-black {
    background-color: #ffffff;
}
footer .footer-orange {border-radius: 3px;
    background-color: #000000;
    color: #ffffff;
    line-height: 2em;
    font-weight: 100;
    /*margin-top: -30px;*/
}
footer h6,footer h3,footer h5 {
    color:#ffffff;
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
    text-transform: uppercase;
}
footer p {
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    line-height: 1.5em;
}
footer p a {
    color: #fff;
    font-weight: 600
}
footer ul {
    list-style: none;
    padding: 0;
}
footer ul.nav-2 {
    text-align: center;
}
footer ul li,footer .social_media h4 {
    margin-right:20px;
    margin-bottom: 15px;
    text-align: left;
}
.nav-2 li{margin-right:20px;}
footer ul li a {
    color: #fff;
}
.footer--follow ul li a,.social_media h4 a {
    color: #000000;
    font-weight:700;
}
.social_media h4 a{font-size: 1rem;}
footer ul li a i, .social_media h4 a i,footer ul li a svg, .social_media h4 a svg{
    font-weight:700!important;
    /*margin-right: 5px;*/
}
.nav-2 li a {
    text-decoration: underline;
}
.cont-head {
    border-bottom: 1px solid #000;
    text-align: center;
    margin: 10px auto;
    font-weight: 700;
    font-size: 23px;
}
.header-mobile {
    display: none;
}
.charts .card-body {
    min-height: 430px;
}
.powered-by {text-align: center;
    font-size: 25px;
    font-weight: 700;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: rgb(0 0 0 / 35%) 0 4px 17px;
    z-index: 11;
    letter-spacing: 1px;
}
.powered-by img {
    margin-left: 10px;
}
.powered-by p{font-size: 20px;
    font-weight: 500;
    background: #000000;
    padding: 6px 0;
    color: #fff;}
.padding-bottomss1 .powered-by {
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 0;
}
.logo-single {
    width: 360px;
    height: 60px;
    display: inline-block;
    margin-bottom: 60px;
}
.notification-bell:hover i,.notification-bell:hover svg {
    color: #fff!important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef!important;
    border: 1px solid transparent!important;
    cursor: not-allowed!important;
    opacity: 1;
}
.field-login input.form-control {
    border-radius: 30px;
    color: #58595b;
    padding: 15px 16px!important;
    height: 44px;
    font-size: 14px;
    margin-bottom: 15px;
}
.field-login .btn-green {
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding: 11px 0;
    background: #ff0600;
}
.registration h3 {
    font-size: 25px;
    font-weight: 900;
}
.about-uss {
    padding: 90px 0 70px;
    background: #dfdfdf;
}
.about-uss h2 {
    font-weight: 700;
    margin-bottom: 10px;
}
.visi-hide {
    visibility: hidden;
}
.currency-toggle .custom-control {
    position: relative!important;
    display: block!important;
    min-height: 1.5rem!important;
    padding-left: 0.5rem!important;
    background: transparent!important;
    height: auto!important;
    width: auto!important;
    border: 1px solid transparent!important;
}
.currency-toggle .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
}
.currency-toggle .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
}
.currency-toggle .custom-switch .custom-control-label::after {
    top: calc(.20rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}
.currency-toggle .custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.more-spot .slider-right {
    margin-right: 24px;
    margin-left: 24px;
    width: 46%;
    text-align: center;
}
.react-select__value-container {
    background: transparent!important;
}
.commo-text {
    font-size: 12px!important;
}
.separator {
    border-bottom: 1px solid #9d9d9d;
    margin: 10px 0;
}
.table-vessles {
    height: 150px;
    display: block;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    background: #ffffff;
}
.table-vessles .table-sticky>thead>tr>th, .table-vessles .table-sticky>thead>tr>td {
    background: #ff0600;
    color: #fff;
    padding: 7px 0;
    text-align: center;
    top: 0;
    position: sticky;
}
.table-vessles .table-sticky>thead>tr>th{font-size: 14px;font-weight: 600;border: 1px solid #fff!important;}
.table-vessles tbody tr td {
    padding: 8px 0;
    text-align: center;
    vertical-align: middle;
}
.table-vessles tbody tr td button {
    margin: auto;
}
.findpopup {
    max-width: 950px;
}
.findpopup .table thead th {
    vertical-align: middle;
    border: 1px solid transparent;
}
.findpopup .table td {
    vertical-align: middle;
    padding: 5px 3px;
    text-align: center;
}
.review-page {
    margin: 90px 0 50px;
}
.review-content {padding: 40px 50px;
    width: 100%;
    max-width: 520px;
    background: #f9f9f9;
}
.review-form {
    background: #fff;
    padding: 25px;
    margin: 25px 0 0
}
.review-content h3 {
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 24px;
    text-decoration: underline;
}
.review-content h5 {
    margin-bottom: 15px;
}
.rate-icons {
    margin-right: 6px;
    cursor: pointer;
}
.rate-icons i,.rate-icons svg {
    font-size: 32px;
}
.rate-green {
    color: #FF6700;
}
.rate-black {
    color: #202020;
}
.roboto-fonts h1 {
    font-size: 2.8em;
    font-weight: 700;
    color: #000;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.roboto-fonts p {
    text-transform: capitalize;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
}
.min-height {
    min-height: 100vh;
}
.statics-forms {
    margin: 45px 0 0
}
.widths-20 {
    width: 19%;
    margin-right: 8px;
}
.inputs11 {
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    padding: 0 12px;
}
.shipment_contents label {
    color: #fff;
    font-weight: 600;
}
.shipment_contents .css-bg1rzq-control {
    border-radius: 4px;
}
.ship-package.nav-tabs {
    border-bottom: 1px solid transparent;
    margin-bottom: 24px;
    margin-top: 15px;
}
.ship-package.nav-tabs .nav-link:hover {
    color: #fff !important;
}
.ship-package.nav-tabs .nav-link {
    background: #000000;
    color: #fff;
    font-weight: 700;
    padding: 10px 30px;
    margin-right: 5px;
}
.ship-package.nav-tabs .active:before {
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #000000;
    content: " ";
    position: absolute;
    top: 38px;
    z-index: 2;
    background: transparent!important;
    left: 50%;
}
.tick-signs i,.tick-signs svg {
    font-size: 50px;
    line-height: 100px;
    color: #fff;
}
.bordertop-none {
    border: 1px solid transparent;
}
.margin-forms label {
    font-size: 13px;
}
.payment_id {
    font-size: 14px!important;
    font-weight: 500!important;
    word-break: break-word;
    padding: 6px 10px;
}
.economy {
    position: relative
}
.economy:before {
    content: "";
    position: absolute;
    left: 2%;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    clear: both;
}
.visi-hidden {
    visibility: hidden
}
/*/
/////////////////////////////////Goggle Language//////////////
/*/
/*.goog-te-gadget-simple img {*/
/*    display: none;*/
/*}*/
/*.goog-te-gadget-simple {*/
/*    opacity: 0;*/
/*    font-size: 15px !important;*/
/*    line-height: 2px !important;*/
/*    height: 45px;*/
/*}*/
/*.skiptranslate.goog-te-gadget {*/
/*    background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.32086556892397633-1681732951457.png') no-repeat;*/
/*    background-size: contain;*/
/*    !*height: 40px;*!*/
/*    !*width: 40px;*!*/
/*    text-align: center;*/
/*}*/
/*#google_translate_element{*/
/*    position: fixed;*/
/*    z-index: 9;*/
/*    bottom: 90px;*/
/*    !*bottom: 187px;*!*/
/*    right: 21px;*/
/*    padding: 6px;*/
/*    width: 60px;*/
/*    height: 60px;*/
/*    border-radius: 50px;*/
/*    line-height: 60px;*/
/*    text-align: center;*/
/*    background: #000000;*/
/*    border: 1px solid #000000;*/
/*}*/
/*/
/////////////////////////////////Goggle Language//////////////
/*/
.nav-tabs .nav-link.active::before, .nav-tabs .nav-item.show .nav-link::before {
    background: #ff0600;
}
.post-title a{
    color:#000
}
.title.orange {color: #ff0600;
    font-weight: 600!important;
    font-size: 29px;
    padding: 10px 0 10px;
}
.upload-data button{
    background: #ff0600!important;
    border-radius: 50px;
    font-weight: 600;
    padding: 8px 29px;
    border: 1px solid #ff0600;
    font-size: 14px;
}
.upload-data .custom-file-label {
    line-height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    background: #e9ecef;
}
.upload-data .custom-file-label::after {
    border-color: #d7d7d7;
    line-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    background: #000;
    border-radius: 2px;
    color: #fff;
}
.navbar-nav .nav-link i,.navbar-nav .nav-link svg{
    color: #ff0600;
}
/*/
////////////////////////////////logo-asyad//////////////////////////////
/*/
.edocket-pdfs{
    position: relative;
    padding:10px 10px 0;
    width:576px;
    height: auto;
    background: #fff;
    border:2px solid #000;
    margin: 30px auto 30px;
}
.edocket-pdfs-2{
    position: relative;
    padding:10px 10px;
    width:800px;
    height: 445px;
    background: #fff;
    border:2px solid #000;
    margin:30px auto 0;
}
.logo-asyad img{
    width:190px
}
.shipbuttons{
    background: #000000;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    border-radius: .25rem .25rem 0 0;
    margin-right: 5px;
}
/*/
///////////////////////////////House Buttons////////////////////////
/*/
.left-arrow {
    position: fixed;
    left: 200px;
    top: 350px;
    font-size: 40px;
    color: #404040;
}
.right-arrow {
    position: fixed;
    right: 100px;
    top: 350px;
    font-size: 40px;
    color: #404040;
}
.left-arrow button, .right-arrow button {
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    outline: transparent;
}
button:disabled{
    opacity: .2;
}
/*/
//////////////////////////////////////NEW USER///////////////////////////
/*/
.diff-forms{
    width: 100%;
}
.diff-forms h2{
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
}
.diff-forms p{font-weight: 600;
    /*font-family: 'Oswald', Sans-Serif;*/
    letter-spacing: 1px;
    font-size: 29px;
    margin-bottom: 50px;
    color: #000;
}
.mins-height{
    min-height: 500px;
}
.query-btn-groups{
    margin:60px 0 20px;
}
.user-box-first {
    position: relative;
    margin: 0 1%;
    background: white;
    text-align: center;
    cursor: pointer;
    width: 180px;
    height: 180px;
    border: 1px solid #d6d6d6;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 25%);
    border-radius: 3px;
    transition: .2s all;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-box{position: relative;
    margin: 0 2%;
    background: white;
    text-align: center;
    cursor: pointer;
    width: 180px;
    height: 180px;
    /* width: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #6b6b6b;
    box-shadow: 0 2px 9px 2px rgb(18 18 18 / 42%);
    border-radius: 3px;
    transition: .2s all;
}
.user-box p, .handling-box p, .user-box-first p{
    word-break: break-all;
    margin-bottom: 0;
    font-weight: 500;
    color:#000;
    font-size: 17px;
    letter-spacing: normal;
}
.handling-box img,.user-box-first img{
    width: 80px;
    margin-bottom: 15px;
}
.user-box img{width:80px;
    margin-bottom: 15px;}
.change-color .forms-boxes .width-4{
    width: 100%;
}
.pack-btns{position: relative;
    background: #000000;
    border-radius: .4rem;
    width: 60%;
    font-weight: 600;
    font-size: 18px;
    margin: 50px auto 0;
    color: #fff;
    padding: 13px 50px;
    vertical-align: middle;
    letter-spacing: normal;
    overflow: hidden;
    text-align: center;
}
.user-box .show-economy1{
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    padding: 0 10px;
    text-align: left;
    z-index: 1;
    right:0;
}
.forms-boxes1 h4 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
    border-bottom: 1px solid #f3ecec;
    padding-bottom: 10px;
    color: #000;
}
.forms-boxes1{
    margin-top:40px;
}
.user-box.active,.handling-box.active,.user-box-first.active,.box-container_listing.active{
    box-shadow: inset 0 0 0 0.2rem #000000, 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);
}
.user-box label{
    font-weight: 700;
}
.user-box .form-check-inline .form-check-input{
    margin-top: 0.3rem;
}
.upload-data button{
    background: #ff0600!important;
    border-radius: 50px;
    font-weight: 600;
    padding: 8px 29px;
    border: 1px solid #ff0600;
    font-size: 14px;
}
.upload-data .custom-file-label {
    line-height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    background: #e9ecef;
}
.upload-data .custom-file-label::after {
    border-color: #d7d7d7;
    line-height: 40px;
    padding: 0 10px;
    font-size: 14px;
    background: #000;
    border-radius: 2px;
    color: #fff;
}
.button.disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #ff0600!important;
    border-color: #ff0600!important;
    color: white!important;
    cursor: not-allowed!important;
}
.search-btn:active.disabled{
    transform: none
}
.new-popups .close {color: #fff!important;
    z-index: 1;
    right: 7px;
    background: #ff0600;
    position: absolute;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    padding: 0;
    text-align: center;
    font-size: 30px;
}
.contact-later2 .user-box{
    width: 43%;
    height: 150px;
    padding: 0 10px;
    line-height: 30px;

}
.contact-later2 .user-box span{
    font-size: 24px;
    font-weight: 700
}
.contact-later2{
    height: 450px;
    display: flex;
    padding: 0 20px!important;
    justify-content: center;
    align-items: center;
}
.diff-forms h3{
    color: #000;
    text-align: center;
    margin-bottom: 40px;
    font-size: 23px;
    font-weight: 600;
}
.diff-forms h5{
    color: #000;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
}
.ori-destination{
    width: 100%;
}
.pick_destination_addresss{
    width: 100%;
}
.new-originbox{
    position: relative;
}
.new-originbox label {
    font-weight:700;
    position: absolute;
    top: 0.5rem;
    left: 0.625rem;
    z-index: 5;
    cursor: pointer;
    font-size: 17px;
    line-height: 0.9375rem;
}
.new-originbox .css-1szy77t-control{
    border-color: #000;
    box-shadow: 0 0 0 1px #000
}
.new-originbox .css-bg1rzq-control,.new-originbox .css-1szy77t-control {
    padding: 1.5625rem 0 0;
    font-size: 0.875rem;
    line-height: 1;
    min-height:auto;
    height:auto;
    outline: 0 !important;
}
.new-originbox .css-151xaom-placeholder{
    font-size: 12px!important;
    color: #000;
    font-weight: 500;
}
.new-originbox .css-1szy77t-control:hover{
    border-color: #000;
}
.new-originbox .css-1wy0on6{
    display: none;
}
.new-originbox .css-1hwfws3{
    height: auto;
    line-height: inherit
}
.port-widths{
    width: 24%;
}
.drop-widths{
    width: 32%;
}
.new-originbox input.inpt,.new-originbox select.inpt{
    border-radius: 4px;
    padding: 1.5625rem 8px 0;
    line-height: 1;
    outline: 0!important;
    height: 55px!important;
    font-size: 12px!important;
    border-color: #bfbfbf!important;
    color: #000;
    font-weight: 500;
}
.input.inpt:focus {
    border-color: #000!important;
    border-width: 2px;
}
.justify_between{
    justify-content: space-between;
}
.other-images img{
    width: 70px;
}
.handling-box {position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1%;
    background: white;
    text-align: center;
    cursor: pointer;
    width: 180px;
    height: 180px;
    border: 1px solid #d6d6d6;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 25%);
    border-radius: 3px;
    transition: .2s all;
    align-items: center;
    justify-content: center;
}
.dimens-widths {
    width: 21%;
    margin: 0 0 0 5px;
}
.length-widths {
    width: 8%;
    margin: 0 0 0 5px;
}
.package-widths {
    width: 19%;
    margin: 0 0 0 5px;
}
.plus-signs{padding:0!important;
    width: 28px;
    height: 28px;
    text-align: center;
    background: #ff0600;
    line-height: 24px;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 50px;
    font-size: 15px;
    margin: 0 0 15px 10px;
}
.minus-signs{
    width: 28px;
    height: 28px;
    text-align: center;
    background: #000000;
    cursor: pointer;
    line-height: 24px;
    border-color: transparent;
    color: #fff;
    border-radius: 50px;
    font-size: 15px;
    margin: 0 0 15px 10px;
}
.checkbox-widths{
    padding-left: 20px;
}
.checkbox-widths label{
    font-weight: 700;
    color:#fff;
    font-size: 16px;
}
.search-btn2{
    padding: 35px 30px;
    background: #fff;
    border-radius: 5px;
    margin: 40px 20px;
}
.search-btn2 .form-check-label{
    font-size: 18px;
    font-weight: 600;
}
.search-textwidth{
    width: 100%;
    text-align: left;
}
.user-box.active .triangle-topleft, .handling-box.active .triangle-topleft, .courier-box.active .triangle-topleft{
    display: block
}
.triangle-topleft{
    position: absolute;
    display: none;
    top: 11px;
    left: 17px;
    font-size: 24px;
    color: #000;
}
.build-width{
    width:60px!important;
}
input.inpt:focus {
    border-color: #000;
    border-width: 2px;
}
.new-originbox .react-tagsinput-input {
    width: 100%;
    height: 42px;
    border-radius: 4px;
    padding: 0 8px;
    border-color: transparent;
    font-size: 14px;
}
.new-originbox .react-tagsinput{
    border-radius: 4px;
}
.margin-rite{
    margin-right:20px;
    width: 48%;
    margin-left:20px;
}
.upload-newquery button{
    background: #000;
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
    padding: 0 40px;
    height: 53px;
    font-size: 17px;
    letter-spacing: 1px;
}
.margin-rite .custom-file-label::after {
    height: 53px;
    background: #000;
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    padding: 0.75rem 2rem 0.5rem 2rem;
}
.margin-rite .custom-file-label {
    height: 55px;
    line-height: 30px;
    border-radius: 4px;
}
.container-types {
    margin: 0 15px;
}
.container-types .handling-box{
    width: 100%;
    margin-bottom: 10px;
}
.dashboard-tracking{background: #fff;
    padding:60px 0 60px;
}
.tracking-shipment{
    margin-top:55px;
    margin-bottom: 60px;
}
.table-background{
    background: #fff;
    padding: 20px 20px;
    border-radius: 4px;
}
.table-data h4{
    margin-bottom: 20px;
    color:#000;
    font-weight: 600;
}
.table-data table th{
    font-size: 15px;
    border: 1px solid transparent;
    border-bottom: 1px solid #dee2e6;
    text-transform: uppercase;
}
.green-status {
    background: green;
    color: #fff;
    padding: 4px 14px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 600;
}
/*/
///////////////////////////////////Tracking Single Page//////////////////////////////
/*/
.vertical-timeline {
    width: 100%;
    position: relative;
    padding: 0.5rem 0 0.5rem;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 90px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
    border-radius: .25rem
}
.vertical-timeline-element {
    position: relative;
    margin: 0 0 1rem;
    min-height: 60px;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 .8s
}
.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 83px
}
.vertical-timeline-element-icon .badge-dot-xl {
    box-shadow: 0 0 0 5px #fff
}
.badge-dot-xl {
    width: 18px;
    height: 18px;
    position: relative
}
.badge:empty {
    display: none
}
.badge-dot-xl::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: .25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: #fff
}
.vertical-timeline-element-content {
    position: relative;
    margin-left: 108px;
    font-size: .8rem;
    padding-left: 10px;
}
.vertical-timeline-element-content .timeline-title {
    font-size: .8rem;
    text-transform: uppercase;
    margin: 0 0 .5rem;
    padding: 2px 0 0;
    font-weight: bold
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: -100px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: #000000;
    font-size: 15px;
    white-space: nowrap;
    font-weight: 600;
}
.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both
}
.tracking-more{
    font-size: 13px;
    color:blue;
    font-weight: 600;
    margin-bottom: 3px;
}
.tracking-more a{
    color:#0082de
}
.table-data h3 {
    margin-bottom: 20px;
    color: #000;
    font-weight: 600;
    font-size: 15px;
}
.table-data p{
    font-size: 13px;
}
.details-track{
    width: 45%;
}
.tracking-more a i,.tracking-more a svg{
    font-size: 20px;
    font-weight: 600;
}
.track-imgs{
    background: url('../img/map-img.png') no-repeat right;
    position: relative;
}
.ori-desti{
    position: absolute;
    left: 390px;
    top: 114px;
}
.pick-off{
    left: 0;
    background: #fd9504;
    padding: 4px 17px;
    position: absolute;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.drop-off{
    right: 0;
    background: #fd9504;
    padding: 4px 17px;
    position: absolute;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.mb-40{
    margin-bottom: 15px;
}

.destination-history h4{
    font-weight: 700;
    font-size: 20px;
}
.modal .modal-header {
    border-bottom: 1px solid #ffffff00;
}
.query-btns {
    font-size: 15px;
    font-weight: 700;
    color: #fcfcfc;
    padding: 7px 25px!important;
    background: #000000;
    margin-right: 10px;
    text-transform: capitalize;
    border-radius: 7px;
    box-shadow: 0 4px 0 #464646, 0 5px 5px 1px rgb(0 0 0 / 40%) !important;
    -webkit-box-shadow: 0 4px 0 #464646, 0 5px 5px 1px rgba(0, 0, 0, .4) !important;
    transition: all .2s
}
.query-btns:active {
    transform: scaleX(.9) translateY(4px)
}
/*/
/////////////////////////////// 04 Augest 2021////////////////////////////////
/*/
.dim-separate div{
    font-size: 14px;
    padding-right: 10px;
}
.housepop-width {
    max-width: 850px;
}
.housepop-width .pop-text {
    padding: 15px 20px 0;
}
.domestic-box p{
    font-weight: 600;
    margin: 0;
    font-size: 17px;
}
.triangle-topleft{
    display: block
}
/*/
///////////////////////////////////////////Rates Half Card/////////////////////////////////////
/*/
.half-rates{padding: 0;
    margin-bottom: 15px;
    border: 2px solid #000;
    width: 100%;
}
.half-rates .origin-text label,.half-rates .origin-destination-details label {font-size: 12px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 2px;
}
.half-rates .origin-text{
    margin-bottom: 15px;
}
.ratecard_data {
    width: 65%;
}
.ratecard_data .origin-text{
    width: 25%;
}

.ratecard_button .card-price{
    margin-bottom: 7px;
    margin-right:0;
    font-size: 18px;
    font-weight: 600;
    color: #ff0600;
}
.newrate_bottom{
    background: #c9c9c947;
}
.half-rates .card-typess label {
    font-weight: 500;
    font-size: 12px;
    margin:0;
    color: #000000;

}
.half-rates .card-typess{
    padding: 3px 5px;
}
.shipment_history {
    box-shadow: 0 1px 15px rgb(0 0 0 / 58%), 0 1px 6px rgb(0 0 0 / 4%);
    border-radius: 5px;
    margin: 6px 0 0;
    overflow: hidden;
    background: #fff;
    min-height: 254px;
}
.ship-heading {border-bottom: 1px solid #ababab;
    padding: 5px 10px 8px;
}
.ship-heading h3{font-size: 22px;}
.details-other{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.new-tracking{
    background: #fff;
}
.new-tracking .track {
    position: relative;
    background-color: #ddd;
    height: 5px;
    display: flex;
    margin-bottom: 65px;
    margin-top: 30px;
}
.new-tracking .track .step {
    flex-grow: 1;
    width: 25%;
    margin-top: -12px;
    text-align: center;
    position: relative;
}
.new-tracking .track .step.active:before {
    background: #00990a
}
.new-tracking .track .step::before {
    height: 5px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 12px
}
.new-tracking .track .step.active .icon {
    background: #00990a;
    color: #fff
}
.new-tracking .track .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: relative;
    border-radius: 100%;
    background: #ddd
}
.new-tracking .track .step.active .text {
    font-weight: 700;
    color: #ff0600;
}
.new-tracking .track .text {
    font-size: 14px;
    cursor:pointer;
    font-weight: 600;
    display: block;
    margin-top: 7px;
    color: #000;
}
.white-rates{
    border-radius: 6px;
}
.tracking-card{
    width: 36%;
}
.map-tracking{overflow: hidden;
    border-radius: 6px;
    box-shadow: -2px 4px 11px 2px rgb(0 0 0 / 63%);
}
.maps-rates{
    margin:20px 0 50px;
    height: 350px;
    border-radius: 5px;
    overflow: hidden;
}
.maps-rates div div{
    width:100%!important;
}
.stretched-align{
    align-items: stretch
}
.new-ships .css-1pcexqc-container{
    padding:0
}
.shipper_con_pop{display: flex;justify-content: center;align-items: center;}
.ships-model .close{display: none;
    position: absolute;
    opacity: 1;
    background: #ff0600;
    z-index: 1;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 0;
    right: 0;
    color: #fff!important;
}
.ships-model .new_courier_input{margin-bottom: 15px;}
.btn-add-dimen {
    min-height: 38px;
    background: #ff0600;
    border: 1px solid #ff0600;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    padding: 9px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.other-label{font-size: 17px;
    margin: 0;
    padding:2px 0!important;
}
.dangerous_pop input,.dangerous_pop select{border-radius: 4px;font-size: 15px!important;font-weight: 500;height:45px!important;border: 1px solid #a7a7a7;}
.dashboard-heights{
    height: auto;
    overflow-y: inherit;
    padding: 15px 10px 0;
}
.half-rates .all-price{
    text-align: left;
    width: 100%;
    padding: 6px 0;
}
.half-rates .terms-width{
    padding: 0;
    border-top: 1px solid #c7c7c7;
}
.half-rates .bottomCard {
    border: 1px solid #b1afaf;
    padding:0
}
.half-rates .origin-destination {
    font-size: 11px;
    color: #000000!important;
    font-weight: 400;
}
.list-fare ul{text-align: left;
    list-style: auto;
    padding-left: 20px;
    text-transform: capitalize;
}
.map_back{background: #ffffff;
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: -2px 4px 11px 2px rgb(0 0 0 / 28%);
    padding:10px;}


.border-rights{border-right: 1px solid #cacaca}
.border-bottom {
    border-bottom: 1px solid #9d9d9d !important;
}
.scroll-freight{
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;}
.final-status {
    max-width: 850px;
}
.final-status .pop-text {
    padding: 15px 20px 0;
}
.final-status .modal-header{border-bottom: 1px solid #e9ecef;}

.AAA-view-pdf-awb-data-5{margin: 20px 0 15px;}

.justify-content-evenly{justify-content: space-evenly;}

.fixed-btnsbot {position: fixed;
    bottom: 33px;
    left: 0;
    width: 100%;
    padding-bottom: 7px;
    padding-right: 90px;
    padding-top: 7px;
    background: #fbfbfb;
    border-top: 1px solid #ddd;
    z-index: 10;
}
.fixed-btnsbot_first {position: fixed;
    bottom: 5px;
    left: 0;
    width: 100%;
    padding-bottom: 7px;
    padding-right: 90px;
    padding-top: 7px;
    background: #fbfbfb;
    border-top: 1px solid #ddd;
}
.margin-scroll{padding: 30px 0 0;}
.margin_firstscroll{padding: 40px 0 0;}

/*///////////////////////////////////////////tracking Search/////////////////////////////*/

.domain-search {margin: 0;
    min-height: 80vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.bg-pblue {
    background-color: #f9f9f9;
}
.domain-search .form-title {margin-top: 0;
    margin-bottom: 2px;
    color: #000;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.domain-search p {color: #000;
    font-size: 24px;
    margin: 0;
}
.domain-search .input-group {
    -webkit-box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 18px 0 rgb(0 0 0 / 44%);
}
.domain-search .input-group-addon {
    border: none
}
.domain-search .form-title strong {
    display: block
}
.domain-search .form-control {
    height: 70px;
    font-size: 20px;
    border-radius: 0;
    border: none
}
.domain-search .form-control:focus {
    box-shadow: 0 0 0 0 #ffffff !important
}
.bg-pblue .input-group .input-group-addon [type="submit"],
.bg-pblue .input-group .input-group-addon button {
    border: none;
    border-radius: 0
}
.domain-search .btn {
    padding: 0 54px;
    line-height: 70px
}
.bg-pblue .btn-primary {padding: 0 40px;
    font-size: 26px;
    line-height: 70px;
    letter-spacing: 1px;
    font-weight: 500;
    background: #ff0600;
    text-transform: uppercase;
    color: #fff;
}
.bg-pblue .btn-primary:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000
}
.domain-search .input-group+p {
    color: #fff;
    font-size: 15px;
    font-weight: 100;
    margin-top: 15px;
    margin-bottom: 0
}
.domain-search .input-group+p strong {
    margin-right: 40px
}
.track-head{font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #000;
    text-transform: uppercase;}
.tracking-search{padding: 25px 30px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fff;
    border-radius: 8px;}
.tracking_details{
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background: #fff;
    border-radius: 8px;}
.dd_track{padding: 15px 20px;align-items: stretch}
.heading-track{font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;}
.tracking-left{color:#505050;
    font-size: 16px;
}
.tracking-text{font-size: 20px;}
.tracking-No{margin-right:50px}
.tracking-No img{margin-right:14px;}
.tracking_details .heading-track{
    /*color: #ff0600;*/
    /*font-weight: 700;*/
}
.tracking_details .view-btn{background: #ff0600;
    cursor: pointer;
    color: #fff;
    align-items: center;
    display: flex;
    width:20%;
    justify-content: center;
    /* padding: 0 36px; */
    font-size: 20px;}
.align-stretched{align-items: stretch}
#top{background: #f9f9f9;padding:10px;}
#top table th {padding:10px;
    font-size: 17px;
    border-color: transparent;
    font-weight: 600;
}
#top table td {
    font-size: 14px;
    font-weight: 500;
    padding: 14px 10px;
}
.logo-pdfs{width: 280px;}
.not-track{display: none;}
/*////////////////////////////////////////////other pages////////////////////////*/
.Other-Page{background: #f6f6f6;
    padding: 80px 0 100px;}

.Other-Page h4{font-size: 38px;
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase;}
.box-home{transition: all .25s ease-in-out;
    cursor: pointer;
    position: relative;
    background-size:cover;
    font-size: 16px;
    background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/air_freight.jpg');
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20px 15px;
    display: flex;
    border: 1px solid #e2e2e2;
    width: 23%;
    margin-right: 20px;
    margin-bottom: 35px;
    align-items: center;
    justify-content: center;
    height: 450px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
}
.box-home:hover {
    transform: translateY(-5px);
}
.box-home img {margin-bottom: 5px;
    border-radius: 3px;
    width: 60px;
    overflow: hidden;
}
.box-home p{font-weight: 500;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 1px;
    color: #fff;
    font-size: 40px!important;}
.box-home:before{content:'';
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    background:#ff06006b;
}
/*.ocean-freights{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/ocean_freight.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
/*.road-freights{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/road_freight.jpg');*/
/*    background-size:cover;*/
/*    background-position: right;}*/
/*.courier-freights{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/courier_freight.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
/*.warehousing-freights{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/warehousing.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
/*.packaging{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/packaging.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
/*.customs{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/custom_brokrage.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
/*.other_services{background-image: url('https://d2ra2wln3jgzfj.cloudfront.net/zipaworld/other_services.jpg');*/
/*    background-size:cover;*/
/*    background-position: center;}*/
.overlay img{width: 350px;object-fit: cover;margin-bottom: 20px;}
.faq-ques {box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
    background: #fff;
    padding: 20px 15px;
    width: 100%;
    border: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}
.faq-ques .card-header{padding: 0;
    font-size: 19px;
    position: relative;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}
.faq-ques .card-header:after {flex-shrink: 0;
    content: "\f078";
    width: 1.25rem;
    font-family: "FontAwesome";
    height: 1.25rem;
    position: absolute;
    right: 0;
    margin-left: auto;
    transition: transform .2s ease-in-out;
}
.faq-content{width: 90%;margin: auto}
.faq-content p{font-size: 16px;
    margin-bottom: 20px;}
.faq-ques i,.faq-ques svg{font-weight: 800;
    cursor:pointer;
    font-size: 25px;}
.faq-ques h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.faq_description{margin-bottom:15px;}
.circle_faq i,.circle_faq svg{color: #ff0600;
    font-size: 19px;
    margin-right: 10px;}
.content_faq .card-body{font-size: 17px;line-height: 30px;}
.truck_Popup {
    max-width:800px;
}
.truck_Popup h2{font-size: 25px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-weight: 600;
    border-bottom: 1px solid #ededed;}
.truck-img {border: 1px solid #b9b9b9;
    cursor: pointer;
    width: 23%;
    padding: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}
.truck-img.active{border: 2px solid #000000}
.truck-img img{width:100%;}
.truck_text{background: #000;
    padding: 8px;
    text-align: center;
    color: #fff;
    font-size: 13px;}
.truck_Popup .modal-header{padding:0;}
.ticker-all {width: 100%;
    border-radius: 6px;
    position: relative;
    background: #fff;
    display: flex;
    border: 2px solid #000;
    flex-wrap: wrap;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
    padding: 7px 10px 4px 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
}
.ticker-alls {
    width: 23%;
    margin-right: 15px;
}
.ticker_section h4{font-size: 13px;margin-bottom: 5px;text-align: left;font-weight: 700;}
.origin_ticker, .destination_ticker{position: relative;}
.ticker_section:before {content: "";
    border-left: 2px dotted #343434;
    height: 46%;
    position: absolute;
    top: 0;
    bottom: 8px;
    left: 11px;
    margin: auto 0;
}
.origin_ticker:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff0600;
    position: absolute;
    left: -18px;
    top: 2px;
}
.destination_ticker:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ff0600;
    position: absolute;
    left: -18px;
    top: 8px;
}
.rate_ticker {
    justify-content: center;
    border-left: 1px solid #969696;
    padding-left: 7px;
    display: flex;
    background: #f9f9f9;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
}
.rate_ticker h4{margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600;}
.rate_ticker h5{color: red;
    font-size: 12px;
    font-weight: 600;}
.space-ticker{padding: 3px 2px;
    display: flex ;
    flex-wrap: wrap;
    align-items: center;
}
.spaces-ticker{margin:0 10px 0 0}
.ticker_btn{text-align: center;
    position: absolute;
    bottom: -31px;
    right: 26px;}
.ticker_btn button{background: white;
    border-color: transparent;
    color: #000;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    font-weight: 600;
    font-size: 13px;}

.origin_ticker span {
    display: block;
    font-size: 12px;
    font-weight: 500;
}
.destination_ticker span {display: block;
    font-size: 12px;
    font-weight: 500;
}
.width-40{width:40%;}
.select-table.active{background: #000;
    color: #fff;
    font-weight: 700;
    font-size: 17px;}
.responsive-padding{padding:0 25px 0 80px;}
.slab-width{max-width: 900px;}
.slab-width-ocean{max-width: 1000px;}
.booking-slab{font-size: 19px;
    padding: 10px 0 30px;}
.booking-slab h4 {
    font-size: 21px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
}
.side-column{font-size: 19px;
    color: #fff;}
.pre-btns{cursor: pointer;
    position: absolute;
    right: 15px;
    top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;}
.pre-btns .delete{box-shadow: 0 5px 5px 1px rgba(0,0,0,.4)!important;
    border-bottom: 2px solid #fff;
    font-size: 13px;
    padding: 8px 22px;}
.pre-btns .delete a{color:#ffffff;}
/*////////////////////////////////////sarch_reload//////////////////////////*/

.search_reload{position: absolute;
    top: -5px;
    left: 17px;}

.search_reload i,.search_reload svg{width: 32px;
    cursor: pointer;
    height: 32px;
    background: #ff0600;
    line-height: 32px;
    border-radius: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;}

.search-spotresult{position: absolute;
    top: -194px;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 45%);
    width: 210px;
    text-align: left;
    background: #fff;
    padding: 7px 11px 20px;
    border-radius: 3px;
}

.search-spotresult .css-151xaom-placeholder {
    font-size: 11px !important;
    color: #000!important;
    font-weight: 500;
    letter-spacing: 0;
}
.search-spotresult .economy:before {content: "";
    position: absolute;
    left: 1%;
    bottom: -38px;
    top: inherit;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    border-bottom: 9px solid transparent;
    clear: both;
}
.search-spotresult .origin-label span{letter-spacing: normal!important;}
.search-spotresult .css-kj6f9i-menu, .search-spotresult .css-dvua67-singleValue{font-size: 11px!important;}
.search-spotresult .css-1g6gooi input{font-size: 13px;}
/*/////////////////////////////////////////////////Previous History/////////////////////////////////////*/
.previous_rate .origin_destination{width:85%}
.previous_rate .origin-text{width:20%;padding-right:10px;}
.privacy-title h2{font-size: 26px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 40px;
}
.coupon_box{display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    cursor: pointer;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
    border: 1px solid #fff;}
.coupon_text{padding: 15px 10px;width: 100%;text-align: center}
.coupon_text h3{font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 17px;
    color: #000;}
.coupon_img{width: 100%;}
.coupon_img img{width: 100%;
    /*height: 180px;*/
    padding: 4px;
}
.coupon_code{font-size: 16px;
    font-weight: 500;
    margin: 3px 0 10px;}
.coupon_code span{display: block;
    font-weight: 600;
    color: #fe1301;
    font-size: 23px;}
.coupon_codes span {
    display: block;
    font-weight: 600;
    color: #fe1301;
    font-size: 23px;
}
.apply_btn{margin-top:10px;}
.apply_now{text-transform: uppercase;
    font-size: 13px;
    text-align: center;
    background: #ff0600;
    color: #fff;
    font-weight: 600;
    border: 1px solid #ff0600;
    padding: 5px 15px;}
.rates_heads .modal-body{padding: 20px 20px 50px;}
.rates_heads h5{font-size: 25px;
    color:#ff0600;
    font-weight: 600;}
.rates_head{font-size: 23px;}
.rates-not .btn{padding: 7px 30px;
    font-size: 15px;}
.mob_invoice{text-align: left}
.invoice_big_button{text-align: right;}
.invoice_big_data{margin-bottom: 0;
    width: 49%;}
.invoice_big_data p{margin-bottom: 2px;font-size: 14px;}
.shipper_big_data{width: 100%;}
.shipper_big_data p{width:49%;}
.width-100{
    width:100%!important;
}
.header_invoice{display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between}
.header_invoice .mob_invoice{width: 35%;}
.header_invoice .invoice_text{text-align: left;width:65%}
.radius-3{border-radius: 3px;box-shadow: 0 4px 15px 0 rgb(0 0 0 / 24%);}
.invoice_big_data p span {
    float: right;
}
.no-desktop{display: none;}
.orderDetail th{border-bottom:1px solid #ddd!important;
    border-top:1px solid #ddd!important;
    background: #ebebeb;}
.tan_cin p {
    margin-right: 10px;
    width: 40%;
}
.padding-invoice{padding:20px 30px}
.half-parts{width: 49%;padding: 5px 0;}
.padding_edocket{padding:8px 0}
.border-shadows{box-shadow: 1px 5px 15px 0 rgb(0 0 0 / 21%);border-radius: 4px;}
.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
}
.btn-secondary{background-color: #000000;
    border-color: #000000;}
.back_gray {
    border-radius: 5px;
    padding: 15px 20px;
}
/*/////////////////////////////////ChatBot/////////////////////////////////*/
.popup-box {background-color: #ffffff;
    border: 1px solid #e4e4e4;
    bottom: 0;
    height: 490px;
    position: fixed;
    right: 0;
    border-radius: 5px;
    width: 380px;
    overflow: hidden;

}
.popup-box .popup-head {
    background-color: #ff0600;
    clear: both;
    color: #7b7b7b;
    display: inline-table;
    font-size: 21px;
    padding: 7px 10px;
    width: 100%;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
}

.chat_img{margin-right: 12px;}
.chat_img img{width:40px;height: 40px;border-radius: 50px;line-height: 40px;}
.popup-head-left h5{font-weight: 600;
    color: #fff;
    font-size: 20px;}


.popup_chat{background: #f0f0f0 none repeat scroll 0 0;
    height: 388px;
    padding: 10px 5px;
    overflow: auto;}


.bot_chat img,.user_chat img{border-radius: 50px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background: #fff;}

.bot_chat .msg, .user_chat .msg{background-color: #fff;
    font-size: 14px;
    padding: 5px;
    display: table;
    border-radius: 5px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px
}


.popup-messages-footer{padding:5px 10px}

#status_message{font-size: 12px;
    font-weight: 400;
    outline: inherit;
    /*margin: 0 0 10px;*/
    width: 80%;
    border: none;}
.send_chat_message{width: 35px;
    height: 35px;
    background: #ff0600;
    line-height: 35px;
    color: #fff;
    text-align: center;
    border-radius: 50px;}
.user_chat .msg {
    background-color: #000;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
}

/*/////////////////////////////////ChatBot/////////////////////////////////*/
.overlay {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    background: rgb(227 227 227 / 26%);
    width: 100%;
    height: 100%;
}
.another_forms1 {
    background: #000000c9;
    padding: 45px 25px;
    border-radius: 5px;
    z-index: 9;
    position: relative;
}
.freight_Table tr th{background: #000;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border: 1px solid #000;
    padding: 10px 10px;
    font-weight: 600;}
.freight_Table tr td{text-align: center;
    background: #fff;
    font-size: 13px;
    padding: 7px 10px;
    vertical-align: middle;
    border: 1px solid #e9e7e7;}

.manual_popup{max-width:800px;}
.manual_popup .close{top: 3px;
    position: absolute;
    z-index: 9;
    right: 5px;
    opacity: 1;
    height: 26px;
    color: #ffffff!important;
    background: #ff0600;
    outline: 0!important;
    font-weight: 700;
    padding: 0;
    font-size: 26px;
    width: 26px;
    border-radius: 50px;
}

.freight_Table input{background: transparent;
    border-color: transparent;text-align: center;
    outline: none;}

.freight_margin{margin-bottom: 60px;}
.two_part{display: flex;flex-wrap: wrap;justify-content: space-between;}
.two_part .first_parts, .two_part .second_parts{width:49%}
.width_full{width: auto!important;}
.ori-destination .css-bg1rzq-control, .ori-destination .form-control{border-color: #7b7b7b!important;}
.foot_logos{margin:40px 0;}
.services_my{margin:80px 0 0;
    height:600px;
    background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8834253763036668-1681905721468.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.tradeFinance{
    background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.46794485996558466-1671197592685.jpg') no-repeat center;
    background-size: cover;
}
.services_my.tradeFinance:before{background:transparent}
.News_Section{margin:80px 0 0;
    height:150px;
    background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.49953076764288173-1635323966547.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;}
.career_Section{background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9014262620197184-1635317506855.jpg') no-repeat center;
    background-size: cover;}
.team_section{background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6723876070664978-1635322078667.jpg') no-repeat center;
    background-size: cover;}
.News_Section h2{z-index: 1;
    position: relative;
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
}
.News_Section:before{content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #ff06006b;
}
.services_content_ocean{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.9092237370819753-1681906642578.jpg') no-repeat center;
    background-size: cover;}
.services_content_road{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.4452637280828695-1681906691651.jpg') no-repeat center;
    background-size: cover;}
.services_content_courier{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.30567548773045106-1681906735753.jpg') no-repeat center;
    background-size: cover;}
.services_content_brokerage{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.01787404778219792-1681906820614.jpg') no-repeat center;
    background-size: cover;}
.services_content_warehousing{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.17001439082831338-1681906777760.jpg') no-repeat center;
    background-size: cover;}
.services_content_other{background: url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.731997128303711-1635322946235.jpg') no-repeat top;
    background-size: cover;}
.services_content_packaging{background: url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.30693743229946224-1681906871997.jpg') no-repeat top;position: relative;
    background-size: cover;}
.services_content_packaging:before{display: none;}
.services_my:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(to bottom, #ff070059 0%, rgb(255 7 0 / 24%) 17%, rgb(255 6 0 / 32%) 31%, rgb(255 6 0 / 13%) 38%, rgb(255 6 0 / 15%) 45%, rgba(0,16,58,0.3) 67%, rgba(0,16,58,0.1) 85%, transparent 100%);
}
.services_content{margin:60px 0 0;}
.services_content h1{font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 35px;}
.services_content h1 span{color:#ff0600;}
.services_content p {font-size: 18px;
    line-height: 30px;
    text-align: justify;
}
.services_content h5{font-size: 23px;
    color: #ff0600;
    margin-bottom: 7px;}
.other_image_services{margin-top:60px}
.left_service_Air{position: relative;z-index: 1;}
.left_service_Air img{width: 100%;box-shadow: -3px 0 51.84px 2.16px rgb(0 0 0 / 42%);}
.left_service_Air:before{content: "";
    position: absolute;
    bottom: -15px;
    right: -13px;
    width: 145px;
    height: 129px;
    z-index: -1;
    background-color: #ff0600;
}
.left_service_Air:after{content: "";
    position: absolute;
    top: -15px;
    left: -13px;
    width: 145px;
    height: 129px;
    z-index: -1;
    background-color: #ff0600;
}

.other_second_services{margin-top:100px;}
.product_dropdown{padding: 0;background:#000!important;width: 14rem;}
.product_dropdown .dropdown-item{color: #fff!important;white-space:inherit;font-size: 15px;font-weight: 600!important;
    padding: 10px 20px;}
.product_dropdown .dropdown-item:hover, .product_dropdown .dropdown-item:focus{background-color: #ff0600}
.latestNewsItem {box-shadow: 0 5px 15px 0 #535353;
    position: relative;
    background: #fff;
    margin: 0 0 25px;
}
.news_details {text-align: left;
    position: relative;
    padding: 10px 20px 0 10px;
    background: #f8f8f8;
    z-index: 3;
    height: 130px;
}
.news_details h2 {font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: unset;
    margin-bottom: 10px;
}
.news_details h2:hover{color:#000;}
.news_images img{width: 100%;
    height: 200px;
    background: #f1f1f1;}
.my_list_date{font-size: 14px;
    font-weight: 600;
    color: #ff0600;
}
.learnButton{background: #ff0600;
    color: #fff!important;
    position: absolute;
    bottom: 8px;
    cursor: pointer;
    padding: 6px 10px;
    font-weight: 500;
    font-size: 13px;
    border-radius: 2px;}
.news_heading {box-shadow: -1px 2px 5px 2px rgb(0 0 0 / 36%);}
.news_heading h3{font-weight: 600;
    font-size: 20px;
    padding: 8px 10px;
    background: #ff0600;
    color: #fff
}
.right_news_section{height: 540px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;}

.testimonial_reviews .center .slick-center .review_contents{padding: 40px 40px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: #FFFBF2;
    box-shadow: 0 0 56.05px 2.95px rgb(43 45 55 / 26%);
    border: 1px solid #f3e8e8;
}
.Other-Page p{font-size: 20px;
    text-align: justify;
    line-height: 30px;}
.review_des{position: relative;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    min-height:165px;
}
.quote-left,.quote-right{font-size: 46px;
    position: absolute;
    line-height: 1;
    color: #ff0600;}
.quote-left{left: -25px;top:0}

.review_name{font-weight: 600;
    font-size: 18px!important;
    margin-bottom: 2px!important;}
.review_work {
    font-weight: 600;
    font-size: 15px!important;
    color: #000;
}
.review_contents{padding: 40px 30px 40px 50px;
    background: #ffffff;
    margin: 0 20px;
    min-height: 370px;
    border: 1px solid #f7f7f7;
    box-shadow: rgb(0 0 0 / 42%) 0 2px 8px 0;
}
.testimonial_reviews .slick-list {
    margin-top: -30px;
    margin-bottom: -30px;
    padding: 30px 0 !important;
}

.demo_tour_vedio{margin:-116px 0 40px;}
.demo_tour{position: relative;
    padding: 60px 0 80px;
    height: 260px;
    background-size: cover;}
.about_action{height:auto;text-align: left;}
.about_padding{padding:30px 0 20px;background: #fbfbfb;}
.about_action h4{text-align: left!important}
.demo_tour:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.demo_tour h4{color: #000;
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;}
.demo_tour p{color: #000;
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    text-align: center;}
.box-video {
    margin-top: 0;
}
.box-video .box {
    position: relative;
    border: 20px solid #ededed;
    padding: 110px 0 350px 0;
    background: #fff url('https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.5274542053310882-1692880339450.png') no-repeat center center;
    background-size: cover;
    box-shadow: 0 24px 60px 0 rgb(0 0 0 / 5%);
}
.box-video .box .overlay {
    background: #cccccc;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}
.box-video .box .flat-video-fancybox {position: absolute;
    z-index: 10;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.box-video .box .flat-video-fancybox a.fancybox {width: 90px;
    height: 90px;
    background: radial-gradient( rgb(255 6 0) 60%, rgba(255, 255, 255, 1) 62%);
    border-radius: 50%;
    position: relative;
    display: block;
    margin: 100px auto;
    box-shadow: 0 0 25px 3px rgb(255 6 0);
}
.flat-video-fancybox i,.flat-video-fancybox svg{font-size: 30px;
    color: #fff;}
.box-video .box .flat-video-fancybox .box-content {
    background: #ffa800;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 49px;
    padding: 12px 0;
}
.box-content{position: absolute;
    bottom: 0;
    background: #ff0600;
    padding: 20px 40px;}
.fancybox::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    z-index: 100;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.fancybox:before{content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgb(255 0 0);
    top: -24%;
    left: -26%;
    background: rgb(255 21 0);}
@-webkit-keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0 0 25px 3px rgba(255, 255, 255, 0.75), 0 0 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;

    }
}

@keyframes pulsate1 {
    0% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 1;
        box-shadow: inset 0 0 25px 3px rgba(255, 255, 255, 0.75), 0 0 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1);
        opacity: 0;
        box-shadow: none;

    }
}

.hire_page{padding:80px 0 60px;}
.hire_page h2,.trade_page h2{font-weight: 700;
    margin-bottom: 30px;
    font-size: 30px;}
.job_box{padding: 12px;
    border-radius: 4px;
    box-shadow: rgb(33 33 33 / 8%) 0 4px 8px 0;
    border: 1px solid rgb(234, 234, 234);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.job_box i,.job_box svg{color: #ff0600}
.job_title{font-size: 20px;
    font-weight: 700;}
.time_job{text-transform: uppercase;
    color: #ff0600;
    font-weight: 700;
    font-size: 14px;
}
.first_col_job{width: 35%;}
.ex_job{width: 15%;
    font-size: 15px;
    font-weight: 600;}
.view_job{background: #ff0600;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;}
.apply_job{background:#000;}
.ex_job span{display: block;
    font-size: 12px;
    color: #838383;}
.form_jobs{padding: 0 20px;}
input.input100,select.input100 {width: 100%;
    height: 50px;
    font-weight: 700;
    padding: 0 20px 0 50px;
    border-radius: 4px;
    border: 1px solid #000;
    outline: none;
}
.input-checkbox100{font-weight: 700;}
.label-job {display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    font-size: 18px;
    color: #ff0600;
}
textarea.input100{width: 100%;padding: 15px 20px 0 50px;font-weight: 700;border-radius: 4px;
    border: 1px solid #000;
    outline: none;}
.resume_btn{background: #000000;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    padding: 7px 30px;}
.Faq_page{padding: 50px 0;background: #fff;}
.courier_two_img{position: relative;min-height: 550px;}
.courier_two_img img {
    -webkit-box-shadow: -3px 0 51.84px 2.16px rgb(0 0 0 / 42%);
    box-shadow: -3px 0 51.84px 2.16px rgb(0 0 0 / 42%);
}
.courier_two_img img:nth-child(1) {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}
.courier_two_img img:nth-child(2) {
    position: absolute;
    z-index: 10;
    top: 180px;
    left: 250px;
}
.team_img{margin-right: 30px;}
.team_img img{width: 100%;
    height: 320px;
    object-fit: cover;}
.our_team .slick-prev, .our_team .slick-next{display: none!important;}
.our_team{margin:60px 0;}
.our_team .slick-dots li button:before{background: #dbdbdb;opacity:1;color:transparent;width:10px;height:10px;}
.slick-dots li.slick-active button:before{background: #000;color: transparent!important;}
.four_mode .slick-dots li.slick-active button:before{background: #fff;color: transparent!important;}
.four_mode .slick-dots li button:before{opacity: 1;}
.our_team .slick-dots li{width:10px;height:10px;}
.team_img{margin-bottom: 50px;}
.team_img h3 {font-size: 20px;
    letter-spacing: .8px;
    color: #000;
    font-weight: 500;
    display: block;
    margin: 0 0 3px;
    text-transform: uppercase;
    transition: all .4s ease-out;
}
.team_name {
    position: relative;
    text-align: center;
    padding: 30px 15px 0;
}
.team_img h5{font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: 600;
    color: #ff0600;
    position: relative;
    display: inline-block;}
.team_section_img{position: relative;}
.team_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .9s ease;
    -moz-transition: all ease .9s;
    -webkit-transition: all .9s ease;
    -o-transition: all ease .9s;
}
.team_overlay:after {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgb(255 6 0 / 46%);
    overflow: hidden;
    width: 0;
    height: 100%;
    content: "";
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    -moz-transition: all ease .4s;
}
.team_section_img:hover .team_overlay:after {
    width: 100%;
}
.team_social{display: flex;flex-wrap: wrap;align-items: center;}
.team_section_img .team_social {
    position: absolute;
    left: 20px;
    top: 49px;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    transition: all .9s ease;
    -moz-transition: all ease .9s;
    -webkit-transition: all .9s ease;
    -o-transition: all ease .9s;
}
.team_section_img:hover .team_social {
    visibility: visible;
    opacity: 1;
    left: 50px;
}
.team_social div{margin-right: 10px;}
.team_social div a {
    width: 30px;
    height: 30px;
    display: inline-block;
    box-shadow: 0 3px 14px #666c752e;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    transition: all 0.3s ease-in;
    background: #3B5998;
    font-size: 17px;
    margin-right: 0;
}
.twitter {
    background: #55ACEE!important;
}
.linkedin {
    background: #2663AC!important;
}
.gallery_my{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.img_gallery{width: 18%;margin-bottom: 10px}
.img_gallery img {border-radius: 10px;
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.media_img{padding: 100px 0 80px;
    text-align: center;
    background-image: -webkit-linear-gradient(left, #ff150f 0%, #9b0300 100%);
    min-height: 700px;}
.media_img h4, .our_team h4 {text-align: center;
    font-size: 30px;
    letter-spacing: .8px;
    color: #fff;
    font-weight: 500;
    display: block;
    margin: 0 0 50px;
    text-transform: uppercase;
    transition: all .4s ease-out;
}
.testi_sections{background: #fbfbfb}
.testi_sections h5{font-size: 34px;
    color: #000;
    font-weight: 500;
    text-align: left;
    margin-bottom: 25px;
    text-transform: uppercase;}
.Other-Page h6{
    color: #ff0600;
    font-weight:600;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 21px;}
.our_team h4{color:#000;}
.media_my{display: flex;flex-wrap: wrap;align-items: center;justify-content: center}
.media_my .team_img{width: 23%;margin-right: 13px;}
.media_my .team_img img{height: 230px;border:10px solid #fff;}
.gallery_btn{background: #000;
    color: #fff;
    font-size: 20px;
    padding: 10px 80px;
    font-weight: 500;
    margin: 60px 0 0;
    text-align: center;}
.navbar--hidden {
    position: relative;
}

.width_forms_new{box-shadow: 0 2px 9px 2px rgb(18 18 18 / 42%);
    width: 40%;
    margin:20px auto 0;
    padding: 45px 45px;}
.width_forms_new input{border-radius: 4px;
    width: 100%;
    padding: 0 15px;
    font-size: 13px;
    border: 2px solid #000;}
.btn_new_form{width: 100%;
    background: #000;
    color: #fff;
    font-size: 20px;
    margin-bottom: 40px;
    font-weight: 600;}
.width_forms_new label{font-weight: 600;}
.third-sec,.second-sec,.ori-destination{position: relative}
.prev_my_Button{font-size: 24px;
    position: absolute;
    left: 20%;
    top: 40%;
    text-align: center;
    background: black;
    width: 35px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    border-radius: 50px;
    padding: 0;}
.next_my_Button{font-size: 24px;
    position: absolute;
    right: 20%;
    text-align: center;
    top: 40%;
    background: black;
    width: 35px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    border-radius: 50px;
    padding: 0;}
.second-sec .prev_my_Button{left:10%;}
.second-sec .next_my_Button{right:10%;}
.ori-destination .prev_my_Button, .ori-destination .next_my_Button{bottom:10%;top:inherit;}
.ori-destination .next_my_Button{right: 46%;}
.ori-destination .prev_my_Button{left: 46%;}
.origin_dropdown{position: absolute;
    text-align: left;
    top: 200px;
    left: 0;
    border-radius: 4px;
    right: -230px;
    background: #fff;
    border: 1px solid #000;
    padding: 25px 20px 10px;
    box-shadow: inset 0 0 0 0.2rem #000000, 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);
}
.origin_dropdown-right{right: 0;left: -230px;}
.padding_port{position: relative}
.padding_port:before{content: "";
    position: absolute;
    left: 2%;
    top: -40px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #000;
    clear: both;}
.pre-btns1 {display: none;
    cursor: pointer;
    position: fixed;
    right: 15px;
    top: 14%}
.new_other_popup .soon_text h6 {
    text-align: center;
    font-size: 22px;
    color: #000;
    font-weight: 700;
    padding: 0 40px;
    line-height: 30px;
}
.new_other_popup .soon_text {
    position: relative;
    height: 350px;}

.new_other_popup .soon_text h5 {
    font-size: 18px;
    color: #000;
    margin: 0 0 20px 0;
    font-weight: 600;
}
.padding_port_right:before{content: "";
    position: absolute;
    right: 2%;
    top: -40px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #000;
    clear: both;
}
.all_banners,.four_mode{
    min-height:750px;
    margin:96px 0 0;
    position: relative;
    border-top: 1px solid #eaeaea;
}
.all_banners1 img{width: 100%;height:100%;}
.ocean_imgs{position: absolute;
    display: none;
    right: 10%;
    top: 12%;}
.height_fix{height:100%;padding-left:50px;width: 100%;padding-right:50px;}
.height_fix h2{font-size:36px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;}
.height_fix p {margin-bottom: 60px;
    font-size: 23px;
    color:#fff;
    font-weight:500;
    line-height: 30px;
}
.height_fix h6{font-size: 30px;
    line-height: 32px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 60px;}
.banner_btns{font-size: 23px;
    cursor:pointer;
    background: #ff0a00;
    border: 1px solid #ff0a00;
    padding: 6px 30px;
    font-weight: 700;
    color: #fff;
    border-radius: 4px;}
.four_mode .slick-prev, .four_mode .slick-next{display: none!important;}
.four_mode .slick-dots{bottom: 30px;}
.otp-margin{margin:45px 0 0}
.pop-login-screen{max-width: 750px}
.mid_otp_line {
    width: 1px;
    background: #000;
}
.mid_otp_line h3{background: #fff;
    position: absolute;
    top: 45%;
    left: -10px;
    font-weight: bold;}
.login_screen,.login_otp_screen{width: 48%;padding: 0 15px;}
.login_btn{border-color: transparent;
    background: #000!important;
    color: #fff;
    margin-bottom: 8px;
    border-radius: 50px;margin-right: 7px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 8px 25px;}
.width_Code{width: 20%;}
.width_number{width: 78%;}
.my-google-button-class{width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #CD4231!important;
    color: #fff!important;
    text-align: center;
    border-radius: 50px!important;
    font-size: 19px!important;}
.my-facebook-button-class{width: 40px;
    cursor: pointer;
    height: 40px;
    box-shadow: rgb(0 0 0 / 24%) 0 2px 2px 0, rgb(0 0 0 / 24%) 0 0 1px 0;
    border:1px solid transparent;
    background-color: #3C5B9B!important;
    color: #fff!important;
    text-align: center;
    border-radius: 50px!important;
    font-size: 19px!important;}
.horizontal_lines{width: 100%;
    height: 1px;
    margin: 25px 0 25px;
}
.horizontal_lines h4 {overflow: hidden;
    text-align: center;
    color: #000;
    z-index: 1;
    position: relative;
    font-weight: 800;
    padding: 0 10px;
}
.horizontal_lines h4:after, .horizontal_lines h4:before {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.horizontal_lines h4:before {
    right: 0.5em;
    margin-left: -50%;
}
.horizontal_lines h4:after {
    left: 0.5em;
    margin-right: -50%;
}
.min_height_about{min-height: 80vh;align-items: center}
.truck_weight,.truck_size{font-size: 14px;}
.truck_size span, .truck_weight span{font-size: 16px;}
.vehicle_imgs{width: 100%;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;}
.vehicle_text{display: none;padding:0 5px;}
.vehicle_imgs:hover .vehicle_text{display: block;transition: 2s}
.vehicle_imgs:hover img{display: none;}
.vehicle_min_height{height: 450px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;}
.change-color_popup{background:#fff}
.popup_margins{padding: 100px 0 20px;}
.pop_text{font-size: 15px;
    font-weight: 600;}
.diff-forms h1{font-size: 34px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 600;
    text-transform: uppercase;}
.popup_css_font h3{
    color: #ff0000;
    text-transform: uppercase;
    font-size: 28px;}
.popup_css_font{width: 100%;}
.popup_css_font .new_pop_img{text-align: center;margin-bottom: 10px;}
.text-justify{text-align: justify!important;}
.road-freights p{text-align: center!important;}
.quote_status{padding: 6px 20px;
    border-radius: 50px;
    background: #ff0600;
    color: #fff;
    font-weight: 500;
    font-size: 13px;}

.booking_quote_status{display: none;}
.tracking_card_shipment{background: #000;padding: 15px;border-radius: 10px;overflow: hidden}
.AAA-invoice-page-data-4 .tracking_card_shipment{padding: 10px;}
.tracking_card_shipment1{padding: 15px;overflow: hidden}
.status_history .track {position: relative;
    background-color: #474747;
    height: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
    width: 90%;
    margin: 36px auto 49px auto;
}
.status_history .track .step {text-align: center;
    margin-top: -18px;
    position: relative;
}

.status_history .track .step.active:before {
    background: #FF5722;
}
.status_history .track .icon {
    color: #fff;
    font-weight: bold;
    cursor:pointer;
    font-size: 25px;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ff0600;
}
.status_history .track .step.active .icon {
    background: #ee5435;
    color: #fff;
}
.status_history .track .step.active .text {
    font-weight: 400;
    color: #000;
}
.status_history .track .text {
    display: block;
    margin-top: 7px;
}
.soon_text1{justify-content: inherit;
    position: relative;
    height: auto;
    min-height: 280px;
    text-align: center;
    display: block;}
.soon_text1 h2 {
    text-align: center;
    color: #ff0600;
    padding: 5px 0 12px 0;
    font-size: 35px;
    font-weight: 600;
    font-style:normal;
    font-family: "Cretype caros", sans-serif!important;
}
.book-text h4{color: #000;
    font-weight: 600;}
.book-imgs1{margin-bottom: 15px;}
.book-imgs1 img{width: auto;}
.bottom_btn1{margin: 30px 0 0;}
.upload-inputs1 .btn-secondary {min-height: 45px;
    background: #ff0600;
    border: 1px solid #ff0600;
    margin-bottom: 0;
    border-radius: 5px;
    padding: 0 30px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}
.upload-inputs1 .custom-file-label{
    height: 45px!important;
    border-radius: 4px!important;
    background: #e5e5e5!important;
    color: #000!important;
    font-weight: 700!important;
}
.upload-inputs1 .custom-file-label::after {
    background: black;
    color: #ffffff;
    border-color: #000000;
    border-radius: 4px;
    height: 44px;
}
.red_text1 {
    color: #ff0600;
    font-size: 14px;
    font-weight: 600;
}
.red_text1 sup{
    font-size: 16px;
}
.input100::-webkit-input-placeholder {
    color: #000;
}
.margin_forms{padding-top:100px}
.popup_dangerous{max-width: 450px;}
.pop-text h2{font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: #ff0600;}
.popup_dangerous .react-tagsinput-input{border: 1px solid #000;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 4px;
    height: 41px;
    padding-left: 14px;}
.react-tagsinput--focused{border-radius: 4px;border:2px solid #000!important}
.popup_dangerous input::placeholder,.popup_dangerous select::placeholder {
    color: #000!important;
}
.dangerous_pop label{font-weight: 600;
    font-size: 15px;}
.dangerous_pop .custom-file-label{height: 42px;
    padding: 0 10px;
    line-height: 42px;
    border-radius: 4px;}

.dangerous_pop .custom-file-label::after {
    background: black;
    color: #ffffff;
    border-color: #d7d7d7;
    height: 41px!important;
    line-height: 41px;
    padding: 0 18px!important;
}
.dangerous_pop .delete{padding: 10px 30px;
    line-height: 20px;
    font-size: 14px!important;
    text-align: center;}

.dangerous_pop .radio input[type=radio]{margin: 0 10px 0 0;width: 15px;height: auto!important;}
.dangerous_pop .radio label{font-size: 16px;}
.popup_dangerous .pop-text h2{margin-bottom: 30px;margin-top: 15px;}
.popup_dangerous{top: 50% !important;
    transform: translate(0, -50%) !important;
    -ms-transform: translate(0, -50%) !important;
    -webkit-transform: translate(0, -50%) !important;}

.status_history .track .icon.active{background: #000;}
.job_title{text-transform: capitalize;}
.category_btns{margin-bottom: 50px;}
.btn_image_category{background: #fff;
    text-transform: uppercase;
    cursor:pointer;
    padding: 7px 18px;
    border-radius: 50px;
    color: #000;
    margin-right:10px;
    font-weight: 600;
    font-size: 14px;}
.btn_image_category.active{background: #000;color:#fff;}
.popup_activity_type_door_door {
    max-width: 740px;
}
.popup_activity_type {
    max-width: 500px;
}
.pick_drop_details{padding: 20px 10px 0}
.width_pick_drop{width:48%;}
.width_pick_full{width:100%;}
.width_pick_drop .ipt1,.width_pick_full .ipt1 {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #9b9b9b;
}
.width_pick_drop .css-bg1rzq-control, .width_pick_full .css-bg1rzq-control{border-color:#9b9b9b}
.drop_pick_btn{margin:10px 0;text-align: center;}
.btn_drop {
    border-radius: 5px;
    padding: 8px 35px;
    background: #ff0600;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    font-size: 18px;
}
.new_lable_design label{position: relative;left: auto;
    right: auto;
    bottom: auto;
    z-index: 0;
    top: auto;}

.new_lable_design input.inpt,.new_lable_design select.inpt{
    border-radius: 4px;
    padding: 0 8px 0;
    line-height: 1;
    outline: 0!important;
    height: 45px!important;
    font-size: 14px!important;
    border-color: #bfbfbf!important;
    color: #000;
    font-weight: 500;
}
.new_lable_design .css-bg1rzq-control, .new_lable_design .css-1szy77t-control {
    padding: 8px 0;}
.ag-snow{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;}
@-webkit-keyframes an-snow {
    100% {
        background-position: 500px 1000px, 300px 300px, -400px 400px;
    }
}
@-moz-keyframes an-snow {
    100% {
        background-position: 500px 1000px, 300px 300px, -400px 400px;
    }
}
@-o-keyframes an-snow {
    100% {
        background-position: 500px 1000px, 300px 300px, -400px 400px;
    }
}
@keyframes an-snow {
    100% {
        background-position: 500px 1000px, 300px 300px, -400px 400px;
    }
}
.banner_ocean_text{width:55%;}
.ocean_imgs1{width:45%;}
.credit_options{padding: 2px 8px;
    background: #f5f5f6;
    border-color: #e1e1e1;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;}
.credit_days{margin-right:5px;}
.credit_days .form-check label{font-weight: 600;font-size: 12px;}
.tarrif_type_box {text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px 0 0;
    margin-bottom: 20px;
    margin-right: 10px;
    width: 31%;
}
.tarrif_type_box.active{border:3px solid #000;}
.tarrif_type_box img {width:80px;
    margin-bottom: 10px;
    padding: 0 10px;
}
.tarrif_type_box p {font-size: 17px;
    font-weight: 700;
    padding: 3px 2px 10px;
    color: #000;
    word-break: break-word;
}
.popup_tarrif {
    max-width: 530px;
}
.activity_type_tarrif {
    max-width: 630px;
}
.activity_type_new.active {
    border: 3px solid #000;
}
.activity_type_new {width: 48%;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    margin-bottom: 20px;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #d5d5d5;
    transition: all 0.1s;
    box-shadow: 0 6px 16px 1px rgb(125 125 125 / 61%);
}
.activity_type_new img{width:75%}
.activity_type_new p{color: #000;text-align: center;
    font-weight: 600;
    font-size: 16px;}
.activity_type_new .dangerous_pop{padding:0 20px;}
.container_popup_mode{max-width: 630px;}
.origin_destination_popup {
    max-width: 530px;
}
.origin_destination_popup .handling-box {padding:0 3px;
    border: 1px solid #8d8d8d;
    margin:0 20px;
    box-shadow: 0 2px 9px 2px rgb(18 18 18 / 42%);
}
.origin_destination_popup .handling-box p{font-family: "Cretype caros", sans-serif;line-height: 22px;}
.origin_destination_popup .padding_port_destination:before {
    content: "";
    position: absolute;
    right: 2%;
    top: -40px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #000;
    clear: both;
}
.origin_destination_popup .handling-box.active{box-shadow: inset 0 0 0 0.2rem #000000, 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);}
.dangerous_pop .css-151xaom-placeholder {
    color: #000;
    font-weight: 600;
    font-size: 16px!important;}
.dangerous_pop .css-1hwfws3, .css-bg1rzq-control{line-height: 45px;height:45px;padding: 0 10px;}
.dangerous_pop .new_lable_design .css-bg1rzq-control, .dangerous_pop .new_lable_design .css-1szy77t-control {
    padding: 3px 0;
}
.pop_small{max-width: 400px;}
.coupon_new_pop{max-width: 650px;}
.css-dvua67-singleValue{font-size: 14px;
    font-weight: 600;
    color: #000;}
.width_dim_20{width:15%;margin-right: 8px;}
.dimen_radius_width input,.dimen_radius_width Input,.dimen_radius_width select,.dimen_radius_width .css-bg1rzq-control{border-radius: 5px;
    height: 40px!important;
    line-height: 40px!important;
    padding: 0 10px;}
.width_shipper_consignee{width: 31%;
    margin: 0 3px;}
.dimen_radius_width .css-1hwfws3, .dimen_radius_width .css-bg1rzq-control {
    line-height: 40px;
    height: 40px;}
.new_shipper_popup{max-width: 700px;}
.new_shipper_popup label{font-size: 13px;
    font-weight: 600;}
.new_shipper_popup input,.new_shipper_popup input, .new_shipper_popup Input, .new_shipper_popup select, .new_shipper_popup .css-bg1rzq-control{
    border-radius: 4px;
    height: 38px!important;
    line-height: 38px!important;
    padding: 0 10px;
    font-size: 12px;
    background: #f7f7f7;
    border: 1px solid #adadad;}
.consignee_btn button{font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .5px;}
.coupon_apply_pop {
    background: #3a8700;
    padding: 1px 4px;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    cursor: pointer;
}
.cross_apply_coupan {
    background: #ff0600;
    color: #fff;
    width: 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 2px;
    cursor: pointer;
}
.flights-types .triangle-topleft{
    color: green ;
    font-size: 19px  ;
    right: -92px  ;
    top: 6px;
}
.display_blocks{display: block;}
.pop_coupons {
    margin : 4px 2px 0;
}
.coupon_btns{background: #000;
    border-radius: 3px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    padding: 6px 20px;
    margin: 0 10px;}
.auth-card .srv-validation-message{font-size: 13px;font-weight: 600;text-align: left;margin-bottom: 20px;}
.pop-login-screen .auth-card{
    background-size: cover;
    background: url(https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.41343387478023197-1643013065828.png) no-repeat center;
}
.pop-login-screen .auth-card.authCardOther{
    background-size: cover;
    background: url(https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.41343387478023197-1643013065828.png) top;


}
.pop-login-screen .auth-card:before {
    content: '';
    background: #ffffff96;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.pop-login-screen .has-float-label label, .pop-login-screen .has-float-label > span:last-of-type,.otp_section label{position: relative;
    top: inherit;
    left: inherit;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: #000!important;}
.pop-login-screen .has-float-label label::after, .has-float-label > span::after{background: transparent!important;}
.pop-login-screen .has-float-label{text-align: left;}
.pop-login-screen .form-control{border: 1px solid #313131;
    border-radius: 4px;}
.pop-login-screen .close{font-size: 35px;}
.pop-login-screen .form-control:disabled, .pop-login-screen .form-control[readonly] {
    background-color: #ffffff!important;
    border: 1px solid black!important;
    cursor: not-allowed!important;
    opacity: 1;
    font-size: 14px;
    font-weight: 700;
}
.pop-login-screen .horizontal_lines {
    width: 100%;
    height: 1px;
    margin: 12px 0 35px;
}

.btn-agreement{background: #000;
    padding:6px 10px;
    border-radius: 50px;
    border-color: #000;
    cursor:pointer;
    font-weight: 600;
    color: #fff;
}
.blog_page{padding:78px 0 60px}
.blog_heading{width: 100%;
    height: 2px;
    margin: 25px 0 40px;
    position: relative;
    background: #000;}
.blog_heading h4{font-size: 28px;
    background: #fff;
    padding-right: 10px;
    position: absolute;
    top: -16px;
    left: 0;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;}
.all_blog{display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 700px;overflow-y: scroll;}
.blog_img{width: 32%;
    position: relative;
    margin-right: 5px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
    height: 350px;}
.blog_div{height:100%;}
.blog_img img{width: 100%;
    object-fit: cover;
    height: 100%;}
.blog_description {color: #fff;
    padding: 20px 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 28%;
    width: 100%;
    background: #161616ad linear-gradient(180deg ,rgb(44 45 49 / 0) 0,#000 100%) 0 0 no-repeat;}
.blog_description a,.blog_description .blog_tit{color: #fff;
    font-weight: 500;
    font-size: 18px;
}
.blog_description_big a, .blog_description_big .blog_img_text{color: #fff;
    font-weight: 500;
    font-size: 32px;}

.all_blog_india{display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: normal;
}
.blog_img_india{width:32%;background: #fff;
    margin-bottom: 15px;
    margin-right: 15px;
    box-shadow: 0 5px 15px 0 #b7b7b7;
    padding: 10px 8px;}
.blog_img_india img,.blog_img_other img,.description_img img{width: 100%;}
.blog_img_india img{height: 250px;}
.blog_description_india{padding: 10px 0 0;}
.blog_description_india a{font-weight: 600;
    color: #000;
    font-size: 19px;}
.blog_img_other{padding: 10px;
    margin: 10px 5px;
    background: #000000;
    min-height: 320px;
    border: 1px solid #fff;
    border-radius: 6px;}
.blog_img_other img{border-radius: 3px;margin-bottom: 16px;height: 200px;}
.blog_description_other a{font-size: 17px;color:#fff;font-weight:500;}
.all_blog_other .slick-prev{top: -11px;
    right: 12%;border-radius: 0!important;
    left: inherit!important;}
.all_blog_other .slick-next{top: -11px;
    right: 9%!important;border-radius: 0!important;
    left: inherit!important;}
.blog_description_heading h4,.blogHeadingFront{font-size: 30px;
    text-transform: uppercase;
    background: #fff;
    padding-right: 10px;
    font-weight: 600;
}
.description_img{margin-bottom:20px;}
.description_text p {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 10px;
}
.description_text ul li{
    font-size: 14px;
}
.description_text h3{
    font-weight: 700;
    font-size:20px;
    text-align: justify;
    margin-top:20px;
    margin-bottom: 10px;
}
.description_blog h5{font-size: 22px;margin-top: 25px;}
.description_blog ul {
    margin-top: 8px;
    margin-bottom: 8px;
    list-style: disc;
    padding-left: 45px;
}
.description_blog ul li,.description_blog ol li{
    margin-bottom: 6px;}

.blog_right_heading h4{font-size: 18px;font-weight: 500;margin:20px 0;
    overflow: hidden;
    color: #000;
    z-index: 1;
    position: relative;
    padding: 0 10px;
    width: 100%;}
.small_newss{display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;}
.small_newss img{width: 45%;border-radius: 4px;margin-right: 10px;cursor:pointer;height: 120px;object-fit: cover;}
.small_description{width:51%}
.small_description h3 {
    line-height: 24px;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 10px;
}
.blog_right_heading h4:before {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 100%;
    left: 21%;
    margin-right: -100%;
}
.blog_img_big{position: relative;width: 100%;
    display: block;}
.blog_img_big img{height: 490px;
    width: 100%;
    background: #f1f1f1;
    object-fit: contain;}
.blog_description_big {
    color: #fff;
    padding: 13px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 10%;
    width: 100%;
    background: transparent linear-gradient(184deg,rgb(0 0 0 / 66%) 0,#000000c2 100%) 0 0 no-repeat;
}
.big_blog .slick-prev{left: 0!important;}
.big_blog .slick-next{right: 0!important;}
.big_blog .slick-prev,.big_blog .slick-next{border-radius: 0!important;width: 40px!important;
    border: 1px solid #000!important;
    height: 40px!important;
    background: #000!important;}
.big_blog .slick-prev:before,.big_blog .slick-next:before{font-size: 30px!important;}
.slider_front_scroll{height: 490px;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;}
/* custom scrollbar */
.short_news::-webkit-scrollbar {
    width: 15px!important;
}
.short_news::-webkit-scrollbar-track {
    background-color: transparent!important;
}
.short_news::-webkit-scrollbar-thumb {
    background-color: #d6dee1!important;
    border-radius: 20px!important;
    border: 4px solid transparent!important;
    background-clip: content-box;
}
.short_news::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf!important;
}
.description_scroll_small {
    height: 600px;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.red_section button{border-bottom: 2px solid #000;
    padding:0;
    border-radius: 0;
    background: transparent;
    font-size: 20px;
    font-weight: 600;}
.coupon_code_btn{padding: 5px 15px;
    text-transform: uppercase;
    font-size: 13px;
    margin-right: 10px;
    font-weight: 600;
    border: 1px solid #000;
    background: #fff;}
.terms_link{font-weight: 500;font-size: 13px;}
.patch_terms_condition{position: absolute;
    top: 16px;
    text-align: center;
    right: 8px;
    min-height: 118px;
    background: #ffffffe0;
    padding: 10px 20px;}
.offer_valids{font-size: 15px;color:#000;margin-bottom: 10px;}
.patch_coupon{font-size: 20px;
    color: #fff;
    font-weight: 600;
    background: red;
    border-radius: 4px;
    padding: 0 10px;}
.offer_discount span {
    font-size: 44px;
    color: #ff0600;
    font-weight: 700;
    display: block;
}
.offer_discount {
    font-size: 38px;
    font-weight: 700;
}
.coupon_imgs img {
    width: 100%;
    height: 250px;
    padding: 0;
    object-fit: cover;
}
.coupon_box1{margin-bottom: 20px;}
.copied_green{color: green;
    font-weight: 700;
    font-size: 14px;}
.view_offers{text-align: center;
    padding: 10px 0;
    text-decoration: underline;
}
.view_offers a{color: #000000;
    font-size: 17px;
    font-weight: 600;}
.offer_mail_pop{padding:0 30px;}
.padding_stuffing{padding:0 15px;}
.page_doc{padding: 40px 40px; }
.doc_listing h5{font-size:25px; }
.doc-selectheading {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
}
.docss {
    margin-right: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}
.files-preview i,.files-preview svg {
    font-size: 25px;
    color: green;
    margin-top: 8px;
    display: block;
}
.doc_manager .edocket-100{width: 32%;
    margin-right: 15px;}
.doc_manager .width-80{width: 48%;
    margin-right: 10px;}
.doc_manager input {
    border-radius: 4px;
    height: 42px!important;
    font-size: 13px;
    padding: 0 20px;
    border: 1px solid #000;
}
.doc_manager .custom-file-label,.doc_manager .custom-file-label::after {
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 20px;
    border: 1px solid #000;
}
.doc_manager .custom-file-label::after{
    background-color: #000000;
    border-left: 1px solid #000000;
    color: #fff;
    height: 38px;
    line-height: 38px;
}
.doc_manager label {
    color: #000000;
    font-size: 15px;
    text-transform: capitalize;
}
.email-mullti{margin: 3px 0 0;}
.email-mullti span{background: #efefef;
    color: #000;
    padding: 2px 5px;
    margin-right: 3px;
    margin-bottom: 2px;
    border-radius: 20px;
    font-size: 11px;
    font-weight: 600;}
.email-plus span{position: absolute;
    top: 3px;
    right: 4px;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: #173ebc;
    text-align: center;
    border-radius: 18px;
    font-size: 20px;
}
.email-rel span{background: #173ebc;
    color: #fff;
    padding: 4px 5px;
    margin-right: 3px;
    border-radius: 20px;
    font-size: 11px;
    margin-bottom: 2px;
    font-weight: 600;}
.allmail_show {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #dedede;
    border-radius: 3px;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}
.allmail_show ul{background: #f1f4f6;
    margin: 0;
    padding: 0;}
.allmail_show ul li{border-bottom: 1px solid #e9e9e9;
    padding: 8px 0 8px 10px;
    border-radius: 2px;
    cursor: pointer;}

.packaging_commercial{padding: 8px 0 20px;
    width: 100%;}
.packaging_commercial label{font-weight:600;}
.packaging_commercial .width-6 {
    width: 70%;
    margin-right: 10px;
}
.packaging_commercial .upload-data{width: 80%;margin-bottom: 20px;}
.packaging_commercial .upload-data button{border-radius: 4px;}

.small_description h6 {
    font-size: 13px;
    font-weight: 500;
    color: #000;
}
.small_description h6 i,.small_description h6 svg{margin-right: 3px;
    margin-left: 3px;}
a:hover, a:active{color:#ff0600}

.description_my img{
    max-width: 100%;
    /*background: #ddd;*/
    border-radius: 5px;
    box-shadow: -1px 0px 6px 0px #00000030;}
.comment_submit h4{font-weight: 600;font-size: 30px;margin-bottom: 30px;}
.comment_submit input{height: 45px;
    width: 100%;
    border-radius: 4px;
    padding: 0 15px;
    border: 1px solid #a5a5a5;}
.comment_submit textarea{width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #a5a5a5;
}
.comment_submit button{background: #ff0500;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    padding: 7px 35px;}
.name_comment{margin-bottom: 15px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 19px;}
.show_comment{margin-bottom: 30px;}
.msg_comment{padding:20px 20px;border:1px solid #ddd;border-radius: 4px;}
.msg_comment p{font-size: 16px;}
.replay_icon{font-size: 18px;
    font-weight: 600;
    color: #0000bb;
    text-decoration: underline;}

.sub_comment{background: #f9f9f9;
    border-radius: 4px;
    padding: 20px;
    margin-left: 30px;
    margin-bottom: 10px;}
.like_dislike{display: flex;flex-wrap: wrap;align-items: center;}
.like_dislike i,.like_dislike svg{font-size: 35px;cursor: pointer}
.comment_like{padding-right: 30px;}
.email-mullti span i,.email-mullti span svg{color: #ff0600;
    margin-left: 5px;}
.parent{
    width: 80%;
}
.box-holi{
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    animation-name: falling;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-color:#fff!important;
}
.box-1{
    background-color: brown;
    left: 2vw;
    animation-duration: 6s;}
@keyframes falling {
    0% {
        top: -10vh
    }
    100% {
        top: 80vh
    }
}
.box-2 {
    background-color: #46027b;
    left: 5vw;
    animation-duration: 5s;
}
.box-3 {
    background-color: #fec702;
    right: 30vw;
    animation-duration: 4s;
}
.box-4 {
    background-color: #00a0e6;
    right: 40vw;
    animation-duration: 7s;
}
.box-5 {
    background-color: #da1c5c;
    right: 50vw;
    animation-duration: 9s;
}
.box-6 {
    background-color: #71c400;
    right: 60vw;
    animation-duration: 8s;
}
.box-7 {
    background-color: #fd690e;
    right: 70vw;
    animation-duration: 10s;
}
.box-8 {
    background-color: #239117;
    right: 73vw;
    animation-duration: 15s;
}
.box-9 {
    background-color: #fd690e;
    right: 75vw;
    animation-duration: 16s;
}
.box-10 {
    background-color: #c70053;
    right: 5vw;
    animation-duration: 20s;
}

.box-11 {
    background-color: #fec702;
    right: 35vw;
    animation-duration: 7s;
}
.box-12 {
    background-color: #00a0e6;
    right: 40vw;
    animation-duration: 7s;
}
.box-13 {
    background-color: #da1c5c;
    right: 26vw;
    animation-duration: 9s;
}
.box-14 {
    background-color: #71c400;
    right: 20vw;
    animation-duration: 8s;
}
.box-15 {
    background-color: #fd690e;
    right: 18vw;
    animation-duration: 10s;
}
.box-16 {
    background-color: #239117;
    right: 14vw;
    animation-duration: 15s;
}
.box-17 {
    background-color: #fd690e;
    right: 10vw;
    animation-duration: 16s;
}
.box-18 {
    background-color: #c70053;
    right: 5vw;
    animation-duration: 20s;
}

.box-19 {
    background-color: #fd690e;
    right: 40vw;
    animation-duration: 12s;
}
.box-20 {
    background-color: #239117;
    right: 35vw;
    animation-duration: 15s;
}
.box-21 {
    background-color: #fd690e;
    right: 30vw;
    animation-duration: 16s;
}
.box-22 {
    background-color: #c70053;
    right: 25vw;
    animation-duration: 19s;
}
.slick-dots li button:before{width:15px!important;height:15px!important;}
.four_mode .slick-dots li button:before{color: #fff;}
.sub_reply{background: #f1f1f1;
    padding: 10px;
    border-radius: 3px;
    margin-left: 30px;
    margin-bottom: 5px}
.mail-group{
    background: #eceff1;
    color: #000;
    display:-webkit-inline-flex;
    padding: 3px 8px;
    margin: 3px 0;
    border-radius: 20px;
}
.kyc_email{font-weight: 500!important;font-size: 11px !important;display: flex;
    align-items: center;}
.cross_email_kyc{color: #ffffff;
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    background: red;
    display: inline-block;
    width: 18px;
    line-height: 18px;
    height: 18px;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
}
.mawb_popup{max-width: 600px;}
.pop-text-mawb {
    padding: 55px 30px 30px;
    text-align: center;
}
.pop-text-mawb h6{font-size: 35px;}
.mawb_popup .modal-footer{border-top: 2px solid #ff0600;}
.mawb_popup_btn{padding: 9px 30px;
    border-radius: 4px;
    margin-right:5px;
    font-size: 17px;
    color:#fff;
    font-weight: 600;
    background: #ff0600!important;}
.margin_top_mawb{margin-top:55px;}
.dangerous_pop .other-images p{word-break: break-word;}
.flex-wraps{flex-wrap: wrap}
.country-dropdown .mr-2{margin-right:0!important;}
.country-dropdown .css-bg1rzq-control{border-color:#000!important;}
.otp_heading{font-weight: bold;
    font-size: 25px;
    text-align: left;}
.airCargo_modal{max-width: 600px;}
.myModalNew .airCargo_modal{
    /*max-width: 1000px;*/
    max-width: 700px;
}
.vedio-screen{display: flex;justify-content: center;align-items: center;position: relative;min-height: 100vh;background: #000;}
.vedio-screen .box-video .box1{position: relative;}
.vedio-screen .box-video .box1:before{
    content: '';
    position: absolute;
    top:0;
    right:0;
    left: 0;
    background: #fff url('https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.33335231573168755-1652441557287.png') no-repeat 50%;
    background-size: cover;
    height:100%;
}
div.laptop-wrapper {
    position: relative;
    width: 90%;
    margin:auto;
}
.vedio-screen:before {
    content: "";
    background: #ffffffa6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.vedio_btn{margin:40px 0 0;}
.vedio_btn button{background: #ff0600;
    border-color: #ff0600;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;}
.date_mention{font-size: 18px;
    font-weight: 700;
    color: #ff0600;}
/*//////////////////////////////////////Doc Manager/////////////////////////////////////*/
.table_cardheader_revert {
    background: #000;
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px;
}
.mail_editior {
    background: #000000;
}
.edit_label_input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mail_editior label {
    color: #fff;
}
.edit_inputs {
    width: 93%;
}
.edit_input {
    height: 40px;
    width: 93%;
    border-radius: 8px;
    padding: 0 10px;
    border: 1px solid #7d7d7d;
}
.RichTextEditor__editor___1QqIU{min-height: 170px;}
.email_btn{background: #ff0600;
    color:#fff;
    font-weight: 500;
    padding: 0 15px;
    min-height: 35px;
    border:1px solid #ff0600;
    border-radius: 4px;}
.doc_btnss button{height: 40px;
    font-size: 15px;
    padding: 0 20px;}
.airline_revert_popup {
    max-width: 900px;
}
.pop-text h4, .not-avial {
    text-align: center;
    margin: 40px 0 20px;
    font-size: 33px;
    color: #ff0600;
}

/*////////////////////////////////////// Query Shipment Manager //////////////////*/
.all_shipment_manager{background: #000;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;}
.all_shipment_manager .bottomCard{background: #fff!important;}
.big_origin_destin {
    color: #ff0000;
    font-size: 17px !important;
}
.status-ships h3 {
    color: #fff;
    margin-right: 6px;
    font-weight: 500;
    background: #ff0000 !important;
    text-align: center;
    border-radius: 50px;
    padding: 6px 12px;
    font-size: 13px;
}
.big_origin_destin i,.big_origin_destin svg{color:#000;}
.heading_shipment{border-bottom:1px solid #fff;font-size: 19px !important;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #fff !important;}
.all_shipment_manager .other-cardss label {
    font-size: 12px;
    font-weight: 600;}
.all_shipment_manager .origin-destination {
    font-size: 12px;
    color: #000000;
    font-weight: 400 !important;
}
.all_shipment_manager  .big_origin_destin{font-size: 15px!important;
    padding-left: 6px!important;}
.padding-5{padding: 0 5px;}
.all_shipment_manager .threecard-head h3{padding:0}
.all_shipment_manager .status-ships h3, .copy_btn .edit{padding: 5px 12px;font-size: 12px;
    line-height: 15px;}
.all_shipment_manager .detail-seconds{padding:4px 5px;}
/*////////////////////////////////////// Query Short Form //////////////////*/
.query-short .flights-types{padding:0 10px 10px}
.query-short .service_type h4,.query-short .service_type h4 span{font-size: 15px!important;}
.query-short .courier-box{width:50%;height:90px;font-size: 14px;}
.query-short .flights-types .triangle-topleft {
    color: green;
    font-size: 16px;
    right: 5px;
    top: 6px;
    left: inherit;
}
.query-short .service_type{width:48%;margin-bottom: 10px;}
.query-short  .courier-box.active {
    -webkit-transform: inherit;
    transform: inherit;}

.query-short .justify-content-evenly{justify-content: space-between}
.query-short .widths-25, .query-short .widthss-20 {
    width: 50%!important;
}
.query-short .steps-heading1{font-size: 17px;font-weight: 600;}
.query-short .all-boxes{min-height:95px;margin-bottom: 10px;}

.display-none-query{display: none;}
.box_width_change .courier-box{width:170px;}
.query-short .box_width_change .service_type{width:100%;}
.query-short .box_width_change .service_type .d-flex{justify-content: center}
.status-ships h3.green_status{background:green!important;}
.query-short .courier_padding{padding:0 0 0}

.shipment_details h5{font-weight: 600;}
.pick_shipment h5 , .pick_shipment_d h5{font-weight: 600;text-transform: uppercase;font-size: 13px;margin-bottom: 5px;}
.pick_shipment{width: 31%;
    margin-bottom: 15px;
    margin-right: 10px;}
.pick_shipment_d{width:40%;margin-bottom:15px;margin-right: 5px;}
.otp_pickup{text-align: center;}
.otp_pickup h2{margin-bottom: 10px;}
.otp_pickup input{border-radius: 4px;
    border: 1px solid #c8c8c8;
    background: #fff;}

.send_btn{width: 100%;
    text-align: right;
    justify-content: right;
    display: flex;
    border-top: 1px solid #d1d1d1;
    padding: 10px 0 0;}

.send_btn .delete{background: #ff0600!important;border:1px solid #ff0600!important;}
.goods_handover{padding: 45px 5px 20px;}
.goods_handover .rates_head{font-size: 32px;}
.drivers_details{width:70%;}
.driver_imgs{width: 20%;margin-right: 20px;background: #f3f3f3;}
.driver_imgs img{width:100%;
    /*height: 250px;*/
    object-fit: fill;}
.display_tour1{display: none;}

.pop-text-error {
    text-align: center;
    padding: 45px 30px 30px;
}
.pop-text-error h6 {
    font-size: 35px;
}

.width_Change .ratecard_data_rates{width:80%;}
.width_Change .ratecard_button_rates{width:20%;padding:0;}
.notification_div label{font-size: 20px;
    font-weight: 600;}
.notification_div_list{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.notification_div_list .form-group{width:48%;}
.notification_div_list label{font-size: 18px;
    font-weight: 600;}
.button_noti{padding-left:30px;}
.Zipa_suggest{max-width:800px;}
.iframe{pointer-events: none;}
.suggest_design{border-radius: 8px;
    overflow: hidden;
    margin-bottom:20px;
    background: #fff;
    box-shadow: 0 2px 6px 0 #999;}
.suggest_design .card-typess{background:#ff0600;}
.origin_info h3, .destination_info h3{font-size: 25px;
    font-weight: 600;}
.rates_product{background: #ffb9b6;
    color: #000;
    padding: 0 9px;
    margin-left: 4px;
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    border: 1px solid #ff908d;}
.rates_product.slab_rates_new{background: #efefef;
    border: 1px solid #bcbcbc;}
.heading_rates h6{font-size: 13px;
    margin-bottom: 5px;
    font-weight: 600;}
.heading_rates h5{font-size: 15px;
    font-weight: 400;}
.rates_description{width:75%;}
.rates-value{border-left: 1px solid #dfdfdf;
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}
.rate_price{font-size: 24px;font-weight: 600;margin-bottom: 5px;}
.rate_price i,.rate_price svg{font-size: 22px;}
.rate_button{background: #ff0600;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    padding: 5px 16px;}
.productName{font-size: 20px;font-weight: 600;}
.suggest_design{border:2px solid #000;}
.des_rates_suggest{
    border-top: 1px solid #d6cdcd;
    padding: 10px;
    border-bottom: 1px solid #d6cdcd;}
.padding-10-lr{padding:5px 10px;}
.des_rates_suggest img{width:40px;}
.border-top_suggest{border-top:2px solid #000;}
.slab_rates_new.rotate_icon i,.slab_rates_new.rotate_icon svg{transform: rotate(180deg);}
.vedio_banner_front {
    width: 100%;
    position: absolute;
    right: 0;
    top:-4px;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}
.no_avail{font-size: 30px;
    font-weight: 600;
    padding: 50px 0;
    color: #ff0600;
    text-align: center;}
.ship-airbill .performa-table table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 6px;
}
.width-10{width:20%;}
.viewData .width-80,.width_upload_100 .width-80{width:78%;}
.upload-inputs .width-10 .btn-secondary{border-radius: 4px;min-height: 38px;background:#000;border-color:#000;width:auto;}
.view_uploads_btn{min-height: 38px!important;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    background: #000;
    color: #fff;
    padding: 0 15px;}
.viewData{display: flex;flex-wrap: wrap;justify-content: space-between}
.upload_docs_style{width:80%;}
.width_upload_100{width:100%;}
.customer_kyc_page .col-md-12,.customer_kyc_page .col-lg-6,.customer_kyc_page .col-xl-4,.customer_kyc_page .col-xl-2{padding-right:10px;padding-left:10px;}
.select__menu.css-26l3qy-menu,.css-26l3qy-menu{
    position: relative;
    z-index: 99!important;
    border: 1px solid #cfcfcf;
}
.css-kj6f9i-menu{z-index: 99!important;position: relative;}

/*///////////////////////////////////////////////New query Form Design /////////////////////////*/

.queryPageImage {
    position: relative;
    height: 94vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-attachment: fixed;
}
.air_freight_banner {
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.560483316653207-1681535159135.jpg");
    background-position: center;
    background-size: cover;
}
.banner_text_background {
    background: #000000a3;
    border-radius: 10px;
    padding: 30px 20px;
    position: relative;
}
.activity_types {
    margin-bottom: 20px;
    color:#fff;
}
.banner_form_text .form-check-inline {
    margin-right: 1.5rem;
}
.new_banner_input {
    position: relative;
    width: 19.8%;
}
.new_banner_input label, .new_courier_input label {
    display: block;color: #fff;
}
.new_banner_input .box_new_query, .new_courier_input .box_new_query{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: #fff;
    padding: 0 10px;
    display: flex;
    cursor:pointer;
    align-items: center;
}
.banner_text_background button {
    padding: 8px 22px;
    font-size: 17px;
    color: #fff;
    background: #ff0600;
    font-weight: 500;
}
.banner_text_background .cross-btn {
    right: -10px;
    top: -11px;
}
.banner_text_background .cross-btn i,.banner_text_background .cross-btn svg{background: #ff0600;border-radius: 50px;}
.banner_text_background .displayCross{background: #ff0600!important;}
.mode_texts {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
}
.airModes {
    background: #fff;
    color: #000;
    padding: 9px 14px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 4px;
    text-transform: uppercase;
    font-weight: 600;
}
.airModes.active {
    background: #ff0600!important;
    color: #fff!important;
    border-color: #ff0600!important;
}
.box_new_query p{font-weight: 600;color: #000;}
.NewHomeSection{
    padding:76px 0 40px;
    /*padding:100px 0 40px;*/
}
.NewHomeSections{
    /*padding:85px 0 0;*/
    padding:75px 0 0;
}

/*//////////////////////////////////Past Shipment//////////////////////////////*/

.pastShipment, .visitorSection{padding:50px 0 80px;}
.servicesSections {
    padding: 50px 0 0px;
}
.pastShipment{background: #fbfbfb;}
.pastShipment h3{font-size: 30px;
    font-weight: 600;}
.shipment_box{box-shadow: 2px 1px 6px 2px rgb(0 0 0 / 20%);
    background: #fff;
    border: 1px solid #000000;
    width: 32%;
    padding: 10px 14px 10px;
    margin-bottom: 20px;
    border-radius: 5px;}
.pastShipment .title_shipment, .SpotRates .title_shipment,.title_shipment{margin-bottom: 50px;font-size: 30px;
    font-weight: 600;}
.shipment_origin_destination h6{font-weight: 600;
    font-size: 14px;
    color: #ff0600;
    margin-bottom: 5px  ;}
.shipment_box h3 {
    font-size: 18px;
    margin-bottom: 20px;
}
.shipment_new_status h6{color: #009800;
    font-weight: 600;
    font-size: 14px;}
.price_query{color: #ff0600;
    font-weight: 600;
    font-size: 18px;}
.ocean_freight_banner{
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8879142455511706-1682401015638.jpg");
    background-position: bottom;
    background-size: cover;}
.courier_freight_banner{
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.553128115058463-1681535221024.png");
    background-position: bottom;
    background-size: cover;
}
.other_service_banner{
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.34944940001980895-1681535321967.jpg");
    background-position: bottom;
    background-size: cover;
}
.road_freight_banner{
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.6076269864268515-1681535263053.jpg");
    background-position: bottom;
    background-size: cover;
}
.query_new {
    border-radius: 50px;
    padding: 2px 0 2px 12px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    text-align: left;
    background: #edeaea;
    cursor: pointer;
}
.query_new span {
    width: 20px;
    height: 20px;
    background: #ff0600;
    color: #fff;
    margin-left: 7px;
    border-radius: 50px;
    text-align: center;
}
.SpotRates{padding:80px 0 80px;}
.spot_transit, .center_width{width:100%;text-align: center;}
.spot_transit{color: #0067d5;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;}
.centerLine {
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.lineCenter{position: relative;
    display: block;
    width: 82%;
    height: 1.6px;
    margin: 0.375rem auto!important;
    padding: 0;
    border-radius: 0.375rem;
    background-color: #68697f;
    line-height: 0;
    text-align: center;}

.origin_destination_new{font-size: 22px;font-weight: 500;}
.details_spot{font-weight: 600;
    color: #676767;
    margin-bottom: 15px;
    font-size: 14px;}
.new_courier_input{width:24.8%;position: relative;}
.new_Package_Details{width:32.8%;position: relative;}

/*/////////////////////////////////////////////////////RATES SCREEN ////////////////////////////////////*/
.rates_all_details {
    margin: 10px 0 80px;
}
.white-rates {
    border-radius: 6px;
}
.details_for_rate h3 {
    font-size: 25px;
}
.icon_origin_destination {
    font-size: 23px;
    color: #ff0600;
    padding: 0 20px;
}
.details-other {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.text-shipment {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 600;
}
.text-shipment span {
    font-weight: 400;
}
.dashboard-ratesheading {
    width:55%;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 4px 11px 2px rgb(0 0 0 / 28%);
}
.sort_filter {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 15px;
    z-index: 1;
    flex-direction: column;
    box-shadow: 0 1px 7px 0 #bcbcbc;
}
.sort_label {
    display: flex;
    width: 55%;
    align-items: center;
    justify-content: end;
    margin-bottom: 8px;
}
.sort_label label {
    width: 33%;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 13px;
}
.sort_label .sorting_filter{
    width:60%;
}
.sort_label .currency_filter{
    width:40%;
}
.sort_label select, .mode_select select {
    border-radius: 4px;
    border: 2px solid #000;
    font-size: 15px;
    padding: 0 10px;
    font-weight: 500;
}
.sort_label select{font-size: 13px;}

.dashboard-ratesheading .form-side {
    height: 750px;
    border-radius: 5px;
    padding: 13px 10px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.destination_origin_app {
    padding: 10px 5px 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: #4a4a4a;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.width-new-rates {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}
.images_destination {
    width: 10%;}
.destination_origin_app img {
    width: 30%;
}
.origin_destination_app {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
.origin_app, .destination_app {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}
.small_name {
    color: #000;
    padding-bottom: 6px;
    font-size: 15px;
    font-weight: 700;
}
.full_name {
    font-size: 13px;
    word-break: break-word;
    line-height: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: #000000;
}
.progress{background-color: #f3f3f3;
    height: 6px}

.rate_new_design {
    color: #ff0600;
    font-weight: 600;
    font-size: 21px!important;
}
.name_company {width: 20%;
    padding: 20px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left:2px solid #000;
}
.name_company h5 {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 20px;
}
.company_btn {color: #fff;
    background: #ff0600;
    padding: 8px 9px;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 600;
}
.spot_coupon_rates{border-top: 1px solid;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px 0;
    justify-content: end;}
.app_couply, .need_credit_query select{border: 1px solid #000000;
    cursor: pointer;
    border-radius: 50px;
    padding: 2px 5px;
    margin-right: 8px;
    font-weight: 500;
    color: #1b1b1b;
    background: #fcfcfc;
    text-transform: capitalize;}
.switch_currency label{font-size: 12px;
    font-weight: 600;}
.switch_currency .form-check{margin-right: 8px;}
.Query_filter {
    width: 19%;
    box-shadow: -2px 4px 11px 2px rgb(0 0 0 / 28%);
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
}
.Query_filter h4, .newStaticsItems h4{font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 600;}

.transit_checkBox .form-check{margin-bottom: 15px;}
.transit_checkBox{margin-top: 10px;margin-bottom: 20px;}
.heading_filter{font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 4px;}
.transit_checkBox input[type=checkbox]{width: 16px;
    height: 16px;}
.transit_checkBox label{margin-left: 15px;}
.select_clear_filter {
    margin-top: 13px;
    margin-bottom: 20px;
}
.tab_filter{font-size: 15px;
    font-weight: 600;
    color:blue;
    margin-right: 10px;
}
.tab_line{width: 2px;
    background: black; margin-right: 10px; }
.need_credit_query{display: flex;}
.list-fare p{text-align: left}
.display_not_commo{display: none;}
.pastShipment .airModes, .SpotRates .airModes{background:#000;color: #fff;}
.shipment_box img{width: 45px;
    position: absolute;
    top: -4px;
    right: -9px;}
.text_origin{font-size: 20px;width:33%;word-break: break-word;white-space:break-spaces;}
.origin_app .light_text{font-size: 15px;font-weight: 500;}
.origin_destination_rates{width:60%;display:flex; flex-wrap:wrap;justify-content: space-between}
.destination_name{font-size: 17px;font-weight:600;text-transform: uppercase;}
.spot_transit2 {
    color: #444560;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
}
.fright_summery_icon{font-size:17px;margin-left: 10px;}
.rates_new_screen{
    width:30%;
    display: flex;flex-wrap: wrap;
    justify-content: center;
}
.color-product{color: #00a232;font-weight: 600;}
.cheapest_fastest{display: flex;flex-wrap: wrap;align-items: stretch;justify-content: space-between;}
.cheapest_rates {padding: 10px 20px;
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    cursor: pointer;
    width: 32.9%;
    background: #dfdfdf0d;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cheapest_rates h4{margin-bottom: 2px;
    font-size: 13px;
    color: #747474;
    font-weight: 500;}
.cheapest_rates h4.view_all{font-size: 20px;}
.cheapestRate {
    color: #180000;
    font-weight: 600;
    font-size: 19px;
}
.cheapest_rates.active{background: #ff0600;box-shadow:none;}
.cheapest_rates.active h4{color:#fff;}
.cheapest_rates.active .cheapestRate{color:#fff;}
.need_credit_switch{display: flex;flex-wrap: wrap;align-items: center;}
.cursorNOtAllowed {
    cursor: not-allowed!important;
    opacity: .5;
}
.cheapest_rates.active p{color:#fff;}
.table.freight_diff_table td, .table.freight_diff_table th{border:1px solid #fff!important}
.table.freight_diff_table td.total_amount_diff, .table.freight_diff_table th.total_amount_diff {
    border: 1px solid #fff!important;
    font-weight: 700;
    border-top: 2px solid #000!important;
}
.proceed_btn{background: #ff0600;
    width: 100%;
    color: #fff;
    font-weight: 500;
    font-size: 20px;}
.side_bar_rate{width:25%;}
.sideBar_margin{margin-right:0;}
.zipasuggestion_side{padding: 0;}
.apply_coupon_div {font-size: 13px;
    color: #009eff;
    cursor: pointer;
    text-align: right;
    margin-bottom: 10px;
    font-weight: 500;
    text-decoration: underline;
}
.fare_table_design td, .fare_table_design th {
    border-color: #000!important;
    border: 1px solid #000!important;
    border-top: 1px solid #000!important;
    font-size: 12px;
    padding: 6px;
}

.border-tops-frieght {
    border-top: 1px solid #868686 !important;
    border-bottom: 1px solid #868686;
    margin-bottom: 15px;
}
.past_shipment_border {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 7px;
    justify-content: end;
}
.radio_rates .form-check-input{margin-top: -0.5rem;
    width: 18px;
    height: 18px;}
.shipment_details_up{background: #0000008c;
    border-radius: 0;
    padding: 10px 10px 0;}
.shipment_details_up .text-shipment{color:#fff;font-size: 15px;}
.shipment_details_up .details_for_rate h3{color:#fff}
.icon_origin_destination i,.icon_origin_destination svg{color:#fff;}
.shipment_details_up .details-other {padding: 10px 0;}
.shipment_details_up .details_for_rate {width: 100%;}
.shipment_details_up button{padding: 3px 12px;
    border-radius: 4px;
    background: #fff!important;
    color: #000;
    font-weight: 700!important;
    font-size: 13px;}
.price_query span{
    text-decoration: line-through;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
}
.margin_paddings{padding:0 2%;}
.margin_paddings .shipment_box{width: 100%;min-height: 160px;box-shadow: 0 0.0625rem 0.1875rem rgb(0 0 0 / 12%);margin-bottom: 10px;}
.margin_paddings .origin_destination_new{text-transform: uppercase;}



/*//////////////////////////////////////////////popup design changes///////////////////////////////*/
.popular_listing {
    padding: 10px 0 0;
}
.popular_listing h4 {text-transform: uppercase;
    letter-spacing: 1px;
    color: #000000;
    padding-bottom: 10px;
    padding-left: 11px;
    font-weight: 700;
    font-size: 13px;
}
.items_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 12px;
}
.airortName_listing {
    width: 80%;
}
.code_listing {width: 20%;
    text-align: right;
    font-size: 14px;
    font-weight: 700;
}
.popular_listing h5 {font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
}
.popular_listing h6 {color: #9b9b9b;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .3px;
}
.popup_query_from{position: absolute;
    background: #fff;
    top: 77px;
    left: 0;
    right: -150px;
    /*padding: 0 10px;*/
    color: #000;
    border-radius: 5px;
    z-index: 1;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 45%);}
.popup_new:before {
    content: "";
    position: absolute;
    left: 2%;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    clear: both;
}
.popup_new .listing_items{height: 250px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 10px 20px;
}
.popup_new .listing_items_notscroll{padding: 10px 20px;}
.origin_lable label{display: none;}
.origin_lable .css-bg1rzq-control{border-radius: 0;
    line-height: inherit;
    height: 46px;
    padding: 0;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
    outline: 0;
    border: 0;}
.origin_lable .css-1hwfws3{line-height: inherit;
    height: auto;}
.origin_lable .css-151xaom-placeholder {font-size: 15px !important;
    color: #8b8b8b!important;
    font-weight: 600;
}
.origin_lables{padding: 10px 10px 0;}
.origin_lables select,.origin_lables input{box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%)!important;border-radius: 5px;}
.origin_lables label{font-size: 13px;font-weight: 600;}
.container_details {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #f5f5f5;
}
.container_name {
    width: 60%;
    padding: 8px 2px;
    font-weight: 500;
    font-size: 13px;
}
.sign_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 4px;
    width: 40%;
}
.container_btn {
    width: 22px;
    height: 22px;
    color: #fff;
    line-height: 22px;
    font-size: 13px;
    text-align: center;
    border-radius: 50px;
    background: #ff0600;
}
.container_text {
    text-align: center;
    height: 25px;
    line-height: 25px;
    font-size: 20px;
}
.skyscanner_design_div{margin-top: 20px;}
.skyscanner_design_div .slick-prev, .skyscanner_design_div .slick-next{top:-14px;background: #ff0600!important;border-radius: 5px!important;}
.skyscanner_design_div .slick-prev {
    position: absolute!important;
    right: 43px!important;
    left: inherit!important;
    z-index: 1!important;
}
.skyscanner_design_div .slick-next{right:10px!important;}
.new_query_div{
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #fff;
    min-height: 400px;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.spotPastShipment .new_query_div{width: 32.4%;}
.spotPastShipment .centerLine img{width:100%;}
.new_query_div .big_img{width:100%;height:130px;border-radius: 5px;margin-bottom: 15px;}
.new_query_div h3{font-size: 1.5rem;margin-bottom: 20px;font-weight: 500;}
.query_text{display: flex;justify-content: space-between;margin-bottom: 25px;}
.logo_imgs img{max-width: 2.25rem;
    margin-right: 0.75rem;}
.logo_imgs{display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: start;}
.right_img_text{color: #00a698;font-weight: 600;font-size: 13px;}
.right_query{color:#0770E1;font-weight: 600;font-size: 15px;}
.lefts_query{font-size: 13px;}
.popup_new.origin_lables_door{
    padding: 0;
}
.popup_new.origin_lables_door .listing_items{
    height: auto;
    overflow-y: inherit;
    padding: 0 10px;
}
.origin_lables_door .form-group {
    margin-bottom: 0.5rem;
}
.origin_lables .dan-good .custom-file-label, .origin_lables .dan-good .custom-file-label::after{border-radius: 5px;}
.dan_button button{font-size: 12px;}
.activity_types input[type=radio] {
    width: 18px;
    height: 18px;}
.origin_lables .ipt1{height:45px;}
.origin_lables .dangerous_pop input{width:100%;}

.edit.back_black{background:#000!important;}
.zipasuggestion_side .rates_head {
    width: 100%;
    padding: 15px 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.edit1, .delete {
    border: none;
    background: #000000!important;
    color: #fff;
    align-items: center;
    padding: 7px 20px 5px;
    line-height: 20px;
    text-align: center;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 600!important;
    font-size: 11px;
    cursor: pointer;
    margin-right: 5px;
}
.next {
    text-align: right;
    margin-bottom: 10px;
}
.rates_head i,.rates_head svg{font-size:12px;}
.zipasuggestion_side .centerLine{width:35%;}
.zipasuggestion_side .text_origin{text-align: center;width:30%;}
.zipasuggestion_side .name_company{width: 100%;border-left:2px solid transparent;border-top:2px solid #000;padding: 10px 10px;}
.freightSummeryzipa{padding:0 10px;}
.freightSummeryzipa .apply_coupon_div{padding: 0;font-size: 11px;}
.zipaRs{color: #ff0600;
    font-weight: 600;
    font-size: 18px!important;}
.lefts_query .right_img_text{    margin-right: 10px;
    border: 2px solid #ff0600;
    border-radius: 6px;
    padding: 3px 10px;
    color: #ff0600;}
.newQueryOrigin{font-size: 30px;
    font-weight: 500;}
.newQueryOrigins {
    font-size: 13px;
    margin-bottom: 10px;
}
.newQueryOrigins span{font-size: 14px;}
.right_img_text.queryStatus{font-size: 16px;
    color: #008e00;}
.query_text_new{border-bottom: 1px solid #dfdfdf;width: 100%;
    margin-bottom: 15px!important;}
.newQueryOrigins span{float: right;font-weight: 600;}
.margin_gap{padding:1%;}
.zipaSuggModal{height:480px;overflow-y: scroll;}
.right_query span{
    text-decoration: line-through;
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
}
.shipperDetails label{
    font-weight: 600;
    font-size: 13px;}
.shipperDetails .css-bg1rzq-control,.houseDetails .css-bg1rzq-control,.house_card .css-bg1rzq-control{border-color: #000;}
.shipperDetails .width_dim_20 {
    width: 32%;
    margin-right: 8px;
}
.shipperDetails select, .shipperDetails input,.houseDetails select, .houseDetails input, .house_card input, .house_card select{border-color: #000!important;}
.shipperDetails .form-control:disabled, .shipperDetails .form-control[readonly], .houseDetails .form-control:disabled, .houseDetails .form-control[readonly]{border-color: #000!important;}

.packaging_commercial .upload-data .custom-file-label{border-color: #000!important;}
.packaging_commercial .upload-data .custom-file-label::after{border-radius: 5px;}
.house_card label{font-weight: 700;}
.house_scroll_check{height: 400px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;}
.shipper_con_pop .gst-btn{padding:8px 10px;margin-right:6px;cursor: pointer;}

.shipper_con_pop .close{top:0}
.sort_labels{width:60%;text-align: right;display: flex;align-items: center;justify-content: space-between;}
.sort_labels label{margin:0;width: 30%;}
.sort_labels select{border:2px solid #000;border-radius: 4px;height: 45px;font-size: 14px;width: 68%;}
.download_app {text-align: center;
    padding: 50px 30px;
    background: #000;
    color: #fff;}
.download_app h2,.download_app h3{font-size: 38px;margin-bottom: 15px;}
.download_app img{width: 150px;margin:0 4px;}

.footer-wrap {
    padding-top: 42px;
    padding-bottom: 40px;
    background-size: cover;
    color: #000;
}

.footer-wrap h3 {
    color: #ff0600;
    font-size: 18px;
    font-weight:700;
    text-transform: uppercase;
    margin-bottom: 25px;

}
.second_class_bdr .about_footer p {
    font-size: 15px;
    line-height: 24px;
    color: #000;
    margin-top: 15px;
    font-weight: 500;
    text-align: justify;
    width: 85%;
}
.contact_footer p {
    font-size: 13px;
    margin: 0 0 15px;
    font-weight: 600;
    line-height: 1.5em;
}
.footer-wrap p a {
    color: #fff;
    text-decoration: underline;
    font-style: italic;
}

.footer-wrap p a:hover {
    text-decoration: none;
    color: #ff7800;
}

.footer-links li a {
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
}

.footer-links li:before {
    content: "\f104";
    /*font-family: "Font Awesome 5 Free";*/
    font-family: "FontAwesome";
    padding-right: 10px;
    font-weight: 900;
    color: #ff0600;
}

.footer-category li a {
    font-size: 15px;
    line-height: 30px;
    color: #000;
    font-weight: 600;
    text-decoration: none;
}

.footer-category li:before {
    content: "\f104";
    /*font-family: "Font Awesome 5 Free";*/
    font-family: "FontAwesome";
    font-weight: 900;
    padding-right: 10px;
    color: #ff0600;
}

.address {
    color: #b3b3b3;
    font-size: 14px;
    position: relative;
    padding-left: 30px;
    line-height: 30px;
}

.address:before {
    content: "\f277";
    font-family: "FontAwesome";
    position: absolute;
    top: 0;
    left: 0;
}
.fa-phone:before {
    content: "\f095";
}
.info a {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 30px;
    font-weight: normal;
}

.fa-fax:before {
    content: "\f1ac";
}

footer .second_class{
    padding-bottom: 25px;
}
footer .first_class {
    padding-bottom: 21px;
    border-bottom: 1px solid #444;
}
footer .first_class p, footer .first_class h3{
    margin: 0 0;
}
footer .second_class_bdr{
    padding-top: 25px;
}
footer .btn-facebook a {
    padding: 6px 14px !important;
}
footer .btn-envelop a {
    color: #D6403A;
    font-size: 15px;
    padding: 12px 12px;
}
footer .round-btn a {
    padding: 6px 12px;
}
footer .round-btn {margin: 15px 4px;
    box-shadow: 2px 2px 5px 0 #222 !important;}
footer dl, ol, ul {
    padding-left: 5px;
}
footer li{
    list-style: none;
}
.footer-social-link li a {
    width: 45px;
    height: 45px;
    display: inline-block;
    box-shadow: 0 3px 14px #666c752e;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    transition: all 0.3s ease-in;
    background: #3B5998;
    font-size: 27px;
    margin-right: 10px;
}
.linkedin_icon{background: #2663AC!important;}
.twitter_icon{background: #55ACEE!important;}
.instagram_icon{background: #CB2027!important;}
.second_class_bdr h4{font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;}
.popup_query_from textarea{width: 100%;
    border-radius: 5px;}
.otherForms .popup_query_from{right:0;}
.filterApply {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0;
    right: 0;
    background: #fff;
    height: 6%;
    border-top: 1px solid #dfdfdf;
    padding: 10px 10px;
    align-items: stretch;
}
.apply_now_btn{font-size: 15px;
    font-weight: 600;
    color: blue;
    text-transform: uppercase;
    letter-spacing: 1px;}

.close_btn_apply{color:#ff0600;}
.centerFilterLine{width: 2px;
    background: #dfdfdf;
    height: 26px;}

.filterBtn{display: none;}
.shipper_con_pop .modal-header {
    border-bottom: 1px solid #d7d7d7;
}
.shipper_con_pop input,.shipper_con_pop select{border-radius: 4px;height: 45px!important;}
.shipperDetails2 .width_dim_20 {
    width: 48%;
    margin-right: 8px;
}
.packaging_commercials .upload-data{width:100%;}
.design_new_shipper h4{font-size: 23px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #ff0600;
    text-align: center;}

.rates_fright h3 {
    font-size: 25px;
    text-align: center;
    color:#ff0600;
}
.booking_list{display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 0 7px 1px #e3e3e3;
    border-radius: 5px;
    padding: 10px 15px;
}
.heading-title {
    font-size: 17px;
    color: #4a4a4a;
    font-weight: 600;
}
.icon_arrow{font-size: 20px;color:#ff0600;}
.booking_list_other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    padding: 10px 10px;
}
.all-price::-webkit-scrollbar{height:7px;}
.foot_logos img{width:300px;}
table.slab_tables th{font-size: 14px!important;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
    border: 1px solid #000!important;
    background: #dfdfdf;
}
.slab_tables td strong{font-size: 14px;
    text-transform: uppercase;
}
.slab_btns{width: 100%;
    border-radius: 3px;
    background: #ff0600!important;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff!important;
    margin: 0;}
.slab_tables td, table.slab_tables td{text-align: center;border: 1px solid #000!important;border-top: 1px solid #000!important;}
.view-all h6{font-size: 20px;
    font-weight: 600;}
.origin_destination_names{margin-bottom: 20px;
    display: none;}
.origin_destination_names h3{margin-bottom: 10px;font-size: 14px;}
.origin_destination_names h3 span{font-weight: 600;}
.bookNowSideBar,.showDisplayZipa{display: none;}
.spotRateSearches .css-151xaom-placeholder {
    color: #000;
    font-weight: 600;
    font-size: 14px!important;
}
.spotRateText{font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ff0600;}
.volumeMetric h2{margin:10px 0 20px;}
.volumeMetric h6{font-size: 18px;
    line-height: 30px;margin-bottom: 25px;}
.ships-model{top: 0;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    margin: 0!important;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background: url(https://test231220.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.21555075212967023-1669185164717.jpg) center;
    background-size: cover;
    position: fixed;}
.ships-model .modal-content{height:100%;
    background: transparent;
    overflow-y: scroll;
}
.ships-model .banner_text_background button {
    padding: 6px 16px;
    margin-left: 5px;
    font-size: 15px;}
.new_shipper_inputs {width: 49.8%!important;}
.enter_shipper_inputs {width: 32.8%;}
.ships-model .popup_query_from{right:0;}
.ships-model .popup_new .listing_items {
    height: 130px;}
.ships-model .form-control[readonly]{border:1px solid #000!important;}

.ships-model .airModes{text-transform: capitalize;font-size: 14px;}
.shipperUpload .width-6{
    /*width:80%;*/
    width:58%;
    margin-bottom: 10px;margin-right: 0;}
.shipperUpload .doc_btnss button{border-radius: 5px!important;}
.ships-model .houseShipper .popup_query_from {
    right: -65px;
}
.houseDetailsNo .new_shipper_inputs {
    width: 43.8%;
}

.slick-slide img{object-fit: contain;}
.skyscanner_design_div .slick-slide img{width:auto;height: 30px;}
.skyscanner_design_div .spot_transit2 {
    text-align: center;
}
.spot_Design .query_text_new{padding:40px 0;width: 100%;}
.ships-model .banner_text_background button.active{background: #000;border:1px solid #fff;}
.showForMob{display: none;}
.dimen_editbooking_popup input[type=text],.dimen_editbooking_popup select,.dimension_shipper input[type=text],.dimension_shipper select{
    height: 50px!important;
    line-height: inherit;
    padding: 1.625rem 0.65rem 0.62rem;
    font-size: 12px!important;
    font-weight: 500;}
.dimen_editbooking_popup select, .dimension_shipper select{padding: 1.42rem 0.65rem 0.62rem;}
.dimen_editbooking_popup .checkbox-widths label{color:#000;}
.width_change .banner_form_text{width:80%;margin:auto;}
.queryPreviewChange .destination_origin_app img {
    width: 70%;cursor: pointer;
}
.queryPreviewChange .spot_transit2{font-size: 10px;}
.queryPreviewChange .spot_transit, .queryPreviewChange .center_width{align-items: center}
.queryPreviewChange .origin_destination_rates{align-items: center;}
.queryPreviewChange .shipsImage img{width:20px;}
.trade_page label{font-weight: 700;}
.trade_page label sup{color:#ff0600;}
.trade_page input,.trade_page select,.trade_page textarea{
    font-weight: 500;
    padding: 0 20px;
}
.trade_page textarea{padding: 10px 20px 0;}
.demoCssIcon i,.demoCssIcon svg{
    color: #ff0600;
    font-size: 29px;
}

.loginButtonChange button {
    background: transparent;
    outline: 0!important;
    box-shadow: none!important;
}
.textLogin{cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color:#202020!important;
    text-transform: uppercase;}

.navbar-nav .nav-link{font-size: 14px;}
.loginTextChange{color: #0770E1;}
.bookingNerForm{
    /*padding:28px 0 0;*/
    padding:20px 0 0;
}
.textBooking{width: 50%;
    text-align: left;
    margin: auto;
    padding: 90px 0 45px;}
.textBooking p {
    font-size: 20px;
    line-height: 50px;
}
.textBooking p span{font-weight: 600;color:#ff0600;}
.textBooking button{margin-top: 30px;
    background: #ff0600;
    color: #fff;
    padding: 8px 30px;
    font-size: 19px;
    font-weight: 600;
}
.coupon-base-infoContainer{
    font-size: 14px;
    line-height: 25px;
}
.coupon-name{font-weight: 600;
    color:#ff0600;
    font-size: 17px;}
.allCoupon {text-align: left;
    background:#fff;
    border-radius: 5px;
    margin-bottom: 4px;
    padding: 15px 12px;
    border-top: 1px solid #000;
}
.allCoupon .coupon-base-customCheckbox input[type=radio]{
    margin-left: 0;
}
.allCoupon .coupon-base-customCheckbox .coupon-name{margin-left: 15px;}
.coupon-details{font-size: 13px;
    line-height: 21px;}


.coupanInput input{border-radius: 5px;
    border: 1px solid #000;}

.coupanInput button{border-radius: 3px;
    background-color: #ff0600!important;
    font-size: 15px;
    width: 100%;
    font-weight: 600;}
.zipasuggestion_side h4{padding:10px 20px 0;}
.zipasuggestion_side h6{padding:0 20px;}
.suggestion_lists{padding:0 20px;height: 780px;overflow-y: scroll;}
.selection_list .selection_doc {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
}
.btn_upload_other{min-height: 40px!important;}
.lableNotShow label{display: none;}
.new_banner_input .box_new_query.lableNotShow{padding:0;position: relative;}
.lableNotShow .css-1hwfws3, .lableNotShow .css-bg1rzq-control{height: 45px;padding:0 2px;}
.lableNotShow .css-bgvzuu-indicatorSeparator{background: transparent;}
.lableNotShow .css-19bqh2r{display: none;}
.lableNotShow .css-1pcexqc-container{
    width: 100%;}

.lableNotShow .css-151xaom-placeholder {line-height: normal;
    font-size: 13px !important;
    color: #000!important;
    font-weight: 600;
}
.lableNotShow .css-kj6f9i-menu{width: 100%;left: 0;right:-140px;position: absolute;}
.popup_query_from label{color:#000;}
.icon_coupon{width:95%;}
.coupon-details i,.coupon-details svg{margin-right: 5px;font-size: 7px;padding-top: 7px;}
.coupanInput{margin:10px 0 0;}
.coupon-base-customCheckbox{margin-bottom: 5px;}
.slab_table_ocean_mob{display: none;}
.table_logo{background: transparent!important;}
.table_logo img{width:45%;}
.slab_tables.table .book-btn{background: #ff0600!important;}
.packing_Data .upload-data .custom-file-label{
    color:#000;
    background-color: #fff;
}
.packing_Data{
    width:75%;
    margin-bottom: 20px;
}
.shipper_con_pop .houseDetails label{color : #fff;}
.shipper_con_pop .dimension_shipper label{color: #000;}
.shipper_con_pop .new-originbox label{z-index: inherit;}
.shipper_con_pop .oringin-master h3{color:#fff;}











/*/////////////////visitors section//////////////////////*/
.visitors_img{position: relative;}
.visitors_img img{width: 1000px}
.visitor_list{background: #fff;
    box-shadow: 0 1px 10px 0 #0000004f;
    padding: 20px 30px;
    text-align: center;
    border-radius: 15px;}

.number_vistors{font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: #ff0600;}
.middle_text_visitors{position: absolute;
    top: -40px;
    left: 42%;}
.vistors_heading{font-size: 17px;
    font-weight: 600;}

.left_text_visitors{left: 8%;
    position: absolute;
    top: 36%;}
.right_text_visitors{right: 8%;
    position: absolute;
    top: 36%;}
.middle_text_visitors, .right_text_visitors,.left_text_visitors{width:22%;}
.visitorSection,.servicesSections{text-align: center;margin-bottom: 30px;}
.visitorSection{background: #fbfbfb;}
.visitors_img{margin-top: 90px;}
/*.servicesSections{background: #fbfbfb;}*/
.title_shipment span{font-size: 16px;
    color: #000000;
    font-weight: 500;}
.two_section{display: flex;flex-wrap: wrap;justify-content: space-between;margin-bottom: 125px;}
.services_text{width:38%;}
.services_img{width:60%;}
.services_text h3,.services_text h4 , .services_text h1{text-align: left;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
}
.services_text h3 span,.services_text h4 span, .services_text h1 span{color:#ff0600;}
.services_text p{font-size: 15px;text-align: justify;
    line-height: 30px;
    font-weight: 400;}
.services_img img{border-radius: 4px;
    box-shadow: rgb(0 0 0 / 19%) 0 29px 42px 0;}
.servicesSections .title_shipment{margin-bottom: 80px;}
.two_section{}
.otherServiceSection{display: flex;flex-wrap: wrap;align-items: stretch;justify-content: center;}
.services_item{width: 31%;
    cursor: pointer;
    margin-right: 25px;
    box-shadow: 0 -1px 5px 1px #b9b9b9;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    margin-bottom: 30px;}
.otherServices{background: #fbfbfb;padding: 80px 0 80px;}
.ourJourney{background: #fff;padding: 80px 0 80px;}
.read_more{color: #005fff;
    cursor: pointer;
    font-weight: 600!important;
    text-decoration: underline;
    font-size: 15px;}
.item_text{padding:0 10px 40px;background: #fff;}
.item_img{background: #fff;}
.item_text h4,.item_text h3{font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;}
.item_text h4 span, .item_text h3 span{color:#ff0600;}
.mobileShowHome{display: none;}
.footer-black .fa-twitter{color: #55ACEE!important;font-size: 21px;}
.footer-black .fa-facebook-f, .footer-black .fa-linkedin-in{color: #3B5998!important;font-size: 21px;}
.footer-black .fa-instagram{color: #CB2027!important;font-size: 21px;}
.footer-black .footer--follow i,.footer-black .footer--follow svg{font-size: 21px;}
footer .footer--follow h6, footer .footer--follow h3{font-size: 25px;margin-bottom: 30px;}
.slab_headings{background: #f3f3f3;
    border-radius: 4px;
    padding: 0 7px;
    border: 1px solid #000;}

.tablesSlabs .slab_btns{width:auto;}
.slab_headings .form-check-label{cursor: pointer;}
.shipperUpload .AAA-uploader-sender-only-data-1 li{color:#fff;}
.background_change {
    background: #dfdfdf!important;
    cursor: not-allowed!important;
}
.allStaticsData{width:80%;background: #ffffff;
    border-radius: 5px;
    box-shadow: -2px 4px 11px 2px rgb(0 0 0 / 28%);}

.newStaticsItems{padding: 10px 15px;}
.newStaticsItems .boxes-widths, .box_statics {
    width: 24%;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 2px solid #000;
}
.box-header {
    background: #ff0600;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}
.box_heading{padding: 5px 10px;}
.box-items{padding: 10px 10px;}
.box-items h6{padding-bottom: 6px;font-size: 14px;}
.margin_section_front{margin-bottom: 20px;}
.charts .card{border:2px solid #000;}
.charts select{border-radius: 3px;}
.highcharts-credits{display: none;}
.highCharts_div{box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    width: 33%;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px 0;}
.highCharts_div:hover {
    border: 1px solid #000;
    -webkit-box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 15%);
    box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 15%);
}
.volume_div{width:49.5%;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);padding: 10px 0;
    margin-bottom: 20px;border-radius: 4px;border: 1px solid #e0e0e0;}
.datesFilter {
    width:40%;
    margin-right: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #a9a9a9;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.datesFilter label{margin-right: 10px;margin-bottom: 0;}
.datesFilter input {outline: none;
    border-radius: 4px;
    border: 1px solid transparent;
}
.dateButton {margin-bottom: 10px;
    min-height: 42px;
    background: #ff0600;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.popup_query_from.right-0{right:0!important;}
.popup_new .listing-items-ocean{padding: 10px 10px;}
.listing-items-ocean .airortName_listing, .listing-items-ocean .code_listing{width:100%;text-align: left;
    font-weight: 600;}
.listing-items-ocean .airortName_listing{margin-bottom: 4px;}
.listing-items-ocean .items_list{border-bottom: 1px solid #f7f7f7;}


.line-straight {width: 1px;
    background: #a3a3a3;
    margin: 4px;}

/*//////////////////////////////////invoice manager table//////////////////////////////*/

.invoice_input input{border: 1px solid #000;
    border-radius: 5px;}
.width_invoice{width:40%;margin-right: 6px;}
.width_invoice label{font-weight: 600;}
.invoiceButton {
    background: #ff0600;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: transparent;
    min-height: 40px;
    border-radius: 5px;
    padding: 0 17px;
}
.filter_invoice{width: 60%;}
.search_invoice {
    width: 20%;
}
.two_part_shipCong{display: flex;flex-wrap: wrap;justify-content: space-between;width:48%;}
.shipperConsignee_input {width: 100%!important;}
.container_change .modal-header{border:1px solid #dfdfdf;}
.shipperConsignee_input .box_new_query{min-height: 85px;padding:5px 10px;align-items: start;}
.two_part_shipCong .new_banner_input{margin-bottom: 15px;}
.separate_shipper {
    width: 0.5px;
    background: #828282;
}
.button_new_add{background: #ff0600;
    margin-top: 6px;
    color: #fff;
    padding: 2px 7px;
    display: inline-block;
    border-radius: 4px;
    font-weight: 500;}
.container_change .modal-title {
    color: #ff0600;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}
.container_change h5{font-size: 20px;
    margin-bottom: 12px;
    font-weight: 600;}

.container_change .modal-footer button{background-color: #ff0600!important;
    border-color: #ff0600!important;}
.status_invoice{background: #0da400;
    border-radius: 5px;
    padding: 2px 7px;
    display: inline-block;
    color: #fff;
    font-weight: 500;}
.rightSerach{width:25%;}
.Top_section{padding:110px 0;}
.heading_left h4{font-weight: 600;text-transform: uppercase;}
.Edocket_manager {
    background-color: #000;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px 3px rgb(235 235 235);
}
.Edocket_manager .AAA-shipper-consignee-approve-data-12 {
    background: #fff;
    padding: 15px 20px;
    border-radius: 6px;
    background: #fff;
    margin: 0 0 15px;
    box-shadow: 2px 1px 5px 0 rgb(142 142 142 / 75%);
}
.docket_listing h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}
.docket_items{display: flex;flex-wrap: wrap;align-items: start;justify-content: space-between;width: 85%;}
.freight_manager .width-4{width:30%;}
.freight_manager .width-4 .css-bg1rzq-control{border-color: #000;border-width: 2px;height: 45px;}
.freight_manager .width-4 label{font-weight: 600!important;font-size: 15px;}
.freight-table-data{min-height: 500px;}
.freight-table-data .book-btn{background: #ff0600!important;}
.shipper_consignee_master {
    padding: 15px 6px;
    background: #fff;
    border-radius: 6px;
    margin: 10px 10px 15px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);}
.shipper_btns{font-size: 14px;
    padding: 5px 15px;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
    background: #ff0600!important;
    border: 1px solid #ff0600;
}
.certificate{padding: 10px;
    text-align: center;
    margin: 10px 10px 30px!important;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px #d9d9d9;
    /* background: #b7b7b7; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    min-height: 345px;
}
.certificate_image {
    background: #fff;
    padding: 0 10px;
    overflow: hidden;
}
.certificate_image img{width: 100%;-webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;}
.certificate h3{padding: 10px 10px 0;
    font-size: 16px;
    font-weight: 600;}
.awards_section h4{font-size: 30px;
    font-weight: 600;
    padding: 20px 0 40px;}
.awards_section h4 span{color:#ff0600;}
.awards_section .slick-slide img{width:100%;height: auto;}
.awards_section .slick-prev, .awards_section .slick-next{display: none!important;}
.awards_section{padding:80px 0 50px;}
.awards_certificates{width:31%;min-height: auto;}
.awards_items{margin:60px 0 0;}
.certificate_image img:hover{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


.destination-master {
    margin: 5px 5px 10px;
    padding: 10px 10px 6px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #b1afaf;
    box-shadow: 1px 3px 6px 1px rgb(0 0 0 / 38%);
}

.destordata {
    margin-right: 20px;
    margin-bottom: 5px;
}
.destordata h3 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 0;
    color: #000;
    margin-bottom: 0.5rem;
}
.destordata p {
    font-size: 13px;
    margin-bottom: 8px;
    word-break: break-all;
}
.origin_actions {
    border-top: 1px solid #ddd;
    padding: 5px 0 0;
    justify-content: flex-end;
}

.top_deals {
    width: 100%;
}
.deal {
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 15%);
    display: inline-block;
    border: 2px solid;
    padding: 2px;
    border-radius: 4px;
    vertical-align: middle;}
.deal img {border-radius: 4px;
    width: 100%;
}
.deal .dealName {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
    color: #333333;
    line-height: 1.1;
}
.dealTxt{padding: 10px 10px;}
.deal .dealTxt p {
    font-size: 12px;
    color: #333333;
    padding: 7px 0 11px;
    text-align: inherit;
}
.promo-val {
    width: 100%;
}
.deal .apply_coupon_div {
    font-size: 11px;
    color: #0081d0;
    cursor: pointer;
    text-align: right;
    /* margin-bottom: 10px; */
    font-weight: 600;
    text-decoration: underline;
}

.sm_vld {
    font-size: 10px;
    color: #7d7d7d;
    font-weight: 400;
    text-align: right;
}
.top_deals .content .deal .dealTxt span {
    color: #000000;
    font-weight: 400;
}
.top_deals .content .deal .dealTxt span {
    color: #000000;
    font-weight: 400;
}
.advertiseMent{padding:0 20px;overflow-y: scroll;height:880px;}
.offersAds{
    padding: 10px 0px;
}
.offers_heading{padding: 10px 0 0!important;}
.spotPastShipment_two .new_query_div {
    margin: 0 3px 10px;
}
.other_form .new_courier_input{padding-bottom: 10px;}

.PlansOur{
    background-size: cover;
    padding:20px 0;
    background-position: center center;
    min-height: 650px;
}
.LatestPlan{padding:80px 0}
.plans_heading{text-align: center;margin:auto auto 50px;}
.plans_heading h1{font-size: 34px;margin-bottom: 15px;font-weight: 600;}
.plans_heading p {color:#ff0600;
    font-size: 19px;
    font-weight: 500;
}
.price-plans {border-radius: 8px;
    text-align: left;
    padding: 25px 15px;
    /*background-color: #fafefd;*/
    /*background-color: #fff4f47d;*/
    box-shadow: -1px 0 4px 0 #b5b5b5;
    margin-bottom: 20px;
    width: 31.6%;
    position: relative;
    /*width: 23.6%;*/
    /*border: 2px solid #000;*/
}
.price-plans h3{font-size: 26px;
    color:#000;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;}
.price-plans h3 span{font-weight: 600;color:#ff0600;}
.price-plans h4{font-weight: 600;font-size: 20px;margin-bottom: 15px;}
.price-plans p.firstPara{font-size: 18px;margin-bottom: 10px;}
.price-plans ul li,.price-plans1 ul li{display: flex;
    flex-flow: nowrap;
    align-items: start;
    font-size: 15px;
    margin-bottom: 10px;}
.price-plans ul{margin-bottom: 50px;}
.price-plans ul i,.price-plans1 ul i,.price-plans ul svg,.price-plans1 ul svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-top: 4px;
    color: #ff0600;
    font-weight: 900;
}
.plans_details{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: stretch;}

.price-plans p.otherPara{font-size: 16px;
    margin-bottom: 15px;
    line-height: 25px;
    color: #6c6c6c;
    font-weight: 400;}
.plans_width{width:75%;margin:auto;}
.planBtn{background: #ff0600;
    color: #fff;
    position: absolute;
    bottom: 5px;
    width: 90%;
    font-weight: 600;
    text-transform: uppercase;margin-bottom: 6px;
    font-size: 17px;}
.planBtn a{color:#fff;}
.customerKycBackground .banner_text_background .custom-file-label::after{display: none;}
.customerKycBackground .banner_text_background .custom-file-input{height: 45px;}
.customerKycBackground .banner_text_background .custom-file-label{border:1px dashed #000;
    text-align: center;
    font-size: 13px;
    border-radius: 4px;
    height: 45px;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;}
.blue_kyc{color:rgb(3, 74, 253);}
.termsPlans{margin-top:40px;}
.termsPlans ul{padding-top: 30px;}
.eye_icon{color: #ff0600;
    margin-top:5px;
    cursor: pointer;}
.file_kyc{color:#c2c2c2;}
.customerKycBackground {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    padding:40px 0 50px;
}
.houseSection{padding: 50px 0 40px;}
.customerKycBackground .banner_text_background .kycBorderSeprate button {
    padding: 6px 16px;
    margin-left: 5px;
    border-radius: 4px!important;
    font-size: 15px;
}
.customerKycBackground .banner_text_background label{color:#fff;font-size: 13px;}
.customerKycBackground .banner_text_background input[type=text],.customerKycBackground .banner_text_background input[type=number],.customerKycBackground .banner_text_background input[type=date],
.customerKycBackground .banner_text_background select.select-gst,.customerKycBackground .banner_text_background select.ipt11,.customerKycBackground .banner_text_background select{height: 45px!important;
    border-radius: 5px!important;
    /*background: #fff;*/
    padding: 0 10px!important;}
.customerKycBackground .banner_text_background .form-control:focus {
    border-color: transparent;
}
.customerKycBackground .width-4 .css-bg1rzq-control,.customerKycBackground .css-1hwfws3, .css-bg1rzq-control {
    height: 45px;
}
.customerKycBackground .banner_text_background .add_remover button, .mails_notify button{padding: 5px 10px;font-size: 13px;border-radius: 4px;}
.kycBorderSeprate{border-top: 1px solid #818181;
    padding: 5px 0 0;}

.customerKycBackground .banner_text_background .upload_docs_style button{font-size: 13px;
    min-height: 40px;}
.width-75{width:75%;}
.customerKycBackground .banner_text_background .upload_docs_style .custom-file-input{height: 45px;}
.banner_text_background .upload-inputs button{font-size: 13px;}
.mawbEditForm{padding-bottom: 90px!important;}
.shipperConsigneeSection h3, .dimensionViews h3{color:#fff;font-size: 17px;}
.dimensionViews .text-dark{color:#fff!important;}
.collapse-btns button {
    height: 35px;
    border-radius: 50px;
    outline: 0;
    cursor: pointer;
    width: 35px;
    line-height: 35px;
    background: #ff0600!important;
    padding:0;
    color: #fff;
}
.OtherDetailsmawb h3{color: #fff;font-size: 17px;}
.customerKycBackground .banner_text_background textarea{border-radius: 4px;}
.customerKycBackground .custom-radio .custom-control-input:checked ~ .custom-control-label::before{background-color: #ff0600!important;}
.customerKycBackground .dim-separate div {
    font-size: 14px;
    padding-right: 10px;
    color: #fff;
    margin-bottom: 15px;
}
.dimensionViews button{height:45px;}
.whiteDivText{color:#fff;}
.slick-slide img.img_size{width: 100%;height:600px;}
.offer_coupon .slick-prev, .offer_coupon .slick-next{display: none!important;}
.addOtherCharges{background-color: #f1f1f1;
    text-align: left;
    border-radius: 4px;
    padding: 15px;}
.addOtherCharges2{background-color: #f1f1f1;
    text-align: left;
    border-radius: 4px;
    padding: 15px 0;}
.addOtherCharges h3,.addOtherCharges2 h3{font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;}
.addOtherCharges2 h3 {padding-left: 15px;}
.sectionOtherCharges .chargesList{background: #fff;
    margin-bottom: 10px;
    padding: 15px 10px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
    border-radius: 4px;
}
.chargesListAmount{display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;}
.pickUpFrom input[type="text"]{
    height:45px;
    border-radius: 4px;
}
.pickUpFrom .css-1hwfws3{height:45px;}
.pickUpFrom{margin:10px 10px 0;background: #f5f5f5;padding: 10px 10px 0;border-radius: 4px;}
.pickUpFrom .d-flex{justify-content: center;}
.pickUpFrom label{text-align: left;}
.pickDetails{margin-bottom: 10px;
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #707070;}
.submitButton{width: 100%;
    text-align: center;
    margin-bottom: 10px;}
.submitButton .btn{background: #ff0600;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 15px;
}
.addOtherCharges label {
    color: #156FC2;
    font-size: 15px;
    font-weight: 600;
}
.amountClearance{font-weight: 600;
    font-size: 17px;}
.otherFreight {
    border-top: 1px solid #ededed !important;
    margin: 10px;
    padding: 10px 0 0;
}
.strike_rates{margin-right: 16px;
    font-size: 17px;
    color: #ff0600;}

@media(max-width:768px){
    .footer-wrap h3 {
        margin-top: 40px;}

    footer .round-btn {
        margin: 15px 4px;}
}
@media(max-width:320px){
    .copyright {
        font-size: 13px;}
}

.change_theme .Query_filter,.change_theme .dashboard-ratesheading{background: #000000a3;}
.change_theme .Query_filter h4,.change_theme .Query_filter .heading_filter,.change_theme .Query_filter .transit_checkBox label,
.change_theme .Query_filter .tab_filter,.change_theme .switch_currency label,.change_theme .sort_label label,.change_theme .zipasuggestion_side h6{color: #fff;}
.change_theme .cheapest_rates{background: #fff;}
.change_theme .cheapest_rates.active{background: #ff0600;}
.change_theme .half-rates{border: 2px solid transparent;}
.change_theme .table.freight_diff_table td.total_amount_diff, .change_theme .table.freight_diff_table th.total_amount_diff {
    font-weight: 700;
    border: 1px solid #fff!important;
    border-top: 2px solid #000!important;
}
.change_theme .freight_diff_table,.change_theme .tab_line{background: #fff;}
.change_theme .slab_headings label{color:#000;}
.activityBases input[type=radio]{width: 18px;
    height: 18px;}
.airine_revert_table {
    border-spacing: 0 13px;
    border-collapse: separate;
}
.vesselsDiv {
    /*box-shadow: 0 2px 10px rgb(0 0 0 / 10%);*/
    box-shadow: 0 2px 10px rgb(0 0 0 / 38%);
    background: #fff;
    border-radius: 5px;
    margin-bottom: 13px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.vesselsDiv1{display: flex;
    flex-wrap: wrap;
    align-items: start;}
.vesselsDiv1 .vessel_Data,.vesselsDiv1 .vessel_Img {
    border-color: transparent;
    padding-bottom: 0.75rem;
    border-bottom: inherit!important;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    width: 25%;
}
.vesselsDiv .vessel_Data {
    padding: 10px 0;
    font-size: 14px;
    border-color: transparent;
    font-weight: 500;
    text-align: center;
    width: 25%;
    background: #fff;
}
.airine_revert_table tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}
.width_vessels {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-between;
    padding: 0 10px;
}
.width_vessels .vesselItems {
    /* width: 33.3%; */
    text-align: center;
    padding: 8px 2px;
}
.width_vessels .vesselItems p{font-size: 12px;}
.tracking_vessels {
    position: relative;
    background: #f4f4f4;
    border-radius: 4px;
    padding: 10px 0;
    margin-bottom: 10px;
}
/*{content: "";*/
/*    display: block;*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    border-radius: 50%;*/
/*    background-color: #e20101;*/
/*    position: absolute;*/
/*    left: calc(-24px - 8px);*/
/*    top: calc(18px * .5);*/
/*    -webkit-transform: translateY(-50%);*/
/*    -ms-transform: translateY(-50%);*/
/*    transform: translateY(-50%);*/
/*    -webkit-box-shadow: 0 0 0 3px #fff;*/
/*    box-shadow: 0 0 0 3px #fff;}*/

.vessel_Data i,.vessel_Data svg{margin-left: 20px;
    font-size: 18px;
    /*color: #9b9b9b;*/
    color: #7f7f7f;
}
.headerVessel span {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    margin-bottom: 10px;
}
.trackingSelection {
    height: 70px!important;
    padding: 0 10px!important;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px!important;
    color: #000;
}
.trackingDiv{width:20%;margin-right: 5px;border-radius: 4px;overflow: hidden;}
.searchNumber{width:60%;border-radius: 4px;overflow: hidden;}
.trackingDiv .custom-select:focus {
    border-color: transparent!important;
}

.drivers_detail{width:100%;}

.pick_shipments{width: 23%;
    margin-bottom: 15px;
    margin-right: 10px;}
.pick_shipments h5 , .pick_shipment_d h5{font-weight: 600;text-transform: uppercase;font-size: 13px;margin-bottom: 5px;}
.tableInsideTable{background: #fff!important;
    padding: 10px 20px!important;}
.tableInsideTable table tr th{text-transform: uppercase;
    text-align: center;
    font-size: 17px!important;font-weight: 600;border:1px solid #000!important;
}
.tableInsideTable table tr td{border: 1px solid #000;
    text-align: center;
    font-size: 13px!important;
    font-weight: 500!important;}
.more-details .book-btn{background:#ff0600!important;}

.small_tracking{width:15%;font-size: 18px;
    padding: 15px 5px;
    text-transform: uppercase;
    color:#5a5a5a;
    font-weight: 500;}
.full_tracking{width: 55%;
    font-size: 18px;
    padding: 15px 5px;
    color: #5a5a5a;
    text-transform: uppercase;
    font-weight: 500;}
.content_tracking {background: #ffffff;
    margin-bottom: 15px;
    box-shadow: 0px 2px 6px 1px #d7d7d7;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e4e4e4;
}
.tracking_section {
    background: #f9f9f9;
    padding: 0 20px 20px;
    border-radius: 4px;
}
.content_tracking .full_tracking,.content_tracking .small_tracking{font-size: 14px;
    text-transform: capitalize;
    color: #000;
    font-weight: 600;}
.color_track{background: green;
    color: #fff;
    border-radius: 3px;
    padding: 5px;
    text-transform: capitalize;}
.color_track_red{background:red;}
.text_right_track{text-align: right;}
.padding_filter{padding:20px 0;}
.sideFilterPopup{
    display: block!important;
    top: 0;
    position: fixed;
    bottom: 0;
    background: #000000de!important;
    color: #000!important;
    z-index: 99;
    border-radius: 0;
    height: 100%;
    padding: 25px 0 0;
    left: 0;}
.vessel_Img img{width:61px!important;margin-right: 15px;}
.vessel_Img,.vesselsDiv1 .vessel_Img{width:46%;display: flex;align-items: center;}
.vessel_with_name{font-size: 18px;
    font-weight: 500;}
.source_name{color: #007dc6;
    font-size: 13px;
    font-weight: 700;}
.vessel_arrow{background: #ff0600;
    width: 17px;
    height: 17px;
    border-radius: 50px;
    line-height: 20px;
    text-align: center;
    color: #fff;}
.tracking_Design{position: relative;}
.firstStepTrack {
    position: absolute;
    bottom: 10%;
    left: 86px;
    text-align: center;
}
.firstStepTrack p,.secondStepTrack p,.thirdStepTrack p,.forthStepTrack p,.fiveStepTrack p,.sixStepTrack p,.sevenStepTrack p,.eightStepTrack p{font-size: 13px;color:#6e6e6e;line-height: 20px;}
.firstStepTrack p strong,.secondStepTrack p strong,.thirdStepTrack p strong,.forthStepTrack p strong,.fiveStepTrack p strong,.sixStepTrack p strong,.sevenStepTrack p strong,.eightStepTrack p strong{color:#000}
.secondStepTrack{position: absolute;
    bottom: 30%;
    left: 16%;
    text-align: center;}
.thirdStepTrack{position: absolute;
    bottom: 11%;
    left: 28.3%;
    text-align: center;}
.forthStepTrack{position: absolute;
    bottom: 30%;
    left: 39%;
    text-align: center;}
.fiveStepTrack{position: absolute;
    bottom: 11%;
    left: 51%;
    text-align: center;}
.sixStepTrack {
    position: absolute;
    bottom: 30%;
    left: 62.5%;
    text-align: center;
}
.sevenStepTrack{position: absolute;
    bottom: 11%;
    left: 75%;
    text-align: center;}
.eightStepTrack{position: absolute;
    bottom: 29%;
    right: 8%;
    text-align: center;}
.tracking_Design img{width:100%}
.tracking_heading{width: 100%;margin-bottom: 40px;
    justify-content: center;}
.tracking_heading p {
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
}
.dashboard-tracking-section{background: #fff;
    padding:42px 0 0;
}




.diff_vessel{box-shadow: 0 2px 10px rgb(0 0 0 / 38%);
    background: #fff;
    border-radius: 5px;
    margin-bottom: 25px;
    /*padding: 10px;*/
    align-items: center;}
.width_30_track{width:30%;border-right: 1px solid #dfdfdf;
    text-align: center;
    padding: 10px 15px;}
.width_70_track{width:70%;padding: 30px 40px 10px;}
.vesel_track_name{font-size: 16px;margin-bottom: 5px;font-weight: 600;}
.vesel_track_shipping{background: #ff0600;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    width: 80%;
    margin: auto auto 8px;
    border-radius: 2px;}
.vesel_track_imo{font-size: 14px;
    font-weight: 600;}
.first_color_green{position: absolute;}
.track_arrival_departure{width: 100%;border:2px solid #000;position: relative;}
.track_arrival_departure:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    right: -2px;
    top: -10px;
    border-radius: 50px;
    border: 1px solid #000;
    background: white;
}
.track_arrival_departure:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: -2px;
    top: -10px;
    border-radius: 50px;
    border: 1px solid #000;
    background: white;
}
.date_tracking{padding:12px 0 0;width: 100%;}
.date_tracking .arrival_depart{font-weight: 700;text-align: center;}
.arrival_img {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    background: #fff;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 10px 15px;
}
.arrival_text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    background: #fff;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    padding: 0 10px;
}
.arrival_img img{width:55px;}
.trackVesselName,.trackSourceName{font-weight: 700;}
.trackSourceName{color: #ff0600;}
.view_track_btn{color: #0051e5;font-weight: 600;font-size: 13px;padding:15px 0 0;cursor: pointer;}
.view_track_btn i,.view_track_btn svg{color: #000;}
.vessel_track_shippingBlack{background: #000;}
.tracking_Show_mob,.track_table_mob{display: none!important;}
.track_table_mob .fare_table_design td, .track_table_mob .fare_table_design th{text-align: center;}

.vessel_div_input{width:30%;}
.vessel_div_input.border-rights{border-right:1px solid #dfdfdf}
.vessel_div_input .css-bg1rzq-control,.vessel_div_input input{border-radius: 4px!important;
    font-size: 15px!important;font-weight: 500;border-color: transparent;
    height: 70px!important;}
.vessel_div_input .css-1hwfws3{height: 70px;}
.vessel_btn_new {cursor: pointer;
    padding: 0 15px;
    font-size: 26px;
    font-weight: 500;
    background: #ff0600;
    border-color: transparent;
    border-radius: 5px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px 0;
}
.vessel_fields{box-shadow: 0 4px 18px 0 rgb(0 0 0 / 44%)!important;border-radius: 4px!important;justify-content: space-between;background: #fff;
    padding: 0 5px;}
.vessel_div_input .css-bg1rzq-control:hover {
    border-color: transparent!important;
}
.vessel_text {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    /*padding-left: 20px;*/
    /*border-bottom: 1px solid #dfdfdf;*/
    padding-bottom: 16px;
}
.vesselSchedules2 {background-color: #000;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px 3px #ebebeb;
}
.vesselSchedules2 .diff_vessel {
    margin: 15px 50px 25px;
}
.vesselSchedules2 .vesel_track_shipping{width:50%;}
.vessel_fields .lableNotShow .css-151xaom-placeholder{font-size: 17px!important;}
.vessel_listings{margin-top: 20px;}
.vessel_listings .AAA-shipper-consignee-approve-data-12{padding:30px 20px;}
.vessel_not{font-size: 30px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 60vh;}
.vesselSchedules{padding: 10px 20px;}
.sideFilterPopup .advertiseMent {
    padding: 0 20px;
    /*overflow-y: inherit;*/
    height: 90%;
}
.NewHomeSection .ocean_freight_banner, .NewHomeSection .air_freight_banner, .NewHomeSection .courier_freight_banner, .NewHomeSection .road_freight_banner{min-height: 100vh;}
/*.change_theme .Query_filter.sideFilterPopup h4,.change_theme .Query_filter .transit_checkBox label{color:#000;}*/
.table_summery_invoice{color:#fff;}
.table_summery_invoice th, .table_summery_invoice td{padding: 6px 10px;}
.customerKycBackground .banner_text_background .table_summery_invoice td input{height: 38px!important;border-color: transparent;}
.width_10 {
    width: 10%;
    margin-right: 8px;
}
.invoiceReaderForm{width:80%;margin: auto;}
.airCargo_modal .modal-content{background:transparent;}
.myModalNew{
    /*padding-top: 100px;*/
    padding-top: 20px;
    width: 100%;
    /* height: 100%; */
    overflow: auto;
    /*background-color: rgb(0,0,0);*/
    /* background-color: rgba(18,18,18,1); */
    background-color: rgb(18 18 18 / 49%);
    box-shadow: 4px 4px 4px #ccc;}
.myModalNew .modal-backdrop.show {
    opacity: 0;
}
.myModalNew h4{text-align: center;
    cursor: pointer;
    font-size: 20px;
    color: #ffffff;
    margin: 17px 0;}
.navbar.zIndexHigh{z-index: 99999!important}
.spotRatesIndex{top:10%;}

.sideSuggestCoupon{padding-bottom: 4px;}
.sideSuggestCoupon .coupon_apply_pop{padding:3px;}

.btn_drop1 {
    border-radius: 5px;
    padding: 8px 35px;
    background: #ff0600;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    font-size: 9px;
}
.btn_drop11 {
    border-radius: 5px;
    padding: 8px 15px;
    background: #ff0600;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    font-size: 13px;
}
.tablesSlabs .slab_btns:disabled{cursor: not-allowed;opacity: .3;}

.valid_till{font-size: 11px;
    text-transform: capitalize;}

.invoice_table_pdf th {
    /*background: #ffffff;*/
    padding: 10px 5px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}
.invoice_table_pdf td {
    color: #fff;
    padding: 15px 8px;
    font-size: 13px;
}
.table-striped tbody tr:nth-of-type(odd) td {
    color: #000;
}
.edit_data_icon{background: green;
    padding: 5px;
    border-radius: 50px;
    color: #fff;
    cursor:pointer;
    width: 25px;
    height: 25px;
    text-align: center;margin:0 4px;}

.delete_data_icon{background: red;
    padding: 5px;
    border-radius: 50px;
    color: #fff;
    cursor:pointer;
    width: 25px;
    height: 25px;margin:0 4px;
    text-align: center;}

.padding_contact{padding:20px 50px;}
.lables_invoice label{text-align: left;
    width: 100%;
    font-weight: 600;
    font-size: 15px;}
.lables_invoice input{border: 2px solid #000;
    border-radius: 4px;}
.soon_text h4 {
    font-size: 14px;
    color: #333;
    font-weight: 600;
}

.uploadPackingList .upload-inputs1 .custom-file-label {
    border: 1px dashed #000;
    text-align: center;
    font-size: 13px;
    height: 50px!important;
    line-height: 50px;
    font-weight: 600!important;
    text-transform: capitalize;
    background: #fff!important;
    color: #000!important;
    padding: 0;
}
.uploadPackingList .upload-inputs1 .custom-file-label::after{display: none;}
.buttonFullWidth button{width:100%;}
.uploadPackingList .upload-inputs1 .custom-file, .uploadPackingList .upload-inputs1 .custom-file-input{height: 50px;}
.uploadPackingList .horizontal_lines{margin:5px 0 25px;}



/*///////////////////error Page//////////////////////*/

.auth-page {
    min-height: 100vh;
    color: #202020;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.auth-center {
    width: 100%;
    text-align: center;
    /*padding: 25px 0 0;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 0;*/
    /*right: 0;*/
    /*transform: translateY(-50%);*/
}
.auth-center h2 {
    font-size: 190px;
    margin-bottom: 0;
    font-weight: 800;
}
.unauth {
    font-size: 35px;
    font-weight: 700;
}
.auth-center h3 {
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 25px;
}
.back-auths {
    padding: 30px 0 50px;
}
.back-auths ul {
    display: flex;
    list-style: none;
    justify-content: center;
    flex-wrap: wrap;
}
.back-auths ul li {
    margin-right: 30px;
    font-size: 20px;
    font-weight: 600;
}
.back-auths button{background: #ff0600;
    border: 1px solid transparent;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;}
.auth-center img{width:500px;}
.banner_form_text h1 {margin-top: 20px;
    margin-bottom: 75px;
    /*font-size: 42px;*/
    position: relative;
    color: #fff;
    text-align: center;
    line-height: 55px;
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
    /*text-shadow: -1px 1px 0 #000;*/
}
.banner_form_text h1 span {
    color: #fff;
    /*background: #ff0600;*/
    /*padding: 5px 10px;*/
    border-radius: 4px;
}
.queryPageImage:before,.queryPageImage1:before{content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    background: #00000042;
    height: 100%;
    width: 100%;}
.typed-cursor.typed-cursor--blink{display: none;}

.textChange{
    position: relative;
    font-size: 40px;
    color: #ff0600;
    -webkit-text-stroke: 0.3vw #383d52;
    text-transform: uppercase;
}
.textChange::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #01fe87;
    -webkit-text-stroke: 0vw #383d52;
    border-right: 2px solid #01fe87;
    overflow: hidden;
    animation: animate 6s linear infinite;

}
@keyframes animate{
    0%,10%,100%{
        width: 0;
    }
    70%,90%{
        width: 100%;
    }
}
.colorChanges{
    font: 700 4em/1 "Cretype caros", sans-serif;
    letter-spacing: 0;
    padding: 0.25em 0 0.325em;
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}
@-webkit-keyframes aitf {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}
.services_content1{margin:90px 0 0;}
.paraHyperlink a,.paraHyperlink a b{color: #007bff;
    font-weight: 600;}

.footer-black .fa-youtube {
    color: #ff0600!important;
}

.form_customer input[type=checkbox],
.form_customer input[type=radio] {
    width: auto;
    height: auto !important;
}
.form_customer .select__value-container.select__value-container--is-multi {
    height: auto;
}
.form_customer .css-g1d714-ValueContainer {
    line-height: inherit;
    /*height: auto;*/
    padding: 2px 8px;
}

.add_stock_second {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.add_stock_second .form-group {
    width: 48%;
}
.blog_description_other a:not([href]):not([tabindex]){color:#fff;}
.heightScroll{height: 600px;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;}
.mobileMap,.truckMobile{display: none;}
.footerIconSocial{
    font-weight: 900;font-size: 24px;
}
.queryUploadPDf{padding: 70px 0 60px;min-height: auto;}
.banner_form_texts {
    margin: auto;
    width: 90%;
}
.banner_form_texts .banner_text_background {
    padding: 50px 20px;
}
.banner_form_texts label.upload_label {
    color: #fff;
    font-size: 25px;
    text-align: center;
    width: 100%;
}
.customerKycBackground .banner_form_texts .custom-file-input {
    height: 60px;
}
.customerKycBackground .banner_form_texts .custom-file-label {
    border: 1px dashed #000;
    text-align: center;
    font-size: 15px;
    border-radius: 4px;
    height: 60px;
    line-height: 60px;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;
    padding: 0;
}
.customerKycBackground .banner_form_texts .btn{width: 100%;
    border-radius: 4px;
    margin-top: 20px;}

.customerKycBackground .banner_form_texts .width-10{width:78%;}
.customerKycBackground .banner_form_texts .align-items-start{align-items: center!important;justify-content: center!important;}
.download_shippingBill{background: #ff0600;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    padding: 7px 10px;
    font-weight: 500;}

.mobileShowingTable{display: none;}
.mobileShowingTable tr {
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.mobileShowingTable td{background:white;padding: 0.75rem!important;font-size: 13px;}

.creditDaysText{background: #d8d8d8;
    display: inline-block;
    padding: 0 10px;
    border-radius: 4px;}
.disabledFilter,.Query_filter .disabledFilter .transit_checkBox label, .Query_filter .disabledFilter .transit_checkBox input{cursor: not-allowed}
.chargesHbl .origin-label{color:#fff;border:none;}
.chargesHbl  th.origin-label{font-size: 16px;
    font-weight: 600;
}
.couponText{font-size: 16px;padding:25px 30px;
    text-align: center;}
.couponText button{
    background: #ff0600;
    display: block;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 4px 25px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 15px auto 0;
}

.opacityStaus{opacity: .1}





/*//////////////////////////////////////new pages////////////////////////*/

.changeCss{position: relative;
    height: auto;
    min-height: 94vh;
    /*background: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.55789792306646-1685790448672.jpg");*/
    background: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.8879142455511706-1682401015638.jpg") fixed;
    background-size: cover;
}
.queryPageImage1{position: relative;
    min-height: 95vh;
    display: flex;
    padding:10px 40px;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
    height: auto;}
.frontBanner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width:95%;
    height:100%;
}
.content_front{
    width:45%;
}
.content_front p{color: #fff;
    font-size: 15px;
    text-align: justify;
    line-height: 33px;
    margin-bottom: 30px;}
.img_front{width:52%;}
.notShowDesk,.mobileShown{display: none;}
.banner_form_text1 h1{font-size: 35px;color:#fff;font-weight: 600;margin-bottom: 10px;}
.opacityProforma{opacity: .2;}
.book-text h3{font-size: 22px;
    color: #ff0600;
    line-height: 37px;
    font-weight: 600;}
.book-text h6 {
    font-size: 19px;
    color: #333;
    margin: 20px 0 10px 0;
    font-weight: 600;
}
.request_btn_payment{background: #ff0600;color:#fff;font-weight: 600;
    border: 1px solid #ff0600;font-size: 15px;border-radius: 4px;
}
.verify_btn1{border-color: transparent;
    background: #000!important;
    color: #fff;
    border-radius: 50px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
}
.spot_Design .text_origin{width:28%;}
.spot_Design .centerLine{width:40%;}
.price_section{width:100%;}


.OfferSideBar {
    position: fixed;
    bottom: 130px;
    width: 44px;
    right: 0;
    height: 288px;
    background-color: #535766;
    z-index: 6;
    color: #fff;
    letter-spacing: 2px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px 0 0 5px;
    box-shadow: rgb(0 0 0 / 19%) 0 29px 42px 0;
}
.offer-arrow , .iconOffer {
    font-size: 40px;
}
.offer-sidebar-content {-webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    padding: 4px;
    height: 200px;
    vertical-align: middle;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    -webkit-margin-before: 0;
    margin-block-start: 0;
    color: #fcfcfc;
    display: inline-block;
}
.offerImg{position: absolute;
    bottom: 0;
    right: 45px;
    box-shadow: rgb(0 0 0 / 20%) 1px -1px 9px 2px;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    height: 100%;}
.offerImg img{height:100%;}
.borderRadiusOffer{
    border-radius: 0 5px 5px 0;
}
.OfferSideBar1{
    background-color: #535766;
}
.iconOffer {
    font-size: 35px;
}
.big-reference1 button {
    margin-right: 10px;
}

.shipper-historys .big-reference h3 , .big-reference h3 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.g20Text{font-size: 16px;
    line-height: 26px;
    text-align: justify;}

.Co2Emmision {background: #fff;
    top: 3px;
    padding: 2px 29px;
    right: 0;
    text-align: center;
    width: 18%;
    position: absolute;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    border-radius: 50px 0 0 50px;
}
.co2EmissionText {text-align: center;
    color: green;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
    text-transform: capitalize;
}
.co2EmissionText img{width:20px!important;}
.Co2Emmision span{
    color: green;
    font-weight: 600;
}
.NewRatesCard .rates_new_screen{width:35%;}
.iciciLogo{width:250px;}

.textFrontICICI {
    margin-top: 28px;
    margin-bottom: 70px;
    position: relative;
    color: #fff;
    line-height: 55px;
    font-size: 34px;
    text-align: left;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
}




/*BlogNewPAgeCSS*/
.BlogTitle{
    /* margin-bottom: 50px; */
    margin-bottom: 0px;
    margin-top: 15px;
}
.BlogTitle h4 {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 6px;
}
.BlogTitle h6{font-size:16px;}

.imgWithblog{width: 32%;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 6px hsla(0,0%,79%,.5);
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    place-self: normal;
    /* place-self: flex-end; */
    padding-bottom: 10px;
}
.onlyTwoImages .imgWithblog{margin : 0 10px 50px 10px;}
.imgWithblog img {
    width: 100%;
    height: 120px;
    /* min-height: 120px; */
    object-fit: fill;
}
.TextBlogs{padding:20px 20px;}
.redTextBlog,.linkBlog{color: #ff0600;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.3px;
    margin-bottom: 8px;}
.TextBlogHeading{font-weight: 700;font-size: 17px;margin-bottom: 20px;line-height: 24px;}
.linkBlog{font-size: 15px;
    cursor: pointer;
    color: #fff;
    background: #ff0600;
    padding: 9px 20px;
    border-radius: 3px;
    text-align: center;
    margin: auto;}
.blogPagination{margin-top: 45px;}
.blogPagination .Blog_page{margin : 0 3px 5px 3px;cursor: pointer;
    background: #dfdfdf;color:#000;width: 30px;height:30px;line-height: 30px;text-align: center;font-weight: 600;font-size: 18px;border-radius: 5px;
}
.Blog_page.active{background: #ff0600;color:#fff;}
.otherServicesActivityBases{width:100%;}
.otherServicesActivityBases hr {
    margin: 5px 0 15px;
}
.listsServices{position: relative;
    text-align: left;
    background: #f1f1f1;
    padding: 6px 10px;
    border-bottom: 1px solid #d6d4d4;
    margin-bottom: 6px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.listsServices svg,.listsServices .amountClearance, .listsServices .strike_rates{font-size: 14px;}

.listsServices  label{margin-bottom: 0;font-size: 13px;}


.shippingBillRatesQueryPAge{display: initial;
    padding:7px 17px;
    background: #000;
    font-size: 17px;}
.PopupDiwali{height: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;}
.btn-branch-main{background: #ff0600;
    display: inline-block;
    padding: 4px 10px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;}

.inputWithButton{display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 9px;
    box-shadow: 0 0 14px 0 #00000040;}

.inputWithButton input {
    width: 65%;
    outline: none;
    border: none;
    font-size: 17px;
}
.inputWithButton button {
    background: #ff0600;
    border-color: transparent;
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    padding: 13px 13px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.trackingPreference {width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.trackingPreference .card {
    margin-bottom: 30px!important;
    width: 32%;
    margin-right: 10px;
}
.trackingPreference .back-cards {
    background: #404040;
    font-size: 16px;
    padding: 8px 15px;
    color: #fff;
}
.trackingPreference .my-cardcontent {
    padding: 30px 10px;
}
.trackingPreference .my-cardcontent .form-check-inline label {
    font-weight: 500;
    font-size: 14px;margin-right: 6px;
    text-transform: capitalize;
    margin-bottom: 0;
    color: #000;
}







/*////////////////////////////New Tracking PAge /////////////////////////*/
.grayColor{background-color:#f1f2f4}
.trackingBox {background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
    padding: 20px 0;
}
.stepper-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;


}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 5px solid #ccc;
    width: 100%;
    top: 20px;
    /*left: -50%;*/
    left: -8%;
    z-index: 2;
    border-radius: 4px;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 5px solid #ccc;
    width: 100%;
    top: 20px;
    left: 8%;
    z-index: 2;
    border-radius: 4px;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    font-size: 25px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
}

.stepper-item.active {
    font-weight: bold;
}

.stepper-item.completed .step-counter {
    background-color: green;
    color: #fff;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 5px solid green;
    width: 100%;
    top: 20px;
    /*left: 50%;*/
    left: 8%;
    z-index: 3;
}

.stepper-item:first-child::before {
    content: none;
}
.stepper-item:last-child::after {
    content: none;
}

.headsName {
    color: green;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 13px;
}
.step-name {
    margin-top: 6px;
    font-size: 15px;
    font-weight: 400;
}
.stepper-item.completed .step-name{font-weight: 600;}
.width-80{width:80%;}
.view-btn svg{margin-left: 6px;}
.airine_revert_table{padding:0 30px;}
.airine_revert_table tbody tr {background: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
    border-radius: 5px;
}
.tracking-shipment .status_history .track{width:100%;margin-bottom: 70px;}
.tracking-shipment .status_history .track .icon.active{background: green}
.stepper-item.completed::before{border-bottom: 5px solid green;}
.trackingTableData{background: #fff;}
.trackingTableData .text-left p{text-transform: capitalize;}
.trackingTableData th{font-weight: 600;
    padding: 5px 20px!important;
    text-align: left;
    font-size: 15px;}
.trackingTableData td {
    border-bottom: 1px solid #cccccc;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    padding: 10px 20px!important;
}
.trackingTableData th{background: #f1f1f1;
    padding: 12px 20px!important;}
.locationData p{text-transform: lowercase;}
.locationData{text-align: center!important;}

.tableHeightTracking{height:430px;overflow-y: scroll;}
.tableHeightTracking table th{position: sticky;
    top: 0;}
.trackingCourierDesign table th{background: #f9f9f9;}

.NewPopForExhibition.airCargo_modal{max-width: 900px;}

.exhibitionBanner{position: relative;
    height: 85vh;
    background-attachment: fixed;
    background-image: url("https://aaa2innovate.s3.ap-south-1.amazonaws.com/unTracked/s3Bucketoo0.970665732501681-1699956393181.jpg");
    background-position: center;
    background-size: cover;
}

.exhibitionBtn{font-size: 18px;
    cursor: pointer;
    background: #ff0a00;
    border: 1px solid #ff0a00;
    padding: 6px 30px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
}
.exhibitionBtn.ColorDiff {
    background: transparent;
    border-width: 3px;
    color: #000;
    font-weight: 600;
}
.exhibitionBannerPosition{
    display: flex;
    flex-wrap : wrap;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 60%;
    height: 100%;
    /*padding: 0 40px;*/
}
.exhibitionBannerPosition img{width:600px;margin-bottom: 30px;}
.exhibitionBannerPosition h5{margin-bottom: 45px;font-size: 42px;font-weight: 600;letter-spacing: 1px;}
.exhibitionFirstSection{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.exhibitionDetails{margin: 70px 0 0;padding : 0 40px;}
.exhibitionText{width: 45%;}
.exhibitionText h6{font-size:38px;margin-bottom: 10px;font-weight: 600;}
.exhibitionText h2,.backgroundColorExhibition h2, .exhibitionMeetUs h2{font-size:45px;font-weight: 600;margin-bottom: 15px;}
.exhibitionText p,.backgroundColorExhibition p{font-size:20px;line-height: 35px;margin-bottom: 30px;text-align: justify;}
.exhibitionImage{width: 52%;position: relative;text-align: right;}
.exhibitionImage img{
    /*box-shadow: -3px 0 7px 2.16px rgb(0 0 0 / 42%);*/
    max-width: 100%;}
.exhibitionImage:after{content: "";
    position: absolute;
    bottom: -21px;
    left: 45px;
    width: 145px;
    height: 129px;
    z-index: -1;
    background-color: #ff0600;}
.backgroundColorExhibition{background : #ff0600;padding:70px 0!important;}
.backgroundColorExhibition p, .backgroundColorExhibition h2,.exhibitionMeetUs h2{text-align: center;color: #fff;}
.backgroundColorExhibition p{margin:auto;width: 88%;line-height: 30px;}
.exhibitionIconsGroup{padding: 50px 0 0;display: flex;flex-wrap: wrap;justify-content: space-between;align-items: start;}
.exhibitionIcon{text-align: center;width: 24%;}
.exhibitionIcon img{margin-bottom: 25px;
    max-width: 100%;
    width: 85px;}
.exhibitionIcon p{font-size: 26px;font-weight: 700;width: 100%;}
.textIncreaseExhibition p{line-height: 30px;}
.exhibitionDivIcon{background: #fff;
    border-radius: 15px;
    text-align: center;
    width : 15%;
    padding: 10px;}
.exhibitionDivContent{width : 82%;}
.exhibitionDivContent h3{font-weight: 700;
    color: #fff;
    font-size: 35px;
    margin-bottom: 15px;
    letter-spacing: 1px;}
.exhibitionDivContent p{width: 100%;text-align: left}
.exhibitionMarginBottom{margin-bottom: 30px;padding: 20px 20px 0;}
.meetUsExhibition{margin: 30px 0 0;}
.redPatchExhibition{text-align: center;padding : 25px 0}
.exhibitionMiddle h5{text-align: center;font-size: 35px;line-height: 45px;color: #fff;font-weight: 600;}
.exhibitionMiddle h5 span{font-size: 30px;}
.redPatchExhibition span{background : #ea5821;padding:5px 10px;font-size: 45px;color: #fff;font-weight: 700;margin-right: 20px;}
.exhibitionMeetUs button {
    background: #ff0600;
    color: #fff;
    border: transparent;
    font-size: 25px;
    padding: 4px 30px;
    border-radius: 9px;
    font-weight: 500;

}
.buttonExhibition{margin:40px 0}
.exhiHideMob{display: none;}
.modal-dialog-centered{box-shadow: none!important;}
.modal-dialog.NewPopForExhibition{margin:0.5rem auto;padding: 0 25px;}

.scroll_btn{position: fixed;
    top: 12%;
    right: 1%;
    background: #ff0600;
    border-radius: 50px;
    z-index: 99;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    cursor: pointer;}

.vehicleDetailsDemo{text-align: left;}
.vehicleDetailsDemo h6{
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;flex-wrap: wrap;align-items: start;
}
.vehicleDetailsDemo h6 strong{width:40%;}
.vehicleDetailsDemo h6 .dot{width:10%;text-align: center;}
.vehicleDetailsDemo h6 .detVehicle{width:50%;word-break: break-word;}
.vehicleTwoPart{display: flex;flex-wrap: wrap;justify-content: space-between;align-items: start;}
.vehicleTwoPart h6{width: 49%;}
.popupLarge{max-width: 900px;}
.popupLarge .modal-body{padding: 15px 10px;}
.marginParaBottom{margin-bottom: 20px;margin-top: 20px;font-size: 25px;}
.differentContent p{font-size: 17px;margin-bottom: 20px;}
.pointer_disabled{pointer-events: none;
    background: #dfdfdf!important;}
.removeBtn{padding: 6px 16px;
    margin-left: 5px;
    border-radius: 4px!important;
    font-size: 15px;cursor: pointer;color: #fff;
    background: #ff0600;
    font-weight: 500;}
.redTextDate{color: #9a9a9a;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    text-transform: uppercase;}
.selectMenuBlog{display: flex;flex-wrap: wrap;justify-content: end;}
 .selectMenuBlog .form-group {
     width: 20%;
     margin-bottom: 25px;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }
.selectMenuBlog .form-group select{border: 1px solid #c4c4c4;
    color: #818181;
    margin-right: 8px;
    border-radius: 6px;}
.deleteIcon{color: #595959;
    cursor:pointer;
    font-size: 16px;}
.paddingDiffer{padding: 10px 10px 10px;}
.dateArrival{font-size: 13px;
    color: #8a8a8a;
    font-weight: 500;}
.paraWidth{width:32%;text-align: justify}
.vesselHeadingMAin{font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 5px;
    padding-top: 5px;}
.ShowingVessel{display: none;}
.vessel_fields .css-1szy77t-control, .vessel_fields .css-1szy77t-control:hover{border-color: #fff;box-shadow: none;}
.seprateVessel{background: #fff;
    margin: 0 10px 10px;
    padding: 0 10px;
    border-radius: 7px;
    border: 2px solid #000;
    box-shadow: 0px 4px 6px 4px rgb(173 173 173 / 44%);}
    .bannerImages img{width:100%;}
.boxIndepence h3{font-size: 22px;padding-bottom: 10px;}
.boxIndepence p{line-height: 30px;
    font-size: 19px;
    text-align: left;
    padding-left: 30px;}
.bottomHeading{padding-bottom: 30px;}
.contentTermsAndCondtions p{padding-bottom: 15px;}
.backgroundColorExhibition .termsAndConditonsHeading{font-size:36px;margin-bottom:30px;}
.contentTermsAndCondtions a{color:#fff;}
.contentTermsAndCondtions a:hover{color:#fff;}
.PopupDiwali {box-shadow: none;}
.PopupDiwali .modal-content{background : #fff;border-radius: 4px;padding : 3px;}
.myModalNew .PopupDiwali h4{color:#000;}


.blog_description_heading{margin:40px 0 20px;}
.blogImage{width: 100%;
    /* height:400px; */
    object-fit: fill;}
.blogsWidths{width:80%;margin: auto;}
.description_my h1,.description_my h2{font-size: 25px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}
.conatinerLengthBlog{width:90%;}
.award_image{padding: 0;}
.award_image img{height: 420px!important;border-radius: 4px;object-fit: inherit!important;}
.awards_items .certificate{min-height: 500px;align-items: start;margin:10px 30px 30px 10px!important;}

.moreDetailsContainer{background: #f2f2f2;padding:10px 10px;}
.moreDetailsContainer .width_vessels{align-items: center;padding:0 10px 5px}
.moreDetailsContainer .width_vessels .vesselItems{text-align: left;}
.moreDetailsContainer .seprateVessel{border: 1px solid #000;margin:0 0 10px;}
.buttonMores {
    text-align: center;
    background: #dfdfdf;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: 1px solid #d0d0d0;
    font-size: 1rem;cursor: pointer;
}
.moreDetailsContainer .trackingTableData th {
    padding: 6px 20px!important;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 2px solid #000;
    font-weight: 600;
    background: #f8f8f8fd;
}
.moreDetailsContainer .trackingTableData .text-left p{font-size: 12px;font-weight: 400;}
.trackingWidth{width:80%;}
.headingTwo{font-size: 25px;font-weight: 600;margin-top: 25px;}
.loads{color: #fff;
    text-align: center;
    font-size: 40px;
    padding: 40px 0;}

.frameImageICICI.bigIciciImage img{height:700px;}
